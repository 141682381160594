import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getMarketoverview } from "../../store/actions";
import ValueFormat from "../../util/ValueFormat";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { displayText } from "../../constant/MessageConstant";
import DrivingBehaviour from "../AllCharts/apex/DrivingBehaviour";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup.js";
import UserDashboardCtrlr from "../../controller/UserDashboardCtrlr.js";

const   MarketOverview = ({filter, loaderCount ,setGetDrivingScoreData}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState("ALL");
  const [dataDisplay, setDataDisplay] = useState(null);  
  const [modalIsOpen, setIsOpen] = useState(false);
  

  const onChangeHandle = (data) => {
    setState(data);
    dispatch(getMarketoverview(data));
  };

  useEffect(() => {
    dispatch(getMarketoverview(state));
  }, [state]);

  useEffect(() => {
    dispatch(getMarketoverview("All"));
  }, [dispatch]);

  const series = [
    {
      name: "Driving Score",
      type: "line",
      data: [0],
    },
    
  ];

  const [drivingData, setDrivingData] = useState(series);  
  const [xAxisData, setXAxisData] = useState([]);
  const getUserBoardData = async () => {
    setDataDisplay(null)
    loaderCount((current) => current + 1);
    var filtersData = (filter.durationFilter == "ALL") ? "" : filter.durationFilter;
    var result = await UserDashboardCtrlr.getV1MultiUserDrivingScoreList(
      filtersData,
      filter.startDateTime,
      filter.endDateTime,
    );
    loaderCount((current) => current - 1);
    // console.log(result,"resultDrivingScore")
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if(status == "SUCCESS"){
    var resultJSONData = resultJSON.data;
    setDataDisplay(resultJSONData.length);
    var drivingScoreDataList = [];
    
    var rideNameList = [];
    var resultData = [];
    var displayData = 0;    
    
    if(resultJSONData.length > 0){
        resultJSONData.map((apiDataTemp) => {         
          var drivingScoreList = ValueFormat.parseAsArray(
            apiDataTemp.drivingScoreList
          );
          setGetDrivingScoreData(drivingScoreList);
          drivingScoreList.map((apiDrivingData) => {
            var drivingScore = ValueFormat.parseAsInt(apiDrivingData.calculatedDrivingScore);
            displayData = displayData + parseFloat(drivingScore)             
            var rideName = ValueFormat.parseAsString(
              apiDrivingData.rideName
            ).substring(4);
            drivingScoreDataList.push(drivingScore);            
            rideNameList.push(rideName);
          });   
    
        });

      }
    
      setDataDisplay(displayData > 0 ? true : false);

    var resultObject = [
      {
        name: "Driving Score",
        type: "line",
        data: drivingScoreDataList,
      },
      
    ];

    resultData.push(...resultObject);

    setDrivingData(resultData);
    setXAxisData(rideNameList);
  }else{
    const code = resultJSON.error.code;
    if(code == "E1206"){
      setDataDisplay("failed")
    }
  }
  };

  useEffect(() => {
    getUserBoardData();
  }, [filter.durationFilter, filter.startDateTime, filter.endDateTime]);

  return (
    <React.Fragment>
      <Col xl={12}>
        <Card style={{height: 550}}>
          <CardHeader  className="cardHeader" style={{padding:"5px"}}>
          
              <h5 className="card-title">Driving Score Comparison</h5>
              
            
          </CardHeader>
          
            <CardBody>
            {dataDisplay == "failed" ? (
          <p className="data_not_available">{displayText.ER_1206}</p>
        ): dataDisplay == false ? (<p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
        ) : dataDisplay == null ? <p className="fetch_data">{displayText.FETCHING_DATA}</p> : "" }
              <Row className="align-items-center">
                <Col xl={12}>
                  <DrivingBehaviour
                    drivingDataList={drivingData}
                    xAxisDataList={xAxisData}
                  />
                </Col>
                
              </Row>
            </CardBody>
       
        </Card>
      </Col>
      <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen} />

    </React.Fragment>
  );
};

export default MarketOverview;

import React, { useEffect, useState } from "react";
import Loader from "../../components/Common/loader";
import { CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  SC_DIVISION_ID,
  SC_LOGIN_USER_FIRST_NAME,
  SC_LOGIN_USER_ID,
  SC_USER_ID,
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import Report from "../../controller/Report/Report";
import { Link } from "react-router-dom";
import UserDashboardCtrlr from "../../controller/UserDashboardCtrlr";
import UtilDateTime from "../../util/UtilDateTime";
import UserCrashCtrlr from "../../controller/UserCrashReportCtrlr";
import { MenuItem, Select } from "@mui/material";

export default function CrashList() {
  const [showLoader, setShowLoader] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [dataLength, setDataLength] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [userId, setUserId] = useState("");
  const [deviceFilter, setDeviceFilter] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [count, setCount] = useState(0);
  const [nextShow, setNextShow] = useState();
  const [prevShow, setPrevShow] = useState();
  const [totalPageCount, setTotalPageCount] = useState();
  const [accidentTypeFilter, setAccidentTypeFilter] = useState("");
  const LSuserType = localStorage.getItem(SC_USER_LOGIN_TYPE);
  const LSuserId = localStorage.getItem(SC_LOGIN_USER_ID);
  const divId = localStorage.getItem(SC_DIVISION_ID);
  const [itemPerPage, setItemPerPage] = useState(5);

  const [totalUserV1Crash, setTotalUserV1Crash] = useState(0);

  let colSize1 = 3;
  let colSize2 = 3;
  let colSize3 = 3;
  let colSize4 = 3;

  const pageSize = itemPerPage;

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const getCrashReport = async (index) => {
    try {
      setShowLoader(true);
      setDataLength(null);
      var filtersData = (filter == "ALL") ? "" : filter;
      var result = await UserCrashCtrlr.getV1VehicleCrashList(
        index,
        filtersData,
        deviceFilter,
        startDateTime,
        endDateTime,
        accidentTypeFilter,
        LSuserId,
        pageSize,
        itemPerPage
      );
      var tableData = [];

      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;

      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("risk", resultJSONData);
        setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          for (let i = 0; i < resultJSONData.length; i++) {
            var userApp = resultJSONData[i];
            var userDetail = ValueFormat.parseAsObject(userApp.userDetail);
            var userid = ValueFormat.parseAsString(userDetail.userId);
            var deviceId = ValueFormat.parseAsString(userApp.deviceId);
            var firstName =
              ValueFormat.parseAsString(
                ValueFormat.capitalizeAllLetter(userDetail.firstName)
              ) || "-";
            var username =
              ValueFormat.parseAsString(userDetail.username) || "-";
            var accidentType =
              ValueFormat.parseAsString(userApp.accidentType) !== null &&
              ValueFormat.parseAsString(userApp.accidentType) !== undefined
                ? ValueFormat.parseAsString(userApp.accidentType)
                : "-";
            var crashTime =
              ValueFormat.parseAsString(userApp.accidentAtToTimeZone) !==
                null &&
              ValueFormat.parseAsString(userApp.accidentAtToTimeZone) !==
                undefined
                ? ValueFormat.parseAsString(userApp.accidentAtToTimeZone)
                : "-";
            var crashValue =
              ValueFormat.parseAsFloat(userApp.accidentSeverityValue) !==
                null &&
              ValueFormat.parseAsFloat(userApp.accidentSeverityValue) !==
                undefined
                ? ValueFormat.parseAsFloat(userApp.accidentSeverityValue) + "g"
                : "-";

            let deviceTypeTemp = "";
            if (userApp.deviceType === "ANDROID") {
              deviceTypeTemp = "Android";
            } else if (userApp.deviceType === "ANDROID_SDK") {
              deviceTypeTemp = "Android SDK";
            } else if (userApp.deviceType === "IOS") {
              deviceTypeTemp = "iOS";
            } else if (userApp.deviceType === "IOS_SDK") {
              deviceTypeTemp = "iOS SDK";
            } else {
              deviceTypeTemp = "N/A";
            }

            var rideName =
              ValueFormat.parseAsString(userApp.deviceName) !== null &&
              ValueFormat.parseAsString(userApp.deviceName) !== undefined
                ? ValueFormat.parseAsString(userApp.deviceName)
                : "-";

            var locationDetails = {
              address: "-",
              zipCode: "-",
              latitude: "",
              longitude: "",
              location: "-",
            };

            userApp.accidentKeyValues.forEach((item) => {
              if (item.key === "address")
                locationDetails.address =
                  ValueFormat.parseAsString(item.value) !== null &&
                  ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "zipCode")
                locationDetails.zipCode =
                  ValueFormat.parseAsString(item.value) !== null &&
                  ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "latitude")
                locationDetails.latitude =
                  ValueFormat.parseAsString(item.value) !== null &&
                  ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "longitude")
                locationDetails.longitude =
                  ValueFormat.parseAsString(item.value) !== null &&
                  ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "location")
                locationDetails.location =
                  ValueFormat.parseAsString(item.value) !== null &&
                  ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
            });

            var totalCounts = ValueFormat.parseAsInt(userApp.totalCount);
            setTotalUserV1Crash(totalCounts)
            setTotalPageCount(Math.ceil(totalCounts / pageSize));
            setNextShow(totalCounts - index > pageSize);

            var crashDatadetails = {
              firstName: firstName,
              username: username,
              accidentType: accidentType,
              crashTime: crashTime,
              crashTimeFormate: crashTime.split(" ")[2],
              crashValue: crashValue,
              device: deviceTypeTemp,
              ...locationDetails,
              rideName: rideName,
              userid: userid,
              deviceId: deviceId,
            };
            tableData.push(crashDatadetails);
            setApiData(tableData);
          }
        }
      } else {
        setDataLength(0);
        // console.log("Status failed");
      }
    } catch (errMes) {
      // console.log("Crash Report Catch Error: ", errMes);
    } finally {
      setShowLoader(false);
    }

    if (index == 0) {
      setPrevShow(false);
    } else if (index > 1) {
      setPrevShow(true);
    }
  };

  useEffect(() => {
    setPageNumber(1);
    if (indexStart == 0) setCount((prev) => prev + 1);
    else setIndexStart(0);
  }, [
    filter,
    deviceFilter,
    startDateTime,
    endDateTime,
    accidentTypeFilter,
    userId,
  ]);

  useEffect(() => {
    if (count > 0) getCrashReport(indexStart);
  }, [indexStart, count,itemPerPage]);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const handleItemPerPageChange = (event) => {
    setItemPerPage(event.target.value);
    setPageNumber(1);
    setIndexStart(0);
  }


  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Battery Level" breadcrumbItem="Battery Level" />
        <Row className="mt-2">
          <Col className="col-12 mt-2">
            <CardHeader style={{ padding: "0px" }}>
              <Row>
                <Col
                  md={colSize1}
                  style={{
                    paddingRight: 0,
                    paddingLeft: 0,
                  }}
                >
                  <div style={{ display: "flex", height: "100%" }}>
                    <div
                      className="sub_heading_new"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginLeft: "14px",
                      }}
                    >
                      Vehicle Crashes
                    </div>
                  </div>
                </Col>
                <Col
                  md={colSize2}
                  style={{
                    paddingRight: 0,
                    paddingLeft: 0,
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <button
                      type="button"
                      className={
                        accidentTypeFilter == ""
                          ? "btn btnColorStylesNew btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                      }
                      onClick={() => setAccidentTypeFilter("")}
                      data-tooltip-id="alle"
                    >
                      All
                      <ReactTooltip
                        id="alle"
                        place="top"
                        content="All"
                        style={{ background: "black" }}
                      />
                    </button>
                    <button
                      type="button"
                      className={
                        accidentTypeFilter == "MILD_ACCIDENT"
                          ? "btn btnColorStylesNew btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                      }
                      onClick={() => setAccidentTypeFilter("MILD_ACCIDENT")}
                      data-tooltip-id="preIn"
                    >
                      Mild
                      <ReactTooltip
                        id="preIn"
                        place="top"
                        content="Mild Accident"
                        style={{ background: "black" }}
                      />
                    </button>
                    <button
                      type="button"
                      className={
                        accidentTypeFilter == "MEDIUM_ACCIDENT"
                          ? "btn btnColorStylesNew btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                      }
                      onClick={() => setAccidentTypeFilter("MEDIUM_ACCIDENT")}
                      data-tooltip-id="postin"
                    >
                      Medium
                      <ReactTooltip
                        id="postin"
                        place="top"
                        content="Medium Accident"
                        style={{ background: "black" }}
                      />
                    </button>
                    <button
                      type="button"
                      className={
                        accidentTypeFilter == "SEVERE_ACCIDENT"
                          ? "btn btnColorStylesNew btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                      }
                      onClick={() => setAccidentTypeFilter("SEVERE_ACCIDENT")}
                      data-tooltip-id="preLo"
                    >
                      Severe
                      <ReactTooltip
                        id="preLo"
                        place="top"
                        content="Severe Accident"
                        style={{ background: "black" }}
                      />
                    </button>
                  </div>
                </Col>

                <Col
                  md={colSize3}
                  style={{
                    paddingRight: 0,
                    paddingLeft: 0,
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <button
                      type="button"
                      className={
                        deviceFilter == ""
                          ? "btn btnColorStylesNew btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                      }
                      onClick={() => setDeviceFilter("")}
                      data-tooltip-id="allBl"
                    >
                      All
                      <ReactTooltip
                        id="allBl"
                        place="top"
                        content="All"
                        style={{ background: "black" }}
                      />
                    </button>
                    <button
                      type="button"
                      className={
                        deviceFilter == "ANDROID"
                          ? "btn btnColorStylesNew btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                      }
                      onClick={() => setDeviceFilter("ANDROID")}
                      data-tooltip-id="androidBl"
                    >
                      Android
                      <ReactTooltip
                        id="androidBl"
                        place="top"
                        content="Android"
                        style={{ background: "black" }}
                      />
                    </button>
                    <button
                      type="button"
                      className={
                        deviceFilter == "IOS"
                          ? "btn btnColorStylesNew btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                      }
                      onClick={() => setDeviceFilter("IOS")}
                      data-tooltip-id="iosBl"
                    >
                      iOS
                      <ReactTooltip
                        id="iosBl"
                        place="top"
                        content="iOS"
                        style={{ background: "black" }}
                      />
                    </button>
                  </div>
                </Col>

                <Col md={colSize4}>
                  <div style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      className={
                        filter == "ALL" && !modalIsOpen
                          ? "btn btnColorStylesNew btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                      }
                      onClick={() => setFilter("ALL")}
                      data-tooltip-id="ALL_DAYS"
                    >
                      All
                      <ReactTooltip
                        id="ALL_DAYS"
                        place="top"
                        content="All"
                        style={{ background: "black" }}
                      />
                    </button>{" "}
                    <button
                      type="button"
                      className={
                        filter == "CURRENT_DAY" && !modalIsOpen
                          ? "btn btnColorStylesNew btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                      }
                      onClick={() => setFilter("CURRENT_DAY")}
                      data-tooltip-id="cd"
                    >
                      CD
                      <ReactTooltip
                        id="cd"
                        place="top"
                        content="Current Day"
                        style={{ background: "black" }}
                      />
                    </button>{" "}
                    <button
                      type="button"
                      className={
                        filter == "CURRENT_WEEK" && !modalIsOpen
                          ? "btn btnColorStylesNew btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => setFilter("CURRENT_WEEK")}
                      data-tooltip-id="cw"
                    >
                      CW
                      <ReactTooltip
                        id="cw"
                        place="top"
                        content="Current Week"
                        style={{ background: "black" }}
                      />
                    </button>{" "}
                    <button
                      type="button"
                      className={
                        filter == "CURRENT_MONTH" && !modalIsOpen
                          ? "btn btnColorStylesNew btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                      }
                      onClick={() => setFilter("CURRENT_MONTH")}
                      data-tooltip-id="cm"
                    >
                      CM
                      <ReactTooltip
                        id="cm"
                        place="top"
                        content="Current Month"
                        style={{ background: "black" }}
                      />
                    </button>{" "}
                    <button
                      type="button"
                      className={
                        filter == "CURRENT_YEAR" && !modalIsOpen
                          ? "btn btnColorStylesNew btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => setFilter("CURRENT_YEAR")}
                      data-tooltip-id="cy"
                    >
                      CY
                      <ReactTooltip
                        id="cy"
                        place="top"
                        content="Current Year"
                        style={{ background: "black" }}
                      />
                    </button>{" "}
                    <button
                      type="button"
                      className={
                        filter == "CUSTOM_DURATION" || modalIsOpen
                          ? "btn btnColorStylesNew btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                      }
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      data-tooltip-id="dfdf"
                    >
                      <FontAwesomeIcon icon={faClock} />
                      <ReactTooltip
                        id="dfdf"
                        place="top"
                        content="Custom Date Selection"
                        style={{ background: "black" }}
                      />
                    </button>{" "}
                  </div>
                </Col>
              </Row>
            </CardHeader>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 mt-2">
            {/* <Card> */}

            <CardBody style={{ padding: "0px", marginTop: "-10px" }}>
              <Row>
                <Col xl={12} md={12} sm={12}>
                  <div className="table-responsive">
                    <div className="m-3" />
                    <table className="table table-bordered mb-0">
                      <thead className="cardHeader">
                        <tr>
                          {LSuserType !== "USER" && (
                            <th
                              data-tooltip-id="fn"
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "12px 20px 12px 10px",
                              }}
                            >
                              First Name
                              <ReactTooltip
                                id="fn"
                                place="top"
                                content="First Name"
                                style={{ background: "black" }}
                              />
                            </th>
                          )}
                          {LSuserType !== "USER" && (
                            <th
                              style={{ fontSize: "13px", fontWeight: 600 }}
                              data-tooltip-id="User Name"
                            >
                              Username
                              <ReactTooltip
                                id="User Name"
                                place="top"
                                content="Username"
                                style={{ background: "black" }}
                              />
                            </th>
                          )}
                          <th
                            data-tooltip-id="RideName"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "12px 20px 12px 10px",
                            }}
                          >
                            Ride Name
                            <ReactTooltip
                              id="RideName"
                              place="top"
                              content="Ride Name"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="CrashType"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "12px 20px 12px 10px",
                            }}
                          >
                            Crash Type
                            <ReactTooltip
                              id="CrashType"
                              place="top"
                              content="Crash Type"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="CrashTime"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "12px 20px 12px 10px",
                            }}
                          >
                            Crash Time
                            <ReactTooltip
                              id="CrashTime"
                              place="top"
                              content="Crash Time"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="CrashValue"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "12px 20px 12px 10px",
                            }}
                          >
                            Crash Value
                            <ReactTooltip
                              id="CrashValue"
                              place="top"
                              content="Crash Value"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="Device"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "12px 20px 12px 10px",
                            }}
                          >
                            Device
                            <ReactTooltip
                              id="Device"
                              place="top"
                              content="Device"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="Location"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "12px 20px 12px 10px",
                            }}
                          >
                            Location
                            <ReactTooltip
                              id="Location"
                              place="top"
                              content="Location"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="Address"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "12px 20px 12px 10px",
                            }}
                          >
                            Address
                            <ReactTooltip
                              id="Address"
                              place="top"
                              content="Address"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="zipcode"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "12px 20px 12px 10px",
                            }}
                          >
                            Zipcode
                            <ReactTooltip
                              id="zipcode"
                              place="top"
                              content="Zipcode"
                              style={{ background: "black" }}
                            />
                          </th>
                          <th
                            data-tooltip-id="Lat/Lng"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "12px 20px 12px 10px",
                            }}
                          >
                            Lat | Long
                            <ReactTooltip
                              id="Lat/Lng"
                              place="top"
                              content="Latitude & Longitude"
                              style={{ background: "black" }}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataLength > 0 ? (
                          apiData.map((apiDataTemp, i) => (
                            <tr key={i}>
                              {LSuserType !== "USER" && (
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span
                                    style={{ fontSize: "13px" }}
                                    onMouseEnter={() => [
                                      localStorage.setItem(
                                        SC_USER_ID,
                                        apiDataTemp.userid
                                      ),
                                      localStorage.setItem(
                                        SC_LOGIN_USER_FIRST_NAME,
                                        apiDataTemp.firstName
                                      ),
                                    ]}
                                  >
                                    {/* <Link
                                      to={
                                        apiDataTemp.status == "Active" &&
                                        "/user-dashboard"
                                      }
                                    > */}
                                    {apiDataTemp.firstName}
                                    {/* </Link> */}
                                  </span>
                                </td>
                              )}
                              {LSuserType !== "USER" && (
                                <td
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: 400,
                                  }}
                                >
                                  {apiDataTemp.username}
                                </td>
                              )}
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  width: "auto",
                                }}
                                onMouseEnter={() => [
                                  localStorage.setItem(
                                    SC_LOGIN_USER_FIRST_NAME,
                                    apiDataTemp.username
                                  ),
                                ]}
                              >
                                <Link
                                  to={"/crashViewUser"}
                                  state={{
                                    userId: apiDataTemp.userid,
                                    divisionId: divId,
                                    rideId: apiDataTemp.deviceId,
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.rideName}
                                  </span>
                                </Link>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  {apiDataTemp.accidentType === "MILD_ACCIDENT"
                                    ? "Mild Accident"
                                    : apiDataTemp.accidentType ===
                                      "SEVERE_ACCIDENT"
                                    ? "Severe Accident"
                                    : apiDataTemp.accidentType ===
                                      "MEDIUM_ACCIDENT"
                                    ? "Medium Accident"
                                    : "-"}
                                </span>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  {UtilDateTime.formatDateTimeUTC(
                                    apiDataTemp.crashTime
                                  )}{" "}
                                  {apiDataTemp.crashTimeFormate}
                                </span>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <span
                                  data-tooltip-id={"crashValue" + i}
                                  style={{ fontSize: "12px" }}
                                >
                                  {apiDataTemp.crashValue}
                                </span>
                                <ReactTooltip
                                  id={"crashValue" + i}
                                  place="top"
                                  content={apiDataTemp.crashValue + " force"}
                                  style={{
                                    backgroundColor: "black",
                                    whiteSpace: "nowrap",
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  {apiDataTemp.device}
                                </span>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  {apiDataTemp.location}
                                </span>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  {apiDataTemp.address}
                                </span>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <span style={{ fontSize: "12px" }}>
                                  {apiDataTemp.zipCode}
                                </span>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <span
                                  data-tooltip-id={"crashLatLng" + i}
                                  style={{ fontSize: "12px" }}
                                >
                                  {apiDataTemp.latitude ||
                                  apiDataTemp.longitude ? (
                                    <span style={{ whiteSpace: "nowrap" }}>
                                      {Number(apiDataTemp.latitude).toFixed(3)}{" "}
                                      |{" "}
                                      {Number(apiDataTemp.longitude).toFixed(3)}
                                    </span>
                                  ) : (
                                    "-"
                                  )}
                                </span>
                                <ReactTooltip
                                  id={"crashLatLng" + i}
                                  place="top"
                                  content={
                                    apiDataTemp.latitude +
                                    " " +
                                    "|" +
                                    " " +
                                    apiDataTemp.longitude
                                  }
                                  style={{
                                    backgroundColor: "black",
                                    whiteSpace: "nowrap",
                                  }}
                                />
                              </td>
                            </tr>
                          ))
                        ) : dataLength === null ? (
                          <tr>
                            {" "}
                            <td colSpan="13">
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.FETCHING_DATA}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            {" "}
                            <td colSpan="13">
                              <div
                                style={{
                                  color: "black",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.NO_RECORDS_FOUND}
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </CardBody>
            {/* </Card> */}
          </Col>
        </Row>
        <Row>
          <Col>
          {dataLength > 0 ? (   <div className="d-flex justify-content-between mb-3 mt-1">
              <div>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              </div>

              <div>
             
               
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Page
                    </p>
                    <div className="pagination ">
                      <a>{pageNumber}</a>
                    </div>
                    <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      of
                    </p>
                    <div className="pagination ">
                      <a>{totalPageCount}</a>
                    </div>
                    
                  </div>
               
                  
               
              
              </div> 

              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Records Per Page
                    </p>
              <Select
            size="small"
            id="items-per-page"
            value={itemPerPage}
            onChange={handleItemPerPageChange}
            sx={{
              marginLeft: "10px",
              marginRight: "20px",
              fontSize: "12px",
              fontWeight: 200,
              color: "#000",
              border: "0px solid #606060",
              width: "76px",
              height: "41px",
              borderRadius: "5px",
              // border: "1px solid rgba(255, 189, 128, 1)",
              boxShadow: "none",
              backgroundColor:"#74788D1A",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
            }}
          >
            <MenuItem value={5} sx={{fontSize:"12px"}}>5</MenuItem>
            <MenuItem value={10} sx={{fontSize:"12px"}}>10</MenuItem>
            <MenuItem value={25} sx={{fontSize:"12px"}}>25</MenuItem>
            <MenuItem value={50} sx={{fontSize:"12px"}}>50</MenuItem>            
            <MenuItem value={100} sx={{fontSize:"12px"}}>100</MenuItem>
            <MenuItem value={250} sx={{fontSize:"12px"}}>250</MenuItem>
            <MenuItem value={500} sx={{fontSize:"12px"}}>500</MenuItem>
            <MenuItem value={1000} sx={{fontSize:"12px"}}>1000</MenuItem>
          </Select>
              </div>

              <div
               style={{ display: "flex", justifyContent: "space-between" }}
             >
               <p
                 style={{
                   marginTop: "6px",
                   marginLeft: "5px",
                   marginRight: "5px",
                 }}
               >
                 Available Records
               </p>
               <div className="pagination ">
                 <a>{totalUserV1Crash}</a>
               </div>

               
               
             </div>

              <div>
               <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      visibility: nextShow ? "visible" : "hidden",
                      float: "hidden",
                    }}
                  >
                    Next
                  </button>
              </div>
              
             
            </div>) :""}
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}

import { apiConfig } from "../config/Config.js";
import { apiEndPoint } from "../constant/ApiConstant.js";
import { ApiService } from "../service/ApiService.js";
import UtilDateTime from "../util/UtilDateTime.js";
import { trimValue } from "../util/UtilFieldValidation.js";
import {
  SC_COMPANY_ID,
  SC_USER_ID,
  SC_DIVISION_ID,
  SC_GROUP_ID,
  SC_LOGIN_USER_ID,
} from "../constant/StorageConstant.js";
import UserSessionController from "./UserSession/UserSessionController.js";

let UserCrashCtrlr = {



 // ---------------------------------- Vehicle Crash List api -------------------------------------------------- //

async getV1VehicleCrashList(
    indexStart,
    filter,
    deviceFilter,
    startDateTime,
    endDateTime,
    accidentTypeFilter,
    userId,
    pageSize,
    itemPerPage
  ) {
  
    var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var reportFields    = {
                            durationType  : filter,
                            accidentType  : accidentTypeFilter,
                            deviceType    : deviceFilter,
                          };
  
    if (companyId.length > 0 && divisionId.length > 0) {
  
      var reqPayload = {
        companyId       : companyId,
        groupId         : groupId,
        divisionId      : divisionId,
        userId          : userId, 
        offset          : indexStart,
        limit           : itemPerPage,     
        dashboardType   : "V1_VEHICLE_CRASH_LIST",
        startDateTime   : startDateTime,
        endDateTime     : endDateTime,
        dashboardFields : JSON.stringify(reportFields),
      };
  
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
  
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  
  },
  
  // --------------------------------------------------------------------------------------------- //
  
  // ---------------------------------- Vehicle Crash List User api Updated -------------------------------------------------- //
  
  async getV2VehicleCrashListUser(
    indexStart,
    filter,
    deviceFilter,
    startDateTime,
    endDateTime,
    accidentTypeFilter,
    userId,
    pageSize,
    itemPerPage
  ) {
  
    var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var reportFields    = {
                            durationType  : filter,
                            accidentType  : accidentTypeFilter,
                            deviceType    : deviceFilter,
                          };
  
    if (companyId.length > 0 && divisionId.length > 0) {
  
      var reqPayload = {
        companyId       : companyId,
        groupId         : groupId,
        divisionId      : divisionId,
        userId          : userId, 
        offset          : indexStart,
        limit           : itemPerPage,     
        dashboardType   : "V2_VEHICLE_CRASH_LIST",
        startDateTime   : startDateTime,
        endDateTime     : endDateTime,
        dashboardFields : JSON.stringify(reportFields),
      };
  
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DASHBOARD,
        1,
        reqPayload
      );
  
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  
  },
  
  // --------------------------------------------------------------------------------------------- //
  // ---------------------------------- Vehicle Single Crash List api New Updated -------------------------------------------------- //

async getUserV2VehicleCrashSingle(rideId, userId, divisionId, ) {

  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
  
  var reportFields    = {
    durationType  : "",
    accidentType  : "",
    deviceType    : "",
  };

  if (companyId && divisionId) {

    var reqPayload = {
      deviceId        : rideId,
      userId          : userId,
      companyId       : companyId,
      groupId         : groupId,
      divisionId      : divisionId,     
      sortBy          : "createdAt",
      sortOrder       : "ASC",     
      dashboardType   : "V2_VEHICLE_CRASH_LIST",     
      dashboardFields : JSON.stringify(reportFields),
    };

    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.DASHBOARD,
      1,
      reqPayload
    );

  } else {
    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
  }

},

// --------------------------------------------------------------------------------------------- //

// ------------------------------------- Crash Details api New Updated-------------------------------------------------------- //

async getCrash1KmErrorData(divisionId, rideId, userId) {
  rideId = trimValue(rideId);
  var dashboardFields = {showLastData : "YES", travelType: "RIDE_DRIVER"};
  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
  
  if (rideId) {
    var reqParams = {
      companyId: companyId,
      groupId:groupId,
      divisionId: divisionId,
      deviceId: rideId,
      userId: userId,
      category:"START_DATA,DISTANCE_DATA,ERROR_DATA,END_DATA",     
      sortBy: "createdAt",
      sortOrder: "ASC",
      offset: "0",
      limit: "1000",
      subCategory:"",
      status:"",
      dashboardType: "V1_RIDE_DATA_LIST",
      dashboardFields: JSON.stringify(dashboardFields)
    };
    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.DASHBOARD,
      1,
      reqParams
    );
  } else {
    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
  }
}, 

// --------------------------------------------------------------------------------------------- //


};

export default UserCrashCtrlr;

import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Table } from "reactstrap";
import { Tooltip as ReactTooltip, } from "react-tooltip";
import Tooltip from '@mui/material/Tooltip';
import { PiDownloadSimple } from "react-icons/pi";
import jsPDF from "jspdf";
import "../OverallSummary/overallSummaryStyle.css";
import Loader from "../../../components/Common/loader";
import {
  DEVICE_TYPE,
  SC_LOGIN_USER_FIRST_NAME,
  SC_PROFILE_ADMIN_TO_USER,
  SC_USER_ID,
  SC_USER_USERNAME,
  VEHICLE_TYPE,
} from "../../../constant/StorageConstant.js";
import { Link } from "react-router-dom";
import { displayText } from "../../../constant/MessageConstant.js";
import ValueFormat from "../../../util/ValueFormat.js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import i4 from "../../../assets/images/adminDashboard/totalRides.svg";
import UBIAnticipationNew from "../../../assets/images/adminDashboard/myRidesPageIcons/AnticipationIcon.svg";
import UBISelfConfidence from "../../../assets/images/adminDashboard/myRidesPageIcons/SelfConfidenceIcon.svg";
import UBIDrivingSkill from "../../../assets/images/adminDashboard/myRidesPageIcons/DrivingSkillIcon.svg";
import UBIDrivingStyle from "../../../assets/images/adminDashboard/myRidesPageIcons/DrivingStyleIcon.svg";
import UBIDrivingState from "../../../assets/images/adminDashboard/myRidesPageIcons/DrivingStateIcon.svg";
import UBIMobileCallUse from "../../../assets/images/adminDashboard/myRidesPageIcons/MobileUsageIcon.svg";
import UBIStressStrain from "../../../assets/images/adminDashboard/myRidesPageIcons/AccBrakingCorIcon.svg";

//New

import locationIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIcon.svg";
import minsIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/myRidesMins.svg";
import OverSpeedIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIconNew.svg";
import AccelerationIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/AccelerationIcon.svg";
import BreakingIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/BrakingIcon.svg";
import CorneringIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/CorneringIcon.svg";
import ScreenCountIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/ScreenCountIcon.svg";
import CountImage from "../../../assets/images/adminDashboard/myRidesPageIcons/CountImage.svg";
import CallCountIconNewOne from "../../../assets/images/adminDashboard/myRidesPageIcons/CallCountIconNew.svg";
import steeringWheel from "../../../assets/images/adminDashboard/steering-wheel.svg";
import UserNameIcon from "../../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";

import FTwheelerIcon from "../../../assets/images/common/twoFourWheelerIcon.svg";
import ActiveDateIcon from "../../../assets/images/common/ActiveDateIcon.svg";
import LastRideIcon from "../../../assets/images/common/lastRideIcon.svg";
import filterUpArrow from "../../../assets/images/common/upArrowIconFilter.svg";
import filterDownArrow from "../../../assets/images/common/downArrowIconFilter.svg";

import iosIconNew from "../../../assets/images/common/iosTableIcon.svg";
import andIconNew from "../../../assets/images/common/androidTableIcon.svg";
import AdminDashboardCtrlr from "../../../controller/AdminDashboardCtrlr.js";
import UtilDateTime from "../../../util/UtilDateTime.js";
import { MenuItem, Select } from "@mui/material";

export default function OverallSummary({
  receiveSummaryData,
  filter,
  selectedUserValue,
  setSelectedFilterValue,
  ADTopRowItemsTemp,
  sendDataToParent
}) {
  const [indexStart, setIndexStart] = useState(0);
  const [filterValuee, setFilterValuee] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [summaryDataList, setSummaryDataList] = useState([]);
  const [dataLength, setDataLength] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [dataDisplay, setDataDisplay] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState();
  const [nextShow, setNextShow] = useState();
  const [prevShow, setPrevShow] = useState();
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [itemPerPage, setItemPerPage] = useState(5);
  
  const inputRef = useRef();
  const [assAndDecValue, setAssAndDecValue] = useState("ASC");
  const [selectedValue, setSelectedValue] = useState("ACTIVE_USER_LIST"); 

  const [totalUserData, setTotalUserData] = useState(0);

  const pageSize = itemPerPage;  

  const handleUserClick = (type) => {
    const selectedMapping = {
      ACTIVE: "ACTIVE_USER_LIST",
      INACTIVE: "INACTIVE_USER_LIST",
      UNREGISTERED: "UNREGISTERED_USER_LIST",
      DEACTIVATED: "DEACTIVATED_USER_LIST",
    };
    setSelectedValue(selectedMapping[type]);
    setSelectedFilterValue(selectedMapping[type]);
    setIndexStart(0);
    setPageNumber(1);
  };

  const getOverAllSummary = async (indexStartOfSet,searchClear) => {
    
    setDataDisplay(null);
    setShowLoader(true);
    const transportType = selectedUserValue;
    // var result = await AdminDashboardCtrlr.getAdminV1UserList(
    //   indexStartOfSet,
    //   assAndDecValue,
    //   filter.durationFilter,
    //   searchText,
    //   filter.startDateTime,Total License Remaining
    //   filter.endDateTime,
    //   selectedValue,
    //   transportType
    // );

    var filters = (filter.durationFilter == "ALL") ? "" : filter.durationFilter;
    var searchData = (searchClear == "CLEAR") ? "" : searchText;
    var result = (selectedValue == "ACTIVE_USER_LIST") && (filters == "CURRENT_YEAR") ? await AdminDashboardCtrlr.getAdminV1MultiUserPeriodicalSummaryList(
      indexStartOfSet,
      itemPerPage,
      assAndDecValue,
      filters,
      searchData,
      filter.startDateTime,
      filter.endDateTime,
      selectedValue,
      transportType,      
    ) : await AdminDashboardCtrlr.getAdminV1UserList(
      indexStartOfSet,
      itemPerPage,
      assAndDecValue,
      filters,
      searchData,
      filter.startDateTime,
      filter.endDateTime,
      selectedValue,
      transportType,      
    );

    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;

    if (status == "SUCCESS") {
      setShowLoader(false);

      var resultJSONData = resultJSON.data;
     
    
      if (resultJSONData.length > 0) {
        setDataLength(resultJSONData.length);
        setDataDisplay(null);        
        var summaryData = [];

        resultJSONData.map((value, i) => {
          var profilePicturePathAdminToUser = ValueFormat.parseAsString(value.profilePicturePath);
          
          var firstName = ValueFormat.parseAsString(
            value.firstName.toUpperCase()
          );
          var drivingCategory = ValueFormat.parseAsString(
            ValueFormat.capitalizeFirstLetterAndFormat(
              value.calculatedDrivingCategory
            )
          );
          var username = ValueFormat.parseAsString(value.username) || "-";
          var totalRides =
            ValueFormat.parseAsString(
              ValueFormat.capitalizeFirstLetterAndFormat(value.totalRideCount)
            ) || "-";
          var userId = ValueFormat.parseAsString(value.userId);
          var overallDrivingScore =
            ValueFormat.parseAsFloat(value.drivingScore, 0) || "-";
          var overallAnticipation =
            ValueFormat.parseAsFloat(value.anticipationScore, 0) || "-";
          var overallSelfConfidence =
            ValueFormat.parseAsFloat(value.selfConfidenceScore, 0) || "-";
          var overallDrivingSkill =
            ValueFormat.parseAsFloat(value.drivingSkillScore, 0) || "-";
          var dStyleValue =
            ValueFormat.parseAsFloat(value.drivingStyleScore, 0) || "-";
          var dStateValue =
            ValueFormat.parseAsFloat(value.drivingStateScore, 0) || "-";         
          var kmsDriven =
            ValueFormat.parseAsFloat(value.overSpeedScore, 0) || "-";
          var mobileCallValue =
            ValueFormat.parseAsFloat(value.mobileCallScore, 2) || "-";
          var mobileScreenValue =
            ValueFormat.parseAsFloat(value.mobileScreenCount, 2) || "-";
          var abcPoint =
            ValueFormat.parseAsFloat(value.accBrakCorScore, 0) || "-";
          var calculatedDrivingScore =
            ValueFormat.parseAsFloat(value.calculatedDrivingScore, 0) || "-";
          
          var calculatedDrivingScoreValue =
            ValueFormat.parseAsString(value.calculatedDrivingScore) || 0;
          // console.log(calculatedDrivingScoreValue,"calculatedDrivingScoreValue")
          var drivingBehaviourScore =
            ValueFormat.parseAsFloat(value.calculatedBehaviourScore) || "-";

          var totalCount = ValueFormat.parseAsInt(value.totalCount);
      
          setTotalUserData(totalCount);
          setTotalPageCount(Math.ceil(totalCount / pageSize));
          setNextShow(totalCount - indexStartOfSet > pageSize);

          var totalAccelerationCount =
            parseFloat(
              ValueFormat.parseAsFloat(value.severeAccelerationLowCount, 0)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(value.severeAccelerationMediumCount, 0)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(value.severeAccelerationHighCount, 0)
            );

          var totalBrakingCount =
            parseFloat(
              ValueFormat.parseAsFloat(value.severeBrakingLowCount, 0)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(value.severeBrakingMediumCount, 0)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(value.severeBrakingHighCount, 0)
            );

          var totalCorneringCount =
            parseFloat(
              ValueFormat.parseAsFloat(value.severeCorneringLowCount, 0)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(value.severeCorneringMediumCount, 0)
            ) +
            parseFloat(
              ValueFormat.parseAsFloat(value.severeCorneringHighCount, 0)
            );

          var totalMobileUseInAcceptedCount =
            ValueFormat.parseAsFloat(value.mobileCallCount, 0) || "-";


          const parsedNum = parseFloat(value.mobileCallDurationInMin);

          var totalMobileUseInAcceptedDuration = ValueFormat.parseAsFloat(value.mobileCallDurationInMin, 2) || "-";

          var totalMobileScreenScreenOnCount =
            ValueFormat.parseAsFloat(value.mobileScreenCount, 0) || "-";
          
            var totalMobileScreenScreenOnDistance =
            ValueFormat.parseAsFloat(value.mobileScreenDistanceInKm, 0) || "-";

          const parsedNumMobileScreen = parseFloat(value.mobileScreenDurationInMin);

          var totalMobileScreenScreenOnDuration =
          ValueFormat.parseAsFloat(value.mobileScreenDurationInMin, 2) || "-";


          var totalOverSpeedCount =
            ValueFormat.parseAsFloat(value.overSpeedCount, 0) || "-";

          const parsedNumOverSpeed = parseFloat(value.overSpeedDurationInMin);
          var totalOverSpeedDuration = ValueFormat.parseAsFloat(value.overSpeedDurationInMin, 2) || "-";

          var totalHighRiskCount =
            ValueFormat.parseAsFloat(value.highAlertCount, 0) || "-";

          var tKiloMeter = ValueFormat.parseAsFloat(value.totalDistanceInKm, 2) || "-";

          var tTravelTime =
            ValueFormat.parseAsFloat(value.totalDurationInMin, 2) || "-";

          var drivingCategoryCount = value.drivingCategoryDetail;

          var badDrivingCount =
            ValueFormat.parseAsInt(drivingCategoryCount.badDrivingCount) || 0;

          var dangerousDrivingCount =
            ValueFormat.parseAsInt(
              drivingCategoryCount.dangerousDrivingCount
            ) || 0;

          var goodDrivingCount =
            ValueFormat.parseAsInt(drivingCategoryCount.goodDrivingCount) || 0;

          var quietSafeDrivingCount =
            ValueFormat.parseAsInt(
              drivingCategoryCount.quietSafeDrivingCount
            ) || 0;

          var vehicleType = ValueFormat.parseAsString(value.vehicleType);

          var deviceType = ValueFormat.parseAsString(value.deviceType);

          var activationDate = ValueFormat.parseAsString(
            value.createdAtToTimeZone
          );

          var lastRideData = (value.lastRideDateTimeToTimeZone.length > 1) ?  ValueFormat.parseAsString(
            value.lastRideDateTimeToTimeZone 
          ) : "-";

          var overallRideCount = ValueFormat.parseAsString(
            value.overallRideCount
          );

          var lastRideName = ValueFormat.parseAsString(
            value.lastRideName
          );

          var statusData = ValueFormat.parseAsString(value.userStatus);

          let statusList = "";

          if (statusData == "INITIATED" || statusData == "REGISTERED") {
            statusList = "Unregistered";
          } else if (statusData == "ACTIVE" && overallRideCount <= 0) {
            statusList = "Inactive";
          } else if (statusData == "ACTIVE" && overallRideCount > 0) {
            statusList = "Active";
          } else if (statusData == "DEACTIVATED") {
            statusList = "Deactivated";
          } else {
            statusList = "-";
          }

          var mobileUsage =
            parseFloat(value.mobileCallScore) +
            parseFloat(value.mobileScreenScore);
            
          var mobileUsageTotal = ValueFormat.parseAsFloat(mobileUsage, 0) || 0;
          var resultObject = {
            drivingCategory: drivingCategory,
            firstName: firstName,
            username: username || "N/A",
            drivingScore: overallDrivingScore,
            calculatedDrivingScore: calculatedDrivingScore,
            calculatedDrivingScoreValue:calculatedDrivingScoreValue,
            drivingBehaviourScore: drivingBehaviourScore,
            totalRides: totalRides,
            userId: userId,
            ABCPoint: abcPoint,
            mobileScreenPointNew: mobileScreenValue,
            mobileUsePointNew: mobileCallValue,
            overallAnticipation: overallAnticipation,
            overallSelfConfidence: overallSelfConfidence,
            overallDrivingSkill: overallDrivingSkill,
            totalKmSPoint: kmsDriven,
            DSP: dStateValue,
            DSTP: dStyleValue,
            totalAccelerationCount: totalAccelerationCount,
            totalBrakingCount: totalBrakingCount,
            totalCorneringCount: totalCorneringCount,
            totalOverSpeedDuration: totalOverSpeedDuration,
            totalMobileUseInAcceptedCount: totalMobileUseInAcceptedCount,
            lastRideName:lastRideName || "-",
            totalMobileUseInAcceptedDuration: totalMobileUseInAcceptedDuration,

            totalMobileScreenScreenOnCount: totalMobileScreenScreenOnCount,
            totalMobileScreenScreenOnDistance:
              totalMobileScreenScreenOnDistance,
            totalMobileScreenScreenOnDuration:
              totalMobileScreenScreenOnDuration,

            totalOverSpeedCount: totalOverSpeedCount,           
            totalHighRiskCount: totalHighRiskCount,

            tKiloMeter: tKiloMeter,
            tTravelTime: tTravelTime,

            mobileUsage: mobileUsageTotal,

            status: statusList,

            vehicleType:
              vehicleType == "FOUR_WHEELER"
                ? "FOUR_WHEELER"
                : vehicleType == "TWO_WHEELER"
                ? "TWO_WHEELER"
                : "-",
            deviceType: deviceType,
            activationDate: activationDate,
            activationTime: activationDate.split(" ")[1],
            lastRideData: lastRideData,
            // lastRideTime: lastRideData.split(" ")[1],
            badDrivingCount:
              ((badDrivingCount / totalRides) * 100).toFixed(0) || 0,
            dangerousDrivingCount:
              ((dangerousDrivingCount / totalRides) * 100).toFixed(0) || 0,
            goodDrivingCount:
              ((goodDrivingCount / totalRides) * 100).toFixed(0) || 0,
            quietSafeDrivingCount:
              ((quietSafeDrivingCount / totalRides) * 100).toFixed(0) || 0,
            goodDrivingCountValue:goodDrivingCount,
            badDrivingCountValue:badDrivingCount,
            dangerousDrivingCountValue:dangerousDrivingCount,
            quietSafeDrivingCountValue:quietSafeDrivingCount,
            profilePicturePathAdminToUser:profilePicturePathAdminToUser
          };
          summaryData.push(resultObject);

          setDataDisplay(
            Object.values(resultObject).some((value) => value !== 0)
          );
        });
      } else {
        setDataLength(0)
        setTotalUserData(0)
        setDataDisplay(false);
      }

      receiveSummaryData({ data: summaryData, filter: filter.durationFilter });

      setSummaryDataList(summaryData);
      // console.log(summaryData, "summaryDatasummaryData");
      
    } else if (status == "FAILED") {
      setShowLoader(false);
      setDataDisplay(false);

      var code = resultJSON.error.code;

      if (code == "E1206") {
        setShowLoader(false);
        setDataDisplay("failed");
      }
    }
    setShowLoader(false);

    if (indexStartOfSet == 0) {
      setPrevShow(false);
    } else if (indexStartOfSet > 0) {
      setPrevShow(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIndexStart(0);
      setPageNumber((current) => current - current + 1);
      event.preventDefault();
      inputRef.current.click();
    }
  };

  

  useEffect(() => {   
   
    if(filterValuee == filter.durationFilter) {      
      getOverAllSummary(indexStart,"");
    } else {      
      setFilterValuee(filter.durationFilter);
      getOverAllSummary(0,"");
      setIndexStart(0);
      setPageNumber(1);
    }
    // var indexStartOfSet = ["CURRENT_DAY", "CURRENT_WEEK", "CURRENT_MONTH", "CURRENT_YEAR"].includes(filter.durationFilter) ? 0 : indexStart;      
    // console.log(filter.durationFilter,filterValuee,"indexStartOfSet")
    // getOverAllSummary(indexStart);
  }, [
    itemPerPage,
    selectedValue,
    assAndDecValue,
    filter.durationFilter,
    filter.startDateTime,
    filter.endDateTime,
    selectedUserValue,
    indexStart, count
  ]);

  // useEffect(() => {
  //   if (count > 0) getOverAllSummary(indexStart);
  // }, [indexStart, count]);

  // useEffect(() => {
  //   setPageNumber(1);
  //   if (indexStart == 0) setCount((prev) => prev + 1);
  //   else setIndexStart(0);
  // }, [
  //   filter.durationFilter,
  //   filter.startDateTime,
  //   filter.endDateTime,
  //   selectedUserValue,
  // ]);

  const handleSearch = (e) => {
    e.preventDefault();
    getOverAllSummary(0,"");    
    setPageNumber((current) => current - current + 1);
  };

  const TableStyle = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      border: "0.2px",
      margin: "0px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: "white",
      position: "sticky",
      height: "20px",
      fontWeight: 600,
      fontSize: "12px",
    },
    tbody: {
      display: "block",
      height: "250px",
      overflow: "auto",
    },
  };

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };

  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const formatTime = (timeString) => {        
    const lastRideTime = timeString.split(" ")[1];   
    const today = new Date().toISOString().split('T')[0];  
    const dateTimeString = `${today}T${lastRideTime}`;
  
    const date = new Date(dateTimeString);
    if(timeString.length > 1) {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    }
    return "-";
  };

  

  const clearSearchInput = () => {
       
    setSearchText("");      
    getOverAllSummary(0,"CLEAR");     
    setPageNumber(1); 
      
  }

  const handleItemPerPageChange = (event) => {
    setItemPerPage(event.target.value);
    setPageNumber(1);
    setIndexStart(0);
  }

                    
  sendDataToParent(pageNumber,totalPageCount,totalUserData,summaryDataList,assAndDecValue,searchText)

  return (
    <>
      {showLoader && <Loader />}

      <Row style={{ marginTop: "-20px",marginBottom:"5px"}}>
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", }}>
          {/* {selectedValue === "ACTIVE_USER_LIST" ? ( */}
          <div className="my-3" style={{ height: "40px", display: "flex", marginTop: "-50px", }}>
          <div
  style={{
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: selectedValue === "ACTIVE_USER_LIST" ? "55%" : "70%",
    position: "relative", // Add relative positioning to the wrapper
  }}
>
  <input
    // type="search"
    style={{
      border: "1px solid #EEEBEB",
      width: "100%",
      height: "80%",
      paddingRight: "30px", // Add padding to avoid overlap with the icon
    }}
    value={searchText}
    onKeyPress={handleKeyPress}
    onChange={(e) => {
      setSearchText(e.target.value);
    }}
    placeholder="Search"
  />
  {searchText &&
  <span
    style={{
      position: "absolute",
      right: "10px", // Position the icon inside the input box
      top: "50%",
      transform: "translateY(-50%)", // Vertically center the icon
      cursor: "pointer",
      color: "#888", // Optional: Icon color
    }}
    onClick={clearSearchInput} // Clear input when clicked
  >
    X
  </span>}
</div>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
              <button ref={inputRef}
                style={{ marginLeft: "5px", height: "80%", display: "flex", justifyContent: "center", flexDirection: "column", }}
                className="btn btnColorStylesNew me-3"
                type="button"
                onClick={handleSearch}
              >
                <i className="bx bx-search-alt align-middle"></i>
              </button>
            </div>
            <div style={{ height: "40px", display:(selectedValue === "ACTIVE_USER_LIST") ? "flex" : "none", width: "55%", }} >
              <p style={{ fontSize: "12px", display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: "0px", }} >
                High - Low Performers
              </p>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                <button style={{ marginLeft: "5px", height: "80%" }}
                  className={
                    assAndDecValue == "ASC"
                      ? "btn btnColorStylesNew btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  type="button"
                  onClick={() => setAssAndDecValue("ASC")}
                >
                  <img style={{ display: "flex", justifyContent: "center", flexDirection: "column", }}
                    src={filterUpArrow} alt="filterIcon"
                  />
                </button>
              </div>
            </div>
            <div style={{ height: "40px", display:(selectedValue === "ACTIVE_USER_LIST") ? "flex" : "none", marginLeft: "15px", width: "55%",}} >
              <p style={{ fontSize: "12px", display: "flex", justifyContent: "center", flexDirection: "column", marginBottom: "0px",  }} >
                Low - High Performers
              </p>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                <button style={{ marginLeft: "5px", height: "80%" }}
                  className={
                    assAndDecValue == "DESC"
                      ? "btn btnColorStylesNew btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  type="button"
                  onClick={() => setAssAndDecValue("DESC")}
                >
                  <img style={{ display: "flex", justifyContent: "center", flexDirection: "column", }}
                    src={filterDownArrow}
                    alt="filterIcon"
                  />
                </button>
              </div>
            </div>
          </div>
         


          <div className="my-3" style={{ height: "40px", display: "flex", marginTop: "-50px", }}>
            <div className="ms-auto pe-0  border-secondary">
              <div style={{ float: "right" }}>
              {["ACTIVE", "INACTIVE", "UNREGISTERED", "DEACTIVATED"].map((type) => (
                <button
                  key={type}
                  type="button"
                  style={{
                    fontWeight: 700,
                    // backgroundColor: selectedValue === type ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm", // Highlight selected
                    // color: selectedValue === type ? "#fff" : "#000",
                    margin:"5px"
                  }}
                  className={`btn btn-sm ${
                    selectedValue === `${type}_USER_LIST` ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm"
                  }`}
                  id={type}
                  onClick={() => handleUserClick(type)} // Pass type to handler
                >
                  {type.charAt(0) + type.slice(1).toLowerCase()} {/* Format button label */}
                </button>
              ))}
              </div>
            </div>
          </div>

        </div>

        <div
          style={{
            position: "relative",
            maxHeight: 490,
            overflowY: "auto",
            borderCollapse: "collapse",
          }}
        >
          <Table bordered style={TableStyle.table}>
            <thead style={{ position: "sticky" }}>
              <tr>
                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  User
                  <br />
                  Details
                </th>
                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Driving
                  <br />
                  Score
                </th>

                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Driving
                  <br />
                  Risk
                </th>

                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Driving Risk
                  <br />
                  Data
                </th>

                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Driving <br />
                  Behaviour
                </th>
                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Driving Behaviour <br />
                  Data
                </th>
                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Crash <br />
                  Probability
                </th>

                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Acc/Brak/Cor
                  <br />
                  Counts
                </th>
                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Over Speed
                  <br />
                  Data
                </th>

                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Mobile
                  <br />
                  Usage
                </th>

                <th
                  className="text-nowrap cardHeaderForDBRDFV"
                  style={{
                    ...TableStyle.th,
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  High Risk<br />
                  Alert Counts
                </th>
              </tr>
            </thead>

            <tbody>
              {dataLength > 0 ? (
                (summaryDataList || []).map((apiData, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        fontSize: "10px",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={UserNameIcon}
                              alt="icons2"
                              style={{
                                height: "20px",
                                marginLeft: "-2px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginLeft: "12px",
                              fontSize: "11px",
                            }}
                          >
                            {apiData.status == "Active" ? (
                              <span
                                style={{
                                  fontSize: "11px",
                                  color: "#5156BE",
                                  marginBottom: "0px",
                                  fontWeight: 700,
                                  cursor: "pointer",
                                }}
                                onMouseEnter={() => [
                                  localStorage.setItem(
                                    SC_USER_ID,
                                    apiData.userId
                                  ),
                                  localStorage.setItem(
                                    SC_LOGIN_USER_FIRST_NAME,
                                    apiData.firstName
                                  ),
                                  localStorage.setItem(
                                    SC_USER_USERNAME,
                                    apiData.username
                                  ),
                                  localStorage.setItem(
                                    DEVICE_TYPE,
                                    apiData.deviceType
                                  ),
                                  localStorage.setItem(
                                    VEHICLE_TYPE,
                                    apiData.vehicleType
                                  ),
                                  localStorage.setItem(
                                    SC_PROFILE_ADMIN_TO_USER,
                                    apiData.profilePicturePathAdminToUser
                                  ),
                                ]}
                              >
                                <Link to={"/user-dashboard"}>
                                  {apiData.firstName}
                                </Link>
                              </span>
                            ) : (
                              <span
                                style={{
                                  fontSize: "11px",
                                  color: "#5156BE",
                                  marginBottom: "0px",
                                  fontWeight: 700,
                                  cursor: "default",
                                }}
                              >
                                {apiData.firstName}
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            marginRight: "4px",
                            marginLeft: "4px",
                            marginTop: "2px",
                          }}
                        >
                          |
                        </div>
                        <div>
                          <p
                            style={{
                              fontSize: "11px",
                              color: "#000000",
                              marginBottom: "0px",
                              fontWeight: 700,
                              cursor: "pointer",
                              marginTop: "2px",
                            }}
                          >{`${apiData.username}`}</p>
                        </div>
                        <div>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-sm "
                            style={{
                              backgroundColor: "#D7EC23",
                              padding: "3px",
                              fontSize: "8px",
                              fontWeight: 600,
                            }}
                          >
                            {apiData.status}
                          </button>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={i4}
                              alt="icons2"
                              style={{
                                height: "20px",
                                marginLeft: "-2px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginLeft: "12px",
                              fontSize: "11px",
                            }}
                          >
                            {apiData.status == "Active" ? (
                              <p
                                style={{
                                  fontSize: "11px",
                                  marginBottom: "0px",
                                  fontWeight: 700,
                                  cursor: "pointer",
                                }}
                                onMouseEnter={() => [
                                  localStorage.setItem(
                                    SC_USER_ID,
                                    apiData.userId
                                  ),
                                  localStorage.setItem(
                                    SC_LOGIN_USER_FIRST_NAME,
                                    apiData.firstName
                                  ),
                                  localStorage.setItem(
                                    SC_USER_USERNAME,
                                    apiData.username
                                  ),
                                  localStorage.setItem(
                                    DEVICE_TYPE,
                                    apiData.deviceType
                                  ),
                                ]}
                              >
                                {" "}
                                <Link to={"/user-dashboard"}>
                                  {`${apiData.totalRides}`} Rides
                                </Link>
                              </p>
                            ) : (
                              <p
                                style={{
                                  fontSize: "11px",
                                  marginBottom: "0px",
                                  fontWeight: 700,
                                  cursor: "default",
                                }}
                              >
                                {`${apiData.totalRides}`} Rides
                              </p>
                            )}
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "2px",
                                color: "#00A74B",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                              Good
                            </p>
                            <p
                              style={{ marginBottom: "2px", marginLeft: "30px" }}
                            >
                              {apiData.goodDrivingCount == "NaN"
                                ? "0"
                                : apiData.goodDrivingCount}{" "}
                              % {`(${apiData?.goodDrivingCountValue})`}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "2px",
                                color: "#D1C600",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                              Quite Safe
                            </p>
                            <p style={{ marginBottom: "2px" }}>
                              {apiData.quietSafeDrivingCount == "NaN"
                                ? "0"
                                : apiData.quietSafeDrivingCount}{" "}
                              % {`(${apiData?.quietSafeDrivingCountValue})`}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "2px",
                                color: "#F14A21",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                              Dangerous
                            </p>
                            <p
                              style={{
                                marginBottom: "2px",
                                marginLeft: "10px",
                              }}
                            >
                              {apiData.dangerousDrivingCount == "NaN"
                                ? "0"
                                : apiData.dangerousDrivingCount}{" "}
                              % {`(${apiData?.dangerousDrivingCountValue})`}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#CB0206",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                              Bad
                            </p>
                            <p style={{ marginBottom: "0px" }}>
                              {apiData.badDrivingCount == "NaN"
                                ? "0"
                                : apiData.badDrivingCount}{" "}
                              % {`(${apiData?.badDrivingCountValue})`}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={locationIcon}
                                alt="icons2"
                                style={{
                                  height: "25px",
                                  marginLeft: "-2px",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                              }}
                            >
                              {apiData.tKiloMeter + " Kms"}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              marginTop: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={minsIcon}
                                alt="icons2"
                                style={{ height: "25px", marginLeft: "-4px" }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                              }}
                            >
                             { 
                                parseFloat(apiData.tTravelTime) < 60 
                                  ? `${parseFloat(apiData.tTravelTime).toFixed(2)} Mins` 
                                  : `${(parseFloat(apiData.tTravelTime) / 60).toFixed(2)} Hours` 
                              }

                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            {apiData.deviceType == "ANDROID" ? (
                              <img
                                src={andIconNew}
                                alt="icons2"
                                style={{ height: "25px", marginLeft: "-4px" }}
                              />
                            ) : apiData.deviceType == "IOS" ? (
                              <img
                                src={iosIconNew}
                                alt="icons2"
                                style={{ height: "25px", marginLeft: "-4px" }}
                              />
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginLeft: "8px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src={FTwheelerIcon}
                              alt="icons2"
                              style={{ height: "25px", marginLeft: "-4px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              marginLeft: "4px",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: "11px",
                            }}
                          >
                            {apiData.vehicleType === "FOUR_WHEELER" 
                              ? "4W" 
                              : apiData.vehicleType === "TWO_WHEELER" 
                              ? "2W" 
                              : "-"}
                          </div>
                        </div>

                        <div style={{ display: "flex", marginLeft: "5px", }} >
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                            <img src={ActiveDateIcon} alt="icons2" style={{ height: "25px" }} />
                          </div>
                          <div
                            style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#0500A3", fontWeight: 400, fontSize: "11px", }} >
                            <p style={{ marginBottom: "0px", color:"#D23BCD" }}>Act. Date</p>
                            <p style={{ marginBottom: "0px", whiteSpace: "nowrap", }} >
                              {UtilDateTime.formatDateInt(apiData.activationDate)}
                            </p>
                          </div>
                        </div>

                        {/* <div style={{ display: "flex", marginLeft: "5px", }} >
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                            <div>
                              <Tooltip title={
                                <>
                                <div>Activation Date: {`${UtilDateTime.formatDateTimeNew(apiData.activationDate).split(" ")[0]} ${formatTime(apiData.activationDate)}`} </div>
                                </>
                              }>
                            <img src={ActiveDateIcon} alt="icons2" style={{ height: "25px" }} />
                              </Tooltip>
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#0500A3", fontWeight: 400, fontSize: "11px", }} >
                            <p style={{ marginBottom: "0px" }}>Act. Date</p>
                            <p style={{ marginBottom: "0px", whiteSpace: "nowrap", }} >
                              {UtilDateTime.formatDateInt(apiData.activationDate)}
                            </p>
                          </div>
                        </div> */}

                        <div style={{ display: "flex", marginLeft: "8px", }} >
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                            <img src={LastRideIcon} alt="icons2" style={{ height: "25px" }} />
                          </div>
                          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px",  fontWeight: 400, fontSize: "11px", }} >
                            <p style={{ marginBottom: "0px", color:"#DD0000",}}> Last Ride: <span style={{color:"#000000"}}>{apiData.lastRideName}</span> </p>
                            <p style={{ marginBottom: "0px", whiteSpace: "nowrap", color: "#0500A3" }} >
                              {apiData.lastRideData.length > 1
                              ? `${UtilDateTime.formatDateTimeNew(apiData.lastRideData).split(" ")[0]} | ${formatTime(apiData.lastRideData)}`
                              : `- | -`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                        fontSize: "10px",
                        verticalAlign: "middle",
                      }}
                      data-tooltip-id={`ETVN${index}`}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            height: 60,
                            width: 60,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <CircularProgressbar
                            value={apiData.calculatedDrivingScore}
                            text={apiData.totalRides > 0 ? `${apiData.calculatedDrivingScore}%` : "-"}
                            styles={buildStyles({
                              width: "10px",
                              textSize: "27px",
                              fontWeight: 700,
                              pathColor:
                                apiData.calculatedDrivingScoreValue >= 0 &&
                                apiData.calculatedDrivingScoreValue <= 80
                                  ? "#E21212"
                                  : apiData.calculatedDrivingScoreValue > 80 &&
                                    apiData.calculatedDrivingScoreValue <= 93
                                  ? "#FFBF00"
                                  : "#19543e",
                              trailColor: "#0095FF",
                              textColor: "#5156BE",
                            })}
                            className="progress-bar-rideList"
                          ></CircularProgressbar>
                        </div>
                        <ReactTooltip
                          id={`ETVN${index}`}
                          place="top"
                          content={apiData.totalEfficiencyTVNew}
                          style={{ backgroundColor: "black" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          {apiData.totalRides > 0 && (<img
                            src={steeringWheel}
                            alt="icons2"
                            style={{ height: "20px" }}
                          />)}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            fontSize: "11px",
                            marginLeft: "5px",
                          }}
                        >
                          {apiData.totalRides > 0 ? apiData.drivingCategory : "-"}
                        </div>
                      </div>
                    </td>

                    <td
                      className="text-nowrap"
                      data-tooltip-id={`categoryTooltip_${index}`}
                      style={{
                        textAlign: "center",
                        fontSize: "13px",
                        verticalAlign: "middle",
                        borderRight: "0px",
                        fontWeight: 700,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `${ValueFormat.formatDecimalIfRounded(
                          apiData.drivingScore
                        )}`,
                      }}
                    />

                    <td
                      style={{
                        textAlign: "left",
                        fontSize: "10px",
                        verticalAlign: "middle",
                        borderLeft: "0px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={UBIAnticipationNew}
                              alt="icons2"
                              style={{ height: "25px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                              }}
                            >
                              Anticipation
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {" - "}
                          {`${ValueFormat.formatDecimalIfRounded(
                            apiData.overallAnticipation
                          )}`}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={UBISelfConfidence}
                              alt="icons2"
                              style={{ height: "25px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "7px",
                                fontSize: "11px",
                              }}
                            >
                              Self Confidence
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {" - "}
                          {ValueFormat.formatDecimalIfRounded(
                            apiData.overallSelfConfidence
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={UBIDrivingSkill}
                              alt="icons2"
                              style={{ height: "25px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                              }}
                            >
                              Driving Skill
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {" - "}
                          {ValueFormat.formatDecimalIfRounded(
                            apiData.overallDrivingSkill
                          )}
                        </div>
                      </div>
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                        fontSize: "10px",
                        verticalAlign: "middle",
                        borderRight: "0px",
                        fontWeight: 700,
                        fontSize: "11px",
                      }}
                    >
                      <div
                        style={{ fontSize: "13px" }}
                      >{`${apiData.drivingBehaviourScore}`}</div>
                    </td>
                    <td
                      className="text-nowrap"
                      style={{
                        textAlign: "left",
                        fontSize: "10px",
                        verticalAlign: "middle",
                        borderLeft: "0px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={UBIDrivingStyle}
                              alt="icons2"
                              style={{ height: "25px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                              }}
                            >
                              Driving Style
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {" - "}
                          {`${ValueFormat.formatDecimalIfRounded(
                            apiData.DSTP
                          )}`}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={UBIDrivingState}
                              alt="icons2"
                              style={{ height: "25px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                              }}
                            >
                              Driving State
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {" - "}
                          {ValueFormat.formatDecimalIfRounded(apiData.DSP)}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={UBIMobileCallUse}
                              alt="icons2"
                              style={{ height: "25px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "9px",
                                fontSize: "11px",
                              }}
                            >
                              Mobile Usage
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                          }}
                        >
                          {" - "}
                          {apiData.mobileUsage}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={OverSpeedIcon}
                              alt="icons2"
                              style={{ height: "25px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "10px",
                                fontSize: "11px",
                              }}
                            >
                              Over Speed
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {" - "}
                          {ValueFormat.formatDecimalIfRounded(
                            apiData.totalKmSPoint
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={UBIStressStrain}
                              alt="icons2"
                              style={{ height: "25px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "10px",
                                fontSize: "11px",
                              }}
                            >
                              ABC
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {" - "}
                          {ValueFormat.formatDecimalIfRounded(apiData.ABCPoint)}
                        </div>
                      </div>
                    </td>
                    <td
                      className="text-nowrap"
                      style={{
                        textAlign: "left",
                        fontSize: "10px",
                        verticalAlign: "middle",
                        fontSize: "11px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            height: 60,
                            width: 60,
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <CircularProgressbar
                            value="-"
                            text="-"
                            styles={buildStyles({
                              width: "10px",
                              textSize: "27px",
                              fontWeight: 700,
                              trailColor: "#0095FF",
                              textColor: "#5156BE",
                            })}
                            className="progress-bar-rideList"
                          ></CircularProgressbar>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginTop: "20px",
                          }}
                        >
                          <p style={{ fontSize: "11px" }}>L0 is Safe</p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginTop: "-5px",
                          }}
                        >
                          <p style={{ fontSize: "11px" }}>L6 is Unsafe</p>
                        </div>
                      </div>
                    </td>

                    <td
                      style={{
                        textAlign: "left",
                        fontSize: "10px",
                        verticalAlign: "middle",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={AccelerationIcon}
                              alt="totalAccelerationCount"
                              style={{ height: "25px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                              }}
                            >
                              Acc
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {" - "}
                          {apiData.totalAccelerationCount}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={BreakingIcon}
                              alt="totalBrakingCount"
                              style={{ height: "25px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                              }}
                            >
                              Brak
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {" - "}
                          {apiData.totalBrakingCount}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <img
                              src={CorneringIcon}
                              alt="totalCorneringCount"
                              style={{ height: "25px" }}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                marginLeft: "8px",
                                fontSize: "11px",
                              }}
                            >
                              Cor
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {" - "}
                          {apiData.totalCorneringCount}
                        </div>
                      </div>
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        fontSize: "10px",
                        verticalAlign: "middle",
                        fontSize: "11px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={CountImage}
                            alt="icons2"
                            style={{ height: "25px", marginLeft: "-4px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "5px",
                            fontSize: "11px",
                          }}
                        >
                          {apiData.totalOverSpeedCount} {"Count"}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={minsIcon}
                            alt="icons2"
                            style={{ height: "25px", marginLeft: "-4px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "5px",
                            fontSize: "11px",
                          }}
                        >
                          { 
                            parseFloat(apiData.totalOverSpeedDuration) < 60 
                              ? `${parseFloat(apiData.totalOverSpeedDuration).toFixed(2)} Mins` 
                              : `${(parseFloat(apiData.totalOverSpeedDuration) / 60).toFixed(2)} Hours` 
                          }
                        </div>
                      </div>
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        fontSize: "10px",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={ScreenCountIcon}
                            alt="icons2"
                            style={{ height: "24px", marginLeft: "-4px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {apiData.totalMobileScreenScreenOnCount} {"Count"}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={minsIcon}
                            alt="icons2"
                            style={{ height: "25px", marginLeft: "-4px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          { 
                            parseFloat(apiData.totalMobileScreenScreenOnDuration) < 60 
                              ? `${parseFloat(apiData.totalMobileScreenScreenOnDuration).toFixed(2)} Mins` 
                              : `${(parseFloat(apiData.totalMobileScreenScreenOnDuration) / 60).toFixed(2)} Hours` 
                          }
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={CallCountIconNewOne}
                            alt="icons2"
                            style={{ height: "26px", marginLeft: "-4px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                          {apiData.totalMobileUseInAcceptedCount} {"Count"}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={minsIcon}
                            alt="icons2"
                            style={{ height: "25px", marginLeft: "-4px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "2px",
                            fontSize: "11px",
                          }}
                        >
                         { 
                            parseFloat(apiData.totalMobileUseInAcceptedDuration) < 60 
                              ? `${parseFloat(apiData.totalMobileUseInAcceptedDuration).toFixed(2)} Mins` 
                              : `${(parseFloat(apiData.totalMobileUseInAcceptedDuration) / 60).toFixed(2)} Hours` 
                          }
                        </div>
                      </div>
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        fontSize: "10px",
                        verticalAlign: "middle",
                        fontSize: "11px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={CountImage}
                            alt="icons2"
                            style={{ height: "25px", marginLeft: "-4px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginLeft: "5px",
                            fontSize: "11px",
                          }}
                        >
                          {apiData.totalHighRiskCount} {"Count"}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : dataLength === 0 ? (
                <tr>
                  {" "}
                  <td colSpan="11">
                    <div
                      style={{
                        color: "red",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100px",
                        width: "100%",
                        fontSize:"13px"
                      }}
                    >
                      {displayText.DATA_NOT_AVAILABLE}
                    </div>
                  </td>
                </tr>
              ) : dataLength === null ? (
                <tr>
                  {" "}
                  <td colSpan="11">
                    <div
                      style={{
                        color: "green",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100px",
                        width: "100%",
                      }}
                    >
                      {displayText.FETCHING_DATA}
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </Table>
          {/* 
                      {dataLength === 0 && (
                        <p
                          className="data_not_available"
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      )} */}
        </div>       
      </Row>

      <Row >
        <Col>
        {dataLength > 0 ? ( <div className="d-flex justify-content-between mb-3 mt-3 me-1">
          <div>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              </div>

              <div>
              
               
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Page
                    </p>
                    <div className="pagination ">
                      <a>{pageNumber}</a>
                    </div>
                    <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      of
                    </p>
                    <div className="pagination ">
                      <a>{totalPageCount}</a>
                    </div>
                    
                  </div>
              
                  
               
              
              </div> 

              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      Records Per Page
                    </p>
              <Select
            size="small"
            id="items-per-page"
            value={itemPerPage}
            onChange={handleItemPerPageChange}
            sx={{
              marginLeft: "10px",
              marginRight: "20px",
              fontSize: "12px",
              fontWeight: 200,
              color: "#000",
              border: "0px solid #606060",
              width: "76px",
              height: "41px",
              borderRadius: "5px",
              // border: "1px solid rgba(255, 189, 128, 1)",
              boxShadow: "none",
              backgroundColor:"#74788D1A",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
            }}
          >
            <MenuItem value={5} sx={{fontSize:"12px"}}>5</MenuItem>
            <MenuItem value={10} sx={{fontSize:"12px"}}>10</MenuItem>
            <MenuItem value={25} sx={{fontSize:"12px"}}>25</MenuItem>
            <MenuItem value={50} sx={{fontSize:"12px"}}>50</MenuItem>            
            <MenuItem value={100} sx={{fontSize:"12px"}}>100</MenuItem>
            <MenuItem value={250} sx={{fontSize:"12px"}}>250</MenuItem>
            <MenuItem value={500} sx={{fontSize:"12px"}}>500</MenuItem>
            <MenuItem value={1000} sx={{fontSize:"12px"}}>1000</MenuItem>
          </Select>
              </div>

               <div
               style={{ display: "flex", justifyContent: "space-between" }}
             >
               <p
                 style={{
                   marginTop: "6px",
                   marginLeft: "5px",
                   marginRight: "5px",
                 }}
               >
                 Available Records
               </p>
               <div className="pagination ">
                 <a>{totalUserData}</a>
               </div>

               
               
             </div>

              <div>
              <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      visibility: nextShow ? "visible" : "hidden",
                      float: "hidden",
                    }}
                  >
                    Next
                  </button>
              </div>
          </div>) : ""}
        </Col>
      </Row>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import Loader from "../../components/Common/loader";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import { FaAngleDown } from "react-icons/fa";
import Report from "../../controller/Report/Report";
import { Tooltip } from "@mui/material";
import filterClockIconDe from "../../assets/images/common/filterIconClockDeactive.svg"
import filterClockIcon from "../../assets/images/common/filterClockIcon.svg"
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserNameIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";
import andIconNew from "../../assets/images/common/androidTableIcon.svg";
import noDeviceIcon from "../../assets/images/common/noDeviceIcon.svg";
import iosIconNew from "../../assets/images/common/iosTableIcon.svg";
import FTwheelerIcon from "../../assets/images/common/twoFourWheelerIcon.svg";
import ActType from "../../assets/images/superAdminIcons/actType.svg";
import ActiveDate from "../../assets/images/superAdminIcons/ActiveDate.svg";
import MDTotal from "../../assets/images/superAdminIcons/MDTotal.svg";
import MDVersion from "../../assets/images/superAdminIcons/MDVersion.svg";
import MDModel from "../../assets/images/superAdminIcons/MDModel.svg";
import MDRegDate from "../../assets/images/superAdminIcons/MDRegDate.svg";
import LDDeviceId from "../../assets/images/superAdminIcons/deviceId.svg";
import MSAppVersion from "../../assets/images/superAdminIcons/MSAppVersion.svg";
import MSLastSession from "../../assets/images/superAdminIcons/MSLastSession.svg";
import MSLastActivity from "../../assets/images/superAdminIcons/MSLastActivity.svg";
import locationImageCrash from "../../assets/images/common/crashLocationImage.svg";
import AddressImageCrash from "../../assets/images/common/addressIconCrash.svg";
import ZipcodeImageCrash from "../../assets/images/common/zipcodeCrashIcon.svg";
import LatImage from "../../assets/images/common/latCrashImage.svg";
import LongImage from "../../assets/images/common/LongImageCrash.svg";
import SuperAdminDashboardCtrlr from "../../controller/SuperAdminCtrlr";
import UtilDateTime from "../../util/UtilDateTime";


export default function AppException() {
  const [count, setCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [totalPageCount , setTotalPageCount] = useState(0);
  const [loginType, setLoginType] = useState("");
  const [dataLength, setDataLength] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [apiDataUser, setApiDataUser] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [exceptionFilter, setExceptionFilter] = useState("POST_LOGIN");
  const [exceptionFilter2, setExceptionFilter2] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectUsername, setSelectUsername] = useState("")
  const [deviceFilter, setDeviceFilter] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [nextShow, setNextShow] = useState(false);
  const [prevShow, setPrevShow] = useState(false);
  const [summaryDataList, setSummaryDataList] = useState([]);
  const location = useLocation();
  const history = useNavigate(); 
  const [filterValueExp2, setFilterValueExp2] = useState("");

  const [locationParams, setLocationParams] = useState({     
      userId: "",      
    });

  const [filters, setFilters] = useState({
    durationFilter: "ALL",
    startDateTime: "",
    endDateTime: "",
  });

  const [subScriptionDataList, setSubcriptionDataList] = useState({
    subscriptionName: "",
    totalCount: 100,
    preLoginCrash: 20,
    preLoginOthers: 20,
    postLoginCrash: 20,
    postLoginOthers: 20,
    postLoginPermission: 10,
    postLoginPeriodical: 10,
    startDate: "",
    endDate: "",
    billing: "1 Year",
    orderCount: 0,
  });

  const [searchText, setSearchText] = useState("");
  const inputRef = useRef();
  const [selectedValue, setSelectedValue] = useState("ALL_USER_LIST");

  const pageSize = 5;

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

 useEffect(() => {
     if (location.state != null) {
       const { userId } = location.state;
       setLocationParams((prev) => {
         return {                   
           userId: userId,           
         };
       });
     }
    //  console.log(location.state,"location.state")
   }, []);


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIndexStart(0);
      setPageNumber((current) => current - current + 1);
      event.preventDefault();
      inputRef.current.click();
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getOverAllSummary(0,"");
    setSummaryDataList([]);
    setPageNumber((current) => current - current + 1);
  };

  const handleUserClick = (type) => {
   
    setIndexStart(0)
    setPageNumber(1);
    const selectedMapping = {
      ALL:"ALL_USER_LIST",
      ACTIVE: "ACTIVE_USER_LIST",
      INACTIVE: "INACTIVE_USER_LIST",
      UNREGISTERED: "UNREGISTERED_USER_LIST",
      DEACTIVATED: "DEACTIVATED_USER_LIST",
      DELETED:"DELETED_USER_LIST"
    };
    setSelectedValue(selectedMapping[type]);    
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
 
      if (count != 0) {
        setFilters({
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          durationFilter: filter,
        });
      }
    }, [filter, startDateTime, endDateTime]);

    const handleChange = (filterType) => {
      setIndexStart(0)
      setPageNumber(1);
      setCount(0);
      setStartDateTime("");
      setEndDateTime("");
      setFilters({
        startDateTime: "",
        endDateTime: "",
        durationFilter: filterType,
      });
    };   

  const getOverAllSummary = async (indexStartOfSet,searchClear) => {   
   
    setShowLoader(true);

    const transportType = selectedValue;
    var searchData = (searchClear == "CLEAR") ? "" : searchText;
    var filtersData = (filters.durationFilter == "ALL") ? "" : filters.durationFilter;
    var result = locationParams.userId ?
    await SuperAdminDashboardCtrlr.getSuperAdminV1SingleExceptionUserList(
      indexStartOfSet,
      locationParams.userId,    
      searchData,      
      selectedValue,
      deviceFilter,
      exceptionFilter2,
      filters.startDateTime ? filters.startDateTime : "",
      filters.endDateTime ? filters.endDateTime : "",
      filtersData,
    ) :
    await SuperAdminDashboardCtrlr.getSuperAdminV1ExceptionUserList(
      indexStartOfSet,    
      searchData,      
      selectedValue,
      deviceFilter,
      exceptionFilter2,
      filters.startDateTime ? filters.startDateTime : "",
      filters.endDateTime ? filters.endDateTime : "",
      filtersData,
    )
    // console.log(result,"resultresult")
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;

    if (status == "SUCCESS") {

      setShowLoader(false);

      var resultJSONData = resultJSON.data;
      
     
      if (resultJSONData.length > 0) {
        setDataLength(resultJSONData.length);
     

        var summaryData = [];

        resultJSONData.map((value, i) => {

          var totalCount              = ValueFormat.parseAsInt(value.totalCount);

          setTotalPageCount(Math.ceil(totalCount / pageSize));
          setNextShow(totalCount - indexStartOfSet > pageSize);

          var profileDetail           = ValueFormat.parseAsObject(value.profileDetail);
          var registrationDetail      = ValueFormat.parseAsObject(value.registrationDetail);
          var licenseDetail           = ValueFormat.parseAsObject(value.licenseDetail);
          var rideDetail              = ValueFormat.parseAsObject(value.rideDetail);
          var lastRideDetail          = ValueFormat.parseAsObject(value.lastRideDetail);
          var mobileDetail            = ValueFormat.parseAsObject(value.mobileDetail);
          var sessionMobileDetail     = ValueFormat.parseAsObject(value.appDetail);
          var locationDetail     = ValueFormat.parseAsObject(value.locationDetail);
          var sessionWebDetail        = ValueFormat.parseAsObject(value.sessionWebDetail);
          var lastNotificationDetail  = ValueFormat.parseAsObject(value.lastNotificationDetail);
          var exceptionAndPermissionDetail  = ValueFormat.parseAsObject(value.exceptionAndPermissionDetail);
          var mobileException         = ValueFormat.parseAsArray(value.exceptionList )
          
          
          // ProfileDetail
          var firstName           = ValueFormat.parseAsString(profileDetail.firstName) || "-";
          var username            = ValueFormat.parseAsString(profileDetail.username) || "-";
          var deviceType          = ValueFormat.parseAsString(profileDetail.deviceType) || "-";
          var vehicleType         = ValueFormat.parseAsString(profileDetail.vehicleType) || "-";
          var usernameType        = ValueFormat.parseAsString(profileDetail.usernameType) || "-";
          var createdAtToTimeZone = ValueFormat.parseAsString(profileDetail.createdAtToTimeZone) || "-";
          var userStatus          = ValueFormat.parseAsString(profileDetail.userStatus) || "-";
          var overallRideCount   = ValueFormat.parseAsInt(profileDetail.overallRideCount) || 0;
          var userActive          = ValueFormat.parseAsString(profileDetail.userActive) || "-";

          // LocationDetail
          var address             = ValueFormat.parseAsString(locationDetail.address) || "-";
          var location            = ValueFormat.parseAsString(locationDetail.location) || "-";
          var zipCode             = ValueFormat.parseAsString(locationDetail.zipCode) || "-";
          var latitude            = ValueFormat.parseAsString(locationDetail.latitude) || "-";
          var longitude           = ValueFormat.parseAsString(locationDetail.longitude) || "-";
          
          // Registration Detail
          var firstNameUpdatedStatus   = ValueFormat.parseAsString(registrationDetail.firstNameUpdatedStatus) || "-";
          var passwordUpdatedStatus    = ValueFormat.parseAsString(registrationDetail.passwordUpdatedStatus) || "-";
          var usernameVerifiedStatus   = ValueFormat.parseAsString(registrationDetail.usernameVerifiedStatus) || "-";
          
          // License Detail
          var approvedStatus   = ValueFormat.parseAsString(licenseDetail.approvedStatus) || "-";
          var validityStatus   = ValueFormat.parseAsString(licenseDetail.validityStatus) || "-";
          var downloadStatus   = ValueFormat.parseAsString(licenseDetail.downloadStatus) || "-";
          var errorStatus      = ValueFormat.parseAsString(licenseDetail.errorStatus) || "-";
          var downloadCount    = ValueFormat.parseAsInt(licenseDetail.downloadCount) || 0;
          var licenseOrderId      = ValueFormat.parseAsString(licenseDetail.licenseOrderId) || "-";
          var deviceUniqueId      = ValueFormat.parseAsString(licenseDetail.deviceUniqueId) || "-";
          
          // Ride Detail
          var invalidRideCount   = ValueFormat.parseAsInt(rideDetail.invalidRideCount) || 0;
          var driverRideCount    = ValueFormat.parseAsInt(rideDetail.driverRideCount) || 0;
          var passengerRideCount = ValueFormat.parseAsInt(rideDetail.passengerRideCount) || 0;
          var abnormalRideCount  = ValueFormat.parseAsInt(rideDetail.abnormalRideCount) || 0;
          var deletedRideCount   = ValueFormat.parseAsInt(rideDetail.deletedRideCount) || 0;
         

          // Last Ride Detail 
          var name                = ValueFormat.parseAsString(lastRideDetail.name) || "-";
          var timeZone            = ValueFormat.parseAsString(lastRideDetail.createdAtToTimeZone) || "-";
          var totalDistanceInKm   = ValueFormat.parseAsInt(lastRideDetail.totalDistanceInKm) || "-";
          var totalDurationInMin  = ValueFormat.parseAsInt(lastRideDetail.totalDurationInMin) || "-";
          var category            = ValueFormat.parseAsString(lastRideDetail.category) || "-";

          // Mobile Device Details
          var totalDeviceCount   = ValueFormat.parseAsInt(mobileDetail.totalDeviceCount) || 0;
          var activeDeviceCount  = ValueFormat.parseAsInt(mobileDetail.activeDeviceCount) || 0;
          var lastUniqueId  = ValueFormat.parseAsString(mobileDetail.deviceUniqueId) || "-";
          var lastVersionNumber      = ValueFormat.parseAsString(mobileDetail.deviceVersionNumber) || 0;
          var lastModelName          = ValueFormat.parseAsString(mobileDetail.deviceModelName) || "-";
          var batteryLevel          = ValueFormat.parseAsString(mobileDetail.batteryLevel) || "-";
          var DeviceLastCreatedAtToTimeZone = ValueFormat.parseAsString(mobileDetail.lastCreatedAtToTimeZone) || "-";

          // Mobile Session Details
          var totalSessionCount   = ValueFormat.parseAsInt(sessionMobileDetail.totalSessionCount) || 0;
          var activeSessionCount  = ValueFormat.parseAsInt(sessionMobileDetail.activeSessionCount) || 0;
          var sessionLastAppVersion      = ValueFormat.parseAsString(sessionMobileDetail.appVersion) || 0;
          var sessionMobileDeviceType          = ValueFormat.parseAsString(sessionMobileDetail.deviceType) || "-";
          var excptionType = ValueFormat.parseAsString(sessionMobileDetail.type) || "-";
          var excptionCategory = ValueFormat.parseAsString(sessionMobileDetail.category) || "-";
          var sessionLastActivityAtToTimeZone = ValueFormat.parseAsString(sessionMobileDetail.createdAtToTimeZone) || "-";
          var sessionLastDeviceId = ValueFormat.parseAsString(sessionMobileDetail.deviceOrderId) || "-";

          // Web Session Details
          var webTotalSessionCount   = ValueFormat.parseAsInt(sessionWebDetail.totalSessionCount) || 0;
          var webActiveSessionCount  = ValueFormat.parseAsInt(sessionWebDetail.activeSessionCount) || 0;
          var lastTokenStatus      = ValueFormat.parseAsString(sessionWebDetail.lastTokenStatus) || "-";
          var lastRemoteAddress          = ValueFormat.parseAsString(sessionWebDetail.lastRemoteAddress) || "-";
          var webLastSessionAtToTimeZone = ValueFormat.parseAsString(sessionWebDetail.lastSessionAtToTimeZone) || "-";
          var lastActivityAtToTimeZone = ValueFormat.parseAsString(sessionWebDetail.lastActivityAtToTimeZone) || "-";
          
          // Last Push Notification Details

          var lastAppUpdateAtToTimeZone      = ValueFormat.parseAsString(lastNotificationDetail.lastAppUpdateAtToTimeZone) || "-";
          var lastMapUpdateAtToTimeZone      = ValueFormat.parseAsString(lastNotificationDetail.lastMapUpdateAtToTimeZone) || "-";
          var lastNoActivityAtToTimeZone      = ValueFormat.parseAsString(lastNotificationDetail.lastNoActivityAtToTimeZone) || "-";
          
          // exceptionAndPermissionDetail

          var preLoginCrashExceptionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.preLoginCrashExceptionCount) || 0;
          var preLoginOtherExceptionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.preLoginOtherExceptionCount) || 0;
          var postLoginCrashExceptionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.postLoginCrashExceptionCount) || 0;
          var postLoginOtherExceptionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.postLoginOtherExceptionCount) || 0;

          var postLoginLoginPermissionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.postLoginLoginPermissionCount) || 0;
          var postLoginPeriodicalPermissionCount   = ValueFormat.parseAsInt(exceptionAndPermissionDetail.postLoginPeriodicalPermissionCount) || 0;

          let statusList = "";

          if (userStatus == "INITIATED" || userStatus == "REGISTERED") {
            statusList = "Unregistered";
          } else if (userStatus == "ACTIVE" && overallRideCount <= 0) {
            statusList = "Inactive";
          } else if (userStatus == "ACTIVE" && overallRideCount > 0) {
            statusList = "Active";
          } else if (userStatus == "DEACTIVATED") {
            statusList = "Deactivated";
          } else {
            statusList = "-";
          }

          if (userActive == "INACTIVE") {
            statusList = "Deleted";
          }
         
          
          
          // console.log(typeof(lastRideDetail.totalDistanceInKm),"downloadCount")

          var resultObject = {

            // ProfileDetail
            firstName:firstName,
            username:username,
            deviceType:deviceType,
            vehicleType:vehicleType,
            usernameType:usernameType,
            createdAtToTimeZone:createdAtToTimeZone,
            userStatus:statusList,

            // LocationDetail
            address:address,
            location:location,
            zipCode:zipCode,
            latitude:latitude,
            longitude:longitude,

            // Registration Detail
            firstNameUpdatedStatus:firstNameUpdatedStatus,
            passwordUpdatedStatus:passwordUpdatedStatus,
            usernameVerifiedStatus:usernameVerifiedStatus,

            // License Detail
            approvedStatus:approvedStatus,
            validityStatus:validityStatus,
            downloadStatus:downloadStatus,
            errorStatus:errorStatus,
            downloadCount:downloadCount,
            licenseOrderId:licenseOrderId,
            deviceUniqueId:deviceUniqueId,

            // Ride Detail
            invalidRideCount:invalidRideCount,
            driverRideCount:driverRideCount,
            passengerRideCount:passengerRideCount,
            abnormalRideCount:abnormalRideCount,
            deletedRideCount:deletedRideCount,

            // Last Ride Detail
            name:name,
            timeZone:timeZone,
            totalDistanceInKm:totalDistanceInKm,
            totalDurationInMin:totalDurationInMin,
            category:category,

            // Mobile Device Details
            totalDeviceCount:totalDeviceCount,
            activeDeviceCount:activeDeviceCount,
            lastUniqueId:lastUniqueId,
            lastVersionNumber:lastVersionNumber,
            lastModelName:lastModelName,
            batteryLevel:batteryLevel,
            DeviceLastCreatedAtToTimeZone:DeviceLastCreatedAtToTimeZone,

             // Mobile Session Details
             totalSessionCount:totalSessionCount,
             activeSessionCount:activeSessionCount,
             sessionLastAppVersion:sessionLastAppVersion,
             sessionMobileDeviceType:sessionMobileDeviceType,
             excptionType:excptionType,
             excptionCategory:excptionCategory,
             sessionLastActivityAtToTimeZone:sessionLastActivityAtToTimeZone,
             sessionLastDeviceId:sessionLastDeviceId,

             // Web Session Details
             webTotalSessionCount:webTotalSessionCount,
             webActiveSessionCount:webActiveSessionCount,
             lastTokenStatus:lastTokenStatus,
             lastRemoteAddress:lastRemoteAddress,
             webLastSessionAtToTimeZone:webLastSessionAtToTimeZone,
             lastActivityAtToTimeZone:lastActivityAtToTimeZone,

             //Last Push Notification Details
             lastAppUpdateAtToTimeZone:lastAppUpdateAtToTimeZone,
             lastMapUpdateAtToTimeZone:lastMapUpdateAtToTimeZone,
             lastNoActivityAtToTimeZone:lastNoActivityAtToTimeZone,

             // exceptionAndPermissionDetail
             preLoginCrashExceptionCount:preLoginCrashExceptionCount,
             preLoginOtherExceptionCount:preLoginOtherExceptionCount,
             postLoginCrashExceptionCount:postLoginCrashExceptionCount,
             postLoginOtherExceptionCount:postLoginOtherExceptionCount,

             postLoginLoginPermissionCount:postLoginLoginPermissionCount,
             postLoginPeriodicalPermissionCount:postLoginPeriodicalPermissionCount,
            
             mobileException:mobileException
           
          };
          summaryData.push(resultObject);
         
        });
      } else {
        setDataLength(0)
        
      }    
      // console.log(summaryData, "hjshdjhsgdfjhsdgf");
      
      setSummaryDataList(summaryData);     
      
    } else if (status == "FAILED") {

      setShowLoader(false);
      

      var code = resultJSON.error.code;

      if (code == "E1206") {
        setShowLoader(false);
      
      }
    }

    setShowLoader(false);

    if (indexStartOfSet == 0) {
      setPrevShow(false);
    } else if (indexStartOfSet > 0) {
      setPrevShow(true);
    }
  };

  useEffect(() => {    
    // if() 
   
    getOverAllSummary(indexStart,"");
    setSummaryDataList([]);
  }, [locationParams.userId,indexStart,selectedValue,deviceFilter,filters.durationFilter, filters.startDateTime, filters.startDateTime, exceptionFilter2,]);
  

  // const [count, setCount] = useState(0);
  // useEffect(() => {
  //     setPageNumber(1);
  //     if (indexStart == 0) setCount((prev) => prev + 1);
  //     else setIndexStart(0);
  // }, [filter,exceptionFilter,deviceFilter,startDateTime,endDateTime,selectedOption]);



  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  function capitalizeLetter  (category){
    return category
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  }
  
  const clearSearchInput = () => {
       
    setSearchText("");      
    getOverAllSummary(0,"CLEAR");     
    setPageNumber(1); 
      
  }


  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Battery Level" breadcrumbItem="Battery Level" />
        {/* {loginType == "SUPER_ADMIN" ? (
          <span
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Welcome, You are viewing{" "}
            <span
              style={{
                fontSize: "13px",
                fontWeight: 800,
              }}
            >
              {userFirstName}'s
            </span>{" "}
            App Exception
          </span>
        ) : ( */}
          <Row className="my-2 py-2 d-flex justify-content-start align-items-center" >
            <Col xs={3} className="d-flex justify-content-start align-items-center text-black" style={{ height:"0px"}}>
              <h5 className="mb-0" style={{textWrap:"nowrap",}}>App Exceptions</h5>
            </Col>
            <Col xs={9} className="d-flex justify-content-end align-items-center text-black" style={{gap:10,}}>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", gap:10}}>
              <div className="pb-0 mt-0 mb-0 d-flex justify-content-end ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center", }}>                         
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "POST_LOGIN"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => setExceptionFilter("POST_LOGIN")}
                  data-tooltip-id="postLo"
                >
                  Post Login
                  <ReactTooltip
                    id="postLo"
                    place="top"
                    content="Post Login"
                    style={{ background: "black" }}
                  />
                </button>
              </div>

              <div className="pb-0 mt-0 mb-0 d-flex justify-content-end" style={{ textAlign: "center", }}>
                <div className="mt-0 mb-0 mr-0  ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center" }}>
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == ""
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() =>{
                      setIndexStart(0)
                      setPageNumber(1);
                      setExceptionFilter2("")
                    }}
                  
                  >
                    All
                    
                  </button>           
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == "CRASH_EXCEPTION"
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => {
                      setIndexStart(0)
                      setPageNumber(1);
                      setExceptionFilter2("CRASH_EXCEPTION")
                    }}
                   
                  >
                    Crash Exception
                   
                  </button>              
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == "OTHER_EXCEPTION"
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => {
                      setIndexStart(0)
                      setPageNumber(1);
                      setExceptionFilter2("OTHER_EXCEPTION")
                    }}
                    
                  >
                    Other Exception
                   
                  </button>    
                </div>
              </div>
            </div>  

            <div className="pb-0 d-flex justify-content-end">
              <div className="ms-auto pe-0 ">
                <div style={{ float: "right",  }}>
                <Tooltip title="All">
                  <button
                      type="button"                            
                      style={{ fontWeight: 700 }}
                      className={
                        filters.durationFilter == "ALL" &&
                        !modalIsOpen
                          ? "btn btnColorStylesNew btn-sm me-1"
                          : "btn btn-soft-secondary btn-sm me-1"
                      }
                      onClick={() => handleChange("ALL")}
                    >
                      All                           
                    </button>
                    </Tooltip>
                    <Tooltip title="Current Day">
                    <button
                      type="button"                            
                      style={{ fontWeight: 700 }}
                      className={
                        filters.durationFilter == "CURRENT_DAY" &&
                        !modalIsOpen
                          ? "btn btnColorStylesNew btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => handleChange("CURRENT_DAY")}
                     
                    >
                      CD                           
                    </button>
                    </Tooltip>
                    {" "}
                    <Tooltip title="Current Week">
                    <button
                      type="button"                            
                      style={{ fontWeight: 700 }}
                      className={
                        filters.durationFilter == "CURRENT_WEEK" &&
                        !modalIsOpen
                          ? "btn btnColorStylesNew btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => handleChange("CURRENT_WEEK")}
                      
                    >
                      CW                           
                    </button>
                    </Tooltip>
                    {" "}
                    <Tooltip title="Current Month">
                    <button
                      type="button"                           
                      style={{ fontWeight: 700 }}
                      className={
                        filters.durationFilter == "CURRENT_MONTH" &&
                        !modalIsOpen
                          ? "btn btnColorStylesNew btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => handleChange("CURRENT_MONTH")}
                      
                    >
                      CM
                      
                    </button>
                    </Tooltip>{" "}
                    <Tooltip title="Current Year">
                    <button
                      type="button"                            
                      style={{ fontWeight: 700 }}
                      className={
                        filters.durationFilter == "CURRENT_YEAR" &&
                        !modalIsOpen
                          ? "btn btnColorStylesNew btn-sm"
                          : "btn btn-soft-secondary btn-sm"
                      }
                      onClick={() => handleChange("CURRENT_YEAR")}
                     
                    >
                      CY                            
                    </button>
                    </Tooltip>{" "}
                    <Tooltip title="Custom Date Selection">
                    <button
                      type="button"
                      className={
                        filters.durationFilter == "CUSTOM_DURATION" ||
                        modalIsOpen
                          ? "btn btnColorStylesNew btn-sm"
                          : "btn btnColorStylesSecondaryNew btn-sm"
                      }
                      onClick={() => {
                        setPageNumber(1);
                        setIndexStart(0)
                        setIsOpen(true);
                        setCount(1);
                        // setFilters({
                        //   ...filters,
                        //   durationFilter: "CUSTOM_DURATION",
                        // });
                      }}
                    
                    >
                      {filter == "CUSTOM_DURATION" || modalIsOpen ? (
                        <img
                          src={filterClockIcon}
                          alt="clock"
                          style={{
                            height: "18px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        />
                      ) : (
                        <img
                          src={filterClockIconDe}
                          alt="clock"
                          style={{
                            height: "18px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        />
                      )}                           
                    </button>{" "}
                    </Tooltip>
                </div>
              </div>
              {/* <ExportScreenPdf fileName={userFirstName + "'s" + " Dashboard"} /> */}
            </div>
            </Col>
          </Row>
          {/* )}  */}

              <Row style={{marginTop:"-20px", }}>
                <Col xl={12} md={12} sm={12}>
                  <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",}}>
                      <div className="my-3" style={{ display: "flex", justifyContent:"space-between", flexDirection: "row",height: "40px", marginTop: "-50px", }}>
                      <div 
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width:"250px",
                        position: "relative", // Add relative positioning to the wrapper
                      }}
                      >
              <input 
              
              style={{ border: " 1px solid #EEEBEB", width: "100%", height: "80%", }}
                value={searchText}
                onKeyPress={handleKeyPress}
                onChange={(e) => { setSearchText(e.target.value); }}
                placeholder="Search"
              />
              {searchText &&
  <span
    style={{
      position: "absolute",
      right: "10px", 
      top: "50%",
      transform: "translateY(-50%)", 
      cursor: "pointer",
      color: "#888", 
    }}
    onClick={clearSearchInput} 
  >
    X
  </span>}
            </div>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }}>
                          <button ref={inputRef}
                            style={{ marginLeft: "5px", height: "80%", display: "flex", justifyContent: "center", flexDirection: "column", }}
                            className="btn btnColorStylesNew me-3"
                            type="button"
                            onClick={handleSearch}
                          >
                            <i className="bx bx-search-alt align-middle"></i>
                          </button>
                        </div>
                      </div>
                      <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", gap:10}}>
                        <div className="mt-0 mb-0 mr-0 ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center",  }}>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == ""
                                ? "btn btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => {
                              setIndexStart(0)
                              setPageNumber(1);
                              setDeviceFilter("")
                            }}
                           
                          >
                            All
                            
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "ANDROID"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => {
                              setIndexStart(0)
                              setPageNumber(1);
                              setDeviceFilter("ANDROID")
                            }}
                           
                          >
                            Android
                            
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "IOS"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => {
                              setIndexStart(0)
                              setPageNumber(1);
                              setDeviceFilter("IOS")                              
                            }}
                           
                          >
                            iOS
                           
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "ANDRIOD_SDK"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => {
                              setIndexStart(0)
                              setPageNumber(1);
                              setDeviceFilter("ANDRIOD_SDK")
                            }}
                           
                          >
                            Android SDK
                           
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "IOS_SDK"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => {
                              setIndexStart(0)
                              setPageNumber(1);
                              setDeviceFilter("IOS_SDK")
                            }}
                            
                          >
                            iOS SDK
                           
                          </button>
                        </div>
                        <div className="my-0" style={{ height: "28px", display: "flex", alignItems:"center", marginTop: "0x",  }}>
                            <div className=" mr-0 ms-auto pe-0  border-secondary" >
                              <div style={{ float: "right" }}>
                              {["ALL","ACTIVE", "INACTIVE", "UNREGISTERED", "DEACTIVATED", "DELETED"].map((type) => (
                                <button
                                  key={type}
                                  type="button"
                                  style={{
                                    fontWeight: 700,
                                    // backgroundColor: selectedValue === type ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm", // Highlight selected
                                    // color: selectedValue === type ? "#fff" : "#000",
                                    marginRight:"5px"
                                  }}
                                  className={`btn btn-sm ${
                                    selectedValue === `${type}_USER_LIST` ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm"
                                  }`}
                                  id={type}
                                  onClick={() => handleUserClick(type)} // Pass type to handler
                                >
                                  {type.charAt(0) + type.slice(1).toLowerCase()} {/* Format button label */}
                                </button>
                              ))}
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div className="mt-0" style={{ overflowX: "auto",border:"1px solid #DFDEDE"}} >
                    <Table className="table table-bordered mb-0" style={{ borderCollapse: "collapse" }}>
                      <thead className="cardHeader">
                        <tr>
                          <th className="cardHeader" style={{ fontSize: "13px", fontWeight: 600, padding: "14px", position: "sticky", left: -2, zIndex: 2, background:"white" }} >
                            User Details                            
                          </th>
                          <th className="cardHeader" style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center", position: "sticky", left: 200, zIndex: 2, background:"white", }} >
                            Device Details
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                            App Details
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                            Location Details
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                            Exception Details
                          </th>                         
                        </tr>
                      </thead>

                      <tbody>
                        {dataLength > 0 ? (
                          (summaryDataList || []).map((apiData, index) => (
                            <tr  key={index} >
                              <td style={{ fontSize: "13px", verticalAlign: "middle", position: "sticky", left: -2, zIndex: 2, backgroundColor: "white", }} >
                                <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={UserNameIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                  </div>                          
                                  <div className="second-div-flex" >     
                                      <span className="span-tag-name" style={{whiteSpace:"nowrap"}}>
                                        {apiData.firstName} 
                                        </span>
                                  </div>
                                  <div className="div-spacing" > | </div>
                                  <div>
                                    <p className="p-tag-username"> 
                                      {apiData.username} 
                                      </p>
                                  </div>
                                  <div>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    > 
                                    {apiData.userStatus}
                                    </button>
                                  </div>
                                </div>

                                <div  className="div-flex-margin" >    
                                    <div style={{ display: "flex", marginLeft: "0px", }}>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                        <img src={FTwheelerIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                      </div>
                                      <p className="p-tag-text-right" > Vehicle: </p>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                        {apiData.vehicleType === "FOUR_WHEELER" 
                                          ? "4W" 
                                          : apiData.vehicleType === "TWO_WHEELER" 
                                          ? "2W" 
                                          : "-"}
                                      </div>
                                    </div>
                                </div>

                                <div>
                                  <div className="div-flex-reg-act">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ActType} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Act Type : </p>
                                    <p className="p-tag-text-left" > {capitalizeLetter(apiData.usernameType)} </p>
                                  </div>

                                  <div className="div-flex-reg-act">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ActiveDate} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Act Date : </p>
                                    <p className="p-tag-text-left" > {UtilDateTime.formatDateInt(apiData.createdAtToTimeZone)} | {formatTime(apiData.createdAtToTimeZone)}</p>
                                  </div>
                                </div>                    
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                <div style={{ display: "flex", marginLeft: "3px", }}>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                        {apiData.deviceType == "ANDROID" ? (
                                          <img src={andIconNew} alt="icons2" style={{ height: "25px", marginLeft: "-8px" }} /> 
                                        ) : apiData.deviceType == "IOS" ? (
                                          <img src={iosIconNew} alt="icons2" style={{ height: "25px", marginLeft: "-8px" }} />
                                        ) : (
                                          <img src={noDeviceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-8px" }} />
                                        )}
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                        {(apiData.deviceType == "ANDROID") || (apiData.deviceType == "IOS" ) ? "Mobile" : (apiData.deviceType == "ANDROID_SDK") || (apiData.deviceType == "IOS_SDK" )  ?  "SDK" : "No Device"}
                                      </div>
                                    </div> 
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={LDDeviceId} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Device Id: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.lastUniqueId}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDVersion} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Version: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.lastVersionNumber}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDModel} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Model: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.lastModelName}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDModel} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Battery Level: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.batteryLevel}%
                                  </p>
                                </div>

                              </td>

                              <td className="td-container" >
                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={LDDeviceId} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Order Id: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.sessionLastDeviceId}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSAppVersion} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right"> App Version: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.sessionLastAppVersion}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSLastSession} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Type: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiData.excptionType)}
                                   </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MSLastActivity} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Category: </p>
                                  <p className="p-tag-text-left" >
                                    {capitalizeLetter(apiData.excptionCategory)}
                                  </p>
                                </div>

                                
                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MDRegDate} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" > Date: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : UtilDateTime.formatDateInt(apiData.sessionLastActivityAtToTimeZone)} | {apiData.sessionLastActivityAtToTimeZone == "-" ? "-" : formatTime(apiData.sessionLastActivityAtToTimeZone)}
                                  </p>
                                </div>
                              </td>

                              <td className="td-container" >

                              <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={LatImage} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" style={{marginLeft:"8px"}}> Lat: </p>
                                  <p className="p-tag-text-left" > {apiData.latitude}    </p>       
                                  <div className="p-tag-text-right" > | </div>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={LongImage} alt="icons2" style={{ height: "25px", marginLeft: "5px" }} />
                                  </div>
                                  <p className="p-tag-text-right" style={{marginLeft:"8px"}}> Long: </p>
                                  <p className="p-tag-text-left" >
                                    {apiData.longitude}
                                  </p>    
                                </div>
                                
                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={ZipcodeImageCrash} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" style={{marginLeft:"8px"}} > Zip Code: </p>
                                  <p className="p-tag-text-left" > {apiData.zipCode} </p>
                                </div>

                                <div className="div-flex-reg-act" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={locationImageCrash} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  {/* <p className="p-tag-text-right" style={{marginLeft:"8px"}} > Location: </p> */}
                                  <p className="p-tag-text-left" >{apiData.location}</p>
                                </div>

                                <div className="div-flex-reg-act" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AddressImageCrash} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-left" style={{whiteSpace:"wrap",}}>{apiData.address}</p>                          
                                </div>

                              </td>

                              <td className="td-container" >
                                  
                                  <div style={{height:"200px", width:"95%", overflowY: apiData?.mobileException.length > 1 ? "scroll" : "hidden !important", overflowX:"hidden !important"}}>
                                  {apiData?.mobileException?.map((value, index) => {                                    
                                    return(

                                  <div className="div-flex-reg-act" key={index} style={{ borderBottom: index === apiData?.mobileException.length - 1 ? "0px solid #DFDEDE" : "1px solid #DFDEDE", minheight:"100px", display:"flex", justifyContent:"center", flexDirection:"column",  }}>
                                    <div className="p-2" style={{width:"auto", }}>
                                      <div className="div-flex-reg-detail">
                                        <p className="p-tag-text-right" style={{fontWeight:700,marginLeft:"5px"}} >Name: </p>
                                        <p className="p-tag-text-left" >
                                        {capitalizeLetter(value.key)}
                                        </p>
                                      </div>
                                      {Array.isArray(value.values) && value.values.map((expValue, idx) => {
                                          return (
                                            <div className="div-flex-reg-detail" key={idx} style={{overflowX:"none"}}>
                                              <p className="p-tag-text-right" style={{marginLeft:"0px", fontWeight:700}} > </p>
                                              {(idx == 0) ? (
                                                <>
                                                <p className="p-tag-text-left" style={{ whiteSpace: "wrap" }}>
                                              <span > <span style={{fontWeight:700}}>Status:</span> {capitalizeLetter(expValue)}</span>
                                              </p>
                                                </>
                                              ) : (
                                                <>
                                                <p className="p-tag-text-left" style={{ whiteSpace: "wrap" }}>
                                              <span > <span style={{fontWeight:700}}>Description-{idx}:</span> {capitalizeLetter(expValue)}</span>
                                              </p>
                                                </>
                                              )}
                                              
                                            </div>
                                          );
                                        })}

                                    </div>
                                  </div>
                                  )})}
                                  </div>
                                  
                              </td>       
                            
                            </tr>
                           ))
                        ) : dataLength === 0 ? ( 
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "red",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                  fontSize:"13px"
                                }}
                              >
                                {displayText.DATA_NOT_AVAILABLE}
                              </div>
                            </td>
                          </tr>
                        ) : dataLength === null ? (
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.FETCHING_DATA}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </Table>          
                  </div>  
                </Col>
              </Row>



        {/* <Row>
          <Col className="col-12 mt-2">
            <Card>
              <CardHeader style={{ padding: "0px" }}>
                <Row>
                  <Col
                    md={4}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        borderBottom: "1px dashed #DFDEDE",
                      }}
                    >
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Error Selection
                      </h4>
                    </div>
                    <div className="mt-2 mb-2" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          exceptionFilter == ""
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("")}
                        data-tooltip-id="alle"
                      >
                        All
                        <ReactTooltip
                          id="alle"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "PRE_INSTALLATION"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("PRE_INSTALLATION")}
                        data-tooltip-id="preIn"
                      >
                        Pre Install
                        <ReactTooltip
                          id="preIn"
                          place="top"
                          content="Pre Installation"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "POST_INSTALLATION"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("POST_INSTALLATION")}
                        data-tooltip-id="postin"
                      >
                        Post Install
                        <ReactTooltip
                          id="postin"
                          place="top"
                          content="Post Installation"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "PRE_LOGIN"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("PRE_LOGIN")}
                        data-tooltip-id="preLo"
                      >
                        Pre Login
                        <ReactTooltip
                          id="preLo"
                          place="top"
                          content="Pre Login"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          exceptionFilter == "POST_LOGIN"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setExceptionFilter("POST_LOGIN")}
                        data-tooltip-id="postLo"
                      >
                        Post Login
                        <ReactTooltip
                          id="postLo"
                          place="top"
                          content="Post Login"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Device Selection
                      </h4>
                    </div>
                    <div  style={{ textAlign: "center",marginTop:"12px"}}>
                      <button
                        type="button"
                        className={
                          deviceFilter == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "ANDROID"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("ANDROID")}
                        data-tooltip-id="androidBl"
                      >
                        Android
                        <ReactTooltip
                          id="androidBl"
                          place="top"
                          content="Android"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "IOS"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("IOS")}
                        data-tooltip-id="iosBl"
                      >
                        iOS
                        <ReactTooltip
                          id="iosBl"
                          place="top"
                          content="iOS"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "WEB"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("WEB")}
                        data-tooltip-id="Webt"
                      >
                        Web
                        <ReactTooltip
                          id="Webt"
                          place="top"
                          content="Web"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Duration Selection
                      </h4>
                    </div>
                    <div  style={{ textAlign: "center",marginTop:"12px" }}>
                      <button
                        type="button"
                        className={
                          filter == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_DAY" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_DAY")}
                        data-tooltip-id="cd"
                      >
                        CD
                        <ReactTooltip
                          id="cd"
                          place="top"
                          content="Current Day"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_WEEK" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_WEEK")}
                        data-tooltip-id="cw"
                      >
                        CW
                        <ReactTooltip
                          id="cw"
                          place="top"
                          content="Current Week"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_MONTH" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_MONTH")}
                        data-tooltip-id="cm"
                      >
                        CM
                        <ReactTooltip
                          id="cm"
                          place="top"
                          content="Current Month"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_YEAR" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_YEAR")}
                        data-tooltip-id="cy"
                      >
                        CY
                        <ReactTooltip
                          id="cy"
                          place="top"
                          content="Current Year"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CUSTOM_DURATION" || modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        data-tooltip-id="dfdf"
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <ReactTooltip
                          id="dfdf"
                          place="top"
                          content="Custom Date Selection"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>
                  <Col
                    md={2}
                    style={{
                      paddingRight: 0, paddingLeft: 0,
                    }}
                  >
                    <div style={{ marginRight: "10px", borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        User Selection
                      </h4>
                    </div>
                    <div  style={{ textAlign: "center",marginTop:"12px"}}>
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                        size="sm"
                      >
                        <DropdownToggle
                          caret
                          color="primary"
                        >
                          {selectUsername ? selectUsername : "All Users"}
                          <FaAngleDown style={{ marginLeft: "5px" }} />
                        </DropdownToggle>
                        <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto" }}>
                          <DropdownItem onClick={() => handleSelect(" ")}>
                            All Users
                          </DropdownItem>
                          {apiDataUser.map(user => (
                            <DropdownItem key={user.userIdTemp} onClick={() => handleSelect(user.userIdTemp, user.firstName)}>
                              {user.firstName}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "0px" }}>
                <Row>
                  <Col xl={12} md={12} sm={12}>
                    <div className="table-responsive mt-3">
                      <table className="table table-bordered mb-0">
                        <thead className="cardHeader">
                          <tr>
                            <th
                              data-tooltip-id="Fn"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              First Name
                              <ReactTooltip
                                id="Fn"
                                place="top"
                                content="First Name"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="err"
                              style={{fontSize: "13px",fontWeight: 600}}
                            >
                              Error
                              <ReactTooltip
                                id="err"
                                place="top"
                                content="Error Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="deviceType"
                              style={{fontSize: "13px",fontWeight: 600}}
                            >
                              Device Type
                              <ReactTooltip
                                id="deviceType"
                                place="top"
                                content="Device Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="appversion"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              App Version
                              <ReactTooltip
                                id="appversion"
                                place="top"
                                content="App Version"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="versionNumber"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              Device Version Number
                              <ReactTooltip
                                id="versionNumber"
                                place="top"
                                content="Device Version Number"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="uniqueId"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              Device Unique Id
                              <ReactTooltip
                                id="uniqueId"
                                place="top"
                                content="Device Unique Id"
                                style={{ background: "black" }}
                              />
                            </th>
                            
                            
                            <th
                              data-tooltip-id="rAddress"
                              style={{fontSize: "13px",fontWeight: 600,}}
                            >
                              Remote
                              <ReactTooltip
                                id="rAddress"
                                place="top"
                                content="Remote Address"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="UserAgent"
                              style={{fontSize: "13px",fontWeight: 600,  }}
                            >
                              User Agent
                              <ReactTooltip
                                id="UserAgent"
                                place="top"
                                content="User Agent"
                                style={{ background: "black" }}
                              />
                            </th>
                            
                            <th
                              data-tooltip-id="batterlevel"
                              style={{fontSize: "13px",fontWeight: 600, }}
                            >
                              Battery Level
                              <ReactTooltip
                                id="batterylevel"
                                place="top"
                                content="Battery Level"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataLength > 0 ? (
                            apiData.map((apiDataTemp, i) => (
                              <tr key={i}>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.firstName}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.errorType}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.deviceType}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.appVersion}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.versionNumber}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.uniqueId}
                                  </span>
                                </td>
                                
                                
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.remote}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.userAgent}
                                  </span>
                                </td>
                                
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.batteryLevel}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : dataLength === null ? (
                            <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.NO_RECORDS_FOUND}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <div className="d-flex justify-content-between mb-3">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
                <div style={{display:"flex",justifyContent:"space-between"}}>
                <div className="pagination">
                  <a>{pageNumber}</a>
                </div>
                <p style={{marginTop:"6px",marginLeft:"5px",marginRight:"5px"}} >of</p>             
              <div className="pagination ">                  
             <a>{totalPageCount}</a>             
           </div> 
           </div>
              ) : null}
              {dataLength > 0 ? (
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility: nextShow ? "visible" : "hidden",
                  float: "hidden",
                }}
              >
                Next
              </button>
                ) : null}
            </div>
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filters.durationFilter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}


export let ValueFormat = {
    formatAsInteger(value) {
        try {
            return Intl.NumberFormat().format(value);
        } catch (err) {
            return value;
        }
    },
    capitalizeFirstLetter(value) {
        try {
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        } catch (err) {
            return "";
        }
    },
    capitalizeAllLetter(value) {
        try {
            return value.toUpperCase();
        } catch (err) {
            return "";
        }
    },
    capitalizeFirstLetterAndFormat(value) {
        var result = value;
        try {
            result = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
            result = result.replaceAll("_", " ");
        } catch (err) {
            result = "";
        }
        return result;
    },
    smallLettersFormat(value) {
        var result = value;
        try {
            result = value.toLowerCase();
            result = result.replaceAll("_", " ");
        } catch (err) {
            result = "";
        }
        return result;
    },

    sliceStringAndDot(value, length) {
        var result = value;
        var lengthTemp = length == undefined ? 5 : length;

        try {
            result = value.slice(0, lengthTemp) + "...";
        } catch (err) {
            result = "";
        }
        return result;
    },

    sliceString(value, length) {
        var result = value;
        var lengthTemp = length == undefined ? 5 : length;
        try {
            result = value.slice(0, lengthTemp);
        } catch (err) {
            result = "";
        }
        return result;
    },
    removeJsonSpecialChar(value){
        var resultData = value

        var result = JSON.parse(JSON.stringify(resultData));
        return result
    },
    removeComma(value) {
        var result = 0;
        try {
            if (value.length > 3) {
                result = parseInt(value.replace(",", ""));
            } else {
                result = parseInt(value);
            }
        } catch (err) {
            result = 0;
        }
        return result;
    },


    apiDataValidation(value){
        let dataType = typeof(value);
        try{ 
        if (dataType === "string"){
            return value != undefined && value != null && value != "" ? value : ""
        }  else if(dataType === "number"){
            return value != undefined && value != null && value > 0  ? value : 0

        }
    } catch (err){
        // console.log("DataValidation failed: " + err )
    }
    },


    
    parseAsInt(value) { 
        var result = 0;
        try {  
            result = value!= undefined && value != null && value > 0 ? value : 0 ;
        } catch (err){
            // console.log("DataValidation failed: " + err )
        }
        return result;
    },

    parseAsFloat(value, decimalPoints) { 
        var result = '0.00';
        try {  
            result = value!= undefined && value != null && value > 0 ? value : result ;
            result = parseFloat(result).toFixed(decimalPoints); 
        } catch (err){
            // console.log("DataFloatValidation failed: " + err )
        }
        return result;
    },

    parseAsFloats(value, decimalPoints) { 
        let result = '-';
        if(value > 0){
        try {  
            result = (value!= undefined && value != null) ? value : result;
            result = parseFloat(result).toFixed(decimalPoints); 
        } catch (err){
            // console.log("DataFloatValidation failed: " + err )
        }
        return result;
    } else{
        return "-";
    }
    },

    parseAsFloatss(value, decimalPoints) { 
        var result = '0.00';
        try {  
            result = value!= undefined && value != null && value > 0 ? value : result ;
            result = parseFloat(result).toFixed(decimalPoints); 
        } catch (err){
            // console.log("DataFloatValidation failed: " + err )
        }
        return parseFloat(result);
    },

    parseAsFloatAllDigit(value) { 
        var result = '0.00';
        try {  
            result = value!= undefined && value != null && value > 0 ? value : result ;
            result = parseFloat(result); 
        } catch (err){
            // console.log("DataFloatValidation failed: " + err )
        }
        return parseFloat(result);
    },

    parseAsString(value, option) { 
        var result = 0;
        try {  
            result = (value != undefined) && (value != null) && (value != "") ? value : option ;
        } catch (err){
            // console.log("DataStringValidation failed: " + err )
        }
        return result;
    },
    parseAsObject(value) { 
        var result = {};
        try {  
            result = value!= undefined && value != null && Object.keys(value).length > 0 ? value : {} ;
        } catch (err){
            // console.log("DataObjectValidation failed: " + err )
        }
        return result;
    },
    
    parseAsArray(value) { 
        var result = [];
        try {  
            result =  value!= undefined && value != null && value.length > 0 ? value : [] ;
        } catch (err){
            // console.log("DataArrayValidation failed: " + err )
        }
        return result;
    },

    roundArrayValue(value, floatValue){
        var result = [];
        try{
            value.map((apiData)=>{
                if(floatValue === undefined){
                    result.push(Math.round(apiData))
                } else {
                    result.push(parseFloat(apiData).toFixed(floatValue))
                }
            })
        } catch (err){
            // console.log("DataArrayValidation failed: " + err )
        }

        return result;
    },
    maskStringByLength(inputString, visibleCharacters = 3){
        if (inputString.length <= visibleCharacters) {
          return inputString;
        }
      
        const maskedPart = '*'.repeat(inputString.length - visibleCharacters);
        const visiblePart = inputString.substring(inputString.length - visibleCharacters);
      
        return maskedPart + visiblePart;
      },
     maskStringByPercentage(inputString){
        const visibleCharacters = Math.ceil(inputString.length / 2);
      
        if (inputString.length <= visibleCharacters) {
          return inputString;
        }
      
        const maskedPart = '*'.repeat(inputString.length - visibleCharacters);
        const visiblePart = inputString.substring(inputString.length - visibleCharacters);
      
        return maskedPart + visiblePart;
    },
     formatDecimalIfRounded(score) {
        if (score === undefined || score === null) {
            return 0;
        }
        // Check if the score ends with ".0"
        if (score % 1 === 0) {
            return parseInt(score, 10); // Return the integer part only
        }
        return score; // Return the score as is if it's not a whole number
    }
};

export default ValueFormat;
 


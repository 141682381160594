import React, { useEffect, useRef, useState } from "react";
import Loader from "../../components/Common/loader";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import { FaAngleDown } from "react-icons/fa";
import Report from "../../controller/Report/Report";
import { Tooltip } from "@mui/material";
import filterClockIconDe from "../../assets/images/common/filterIconClockDeactive.svg"
import filterClockIcon from "../../assets/images/common/filterClockIcon.svg"
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Link, useLocation } from "react-router-dom";
import UserNameIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";
import andIconNew from "../../assets/images/common/androidTableIcon.svg";
import iosIconNew from "../../assets/images/common/iosTableIcon.svg";
import FTwheelerIcon from "../../assets/images/common/twoFourWheelerIcon.svg";
import ActType from "../../assets/images/superAdminIcons/actType.svg";
import ActiveDate from "../../assets/images/superAdminIcons/ActiveDate.svg";
import MDTotal from "../../assets/images/superAdminIcons/MDTotal.svg";

import SubCategoryRD from "../../assets/images/superAdminIcons/RideDetails/subCategory.svg";
import LevelIconRD from "../../assets/images/superAdminIcons/RideDetails/level.svg";
import DeviceIconRD from "../../assets/images/superAdminIcons/RideDetails/Device.svg";
import subTypeIconRD from "../../assets/images/superAdminIcons/RideDetails/subType.svg";
import ModeIconRD from "../../assets/images/superAdminIcons/RideDetails/Mode.svg";

import ScoreIconRD from "../../assets/images/superAdminIcons/RideDetails/ScoreIconRD.svg";
import AnticipationIconRD from "../../assets/images/superAdminIcons/RideDetails/AnticipationIconRD.svg";
import SelfConfidenceRD from "../../assets/images/superAdminIcons/RideDetails/SelfConfidenceRD.svg";
import DrivingSkillIconRD from "../../assets/images/superAdminIcons/RideDetails/DrivingSkillIconRD.svg";
import TDistanceIconRD from "../../assets/images/superAdminIcons/RideDetails/TDistanceIconRD.svg";
import TDurationIconRD from "../../assets/images/superAdminIcons/RideDetails/TDurationIconRD.svg";

import TravelTimeIconRD from "../../assets/images/superAdminIcons/RideDetails/TravelTimeIconRD.svg";
import PreviousTravelTimeRD from "../../assets/images/superAdminIcons/RideDetails/PreviousTravrlTimeRD.svg";
import RideTimeIconRD from "../../assets/images/superAdminIcons/RideDetails/RideTimeIconRD.svg";
import CreatedIconRD from "../../assets/images/superAdminIcons/RideDetails/CreatedIconRD.svg";
import InsertedIconRD from "../../assets/images/superAdminIcons/RideDetails/InsertedIconRD.svg";
import ModifiedIconRD from "../../assets/images/superAdminIcons/RideDetails/ModifiedIconRD.svg";

import SpeedRiskIconRD from "../../assets/images/superAdminIcons/RideDetails/SpeedRiskIconRD.svg";
import SpeedLimitIconRD from "../../assets/images/superAdminIcons/RideDetails/SpeedLimitIconRD.svg";
import PSpeedIconRD from "../../assets/images/superAdminIcons/RideDetails/PSpeedIconRD.svg";
import DistanceIconRD from "../../assets/images/superAdminIcons/RideDetails/DistanceIconRD.svg";
import PoiIconRD from "../../assets/images/superAdminIcons/RideDetails/PoiIconRD.svg";

import CrashesIconRD from "../../assets/images/superAdminIcons/RideDetails/CrashesIconRD.svg";
import LatLngIconRD from "../../assets/images/superAdminIcons/RideDetails/LatLngIconRD.svg";
import ZipCodeIconRD from "../../assets/images/superAdminIcons/RideDetails/ZipCodeIconRD.svg";
import LocationIconRD from "../../assets/images/superAdminIcons/RideDetails/LocationIconRD.svg";
import AddressIconRD from "../../assets/images/superAdminIcons/RideDetails/AddressIconRD.svg";

import UrbanIconRD from "../../assets/images/superAdminIcons/RideDetails/UrbanIconRD.svg";
import UrbanKmsIconRD from "../../assets/images/superAdminIcons/RideDetails/UrbanKmsIconRD.svg";
import RuralIconRD from "../../assets/images/superAdminIcons/RideDetails/RuralIconRD.svg";
import RuralKmsIconRD from "../../assets/images/superAdminIcons/RideDetails/RuralKmsIconRD.svg";
import HighWayIconRD from "../../assets/images/superAdminIcons/RideDetails/HighWayIconRD.svg";
import HighWayKmsIconRD from "../../assets/images/superAdminIcons/RideDetails/HighWayKmsIconRD.svg";

import NetworkIconRD from "../../assets/images/superAdminIcons/RideDetails/NetworkIconRD.svg";
import GpsIconRD from "../../assets/images/superAdminIcons/RideDetails/GpsIconRD.svg";
import BgLocationRD from "../../assets/images/superAdminIcons/RideDetails/BgLocationRD.svg";
import DoaStatusIconRD from "../../assets/images/superAdminIcons/RideDetails/DqaStatusIconRD.svg";
import StartModeIconRD from "../../assets/images/superAdminIcons/RideDetails/StartModeIconRD.svg";

import OverSpeedMinsIconRD    from "../../assets/images/superAdminIcons/RideDetails/OverSpeedMinsIconRD.svg";
import OverSpeedKmsIconRD     from "../../assets/images/superAdminIcons/RideDetails/OverSpeedKmsIconRD.svg";
import MobileCallMinsIconRD1  from "../../assets/images/superAdminIcons/RideDetails/MobileCallMinsIconRD1.svg";
import MobileCallKmsIconRD    from "../../assets/images/superAdminIcons/RideDetails/MobileCallKmsIconRD.svg";
import MobileScreenMinsIconRD from "../../assets/images/superAdminIcons/RideDetails/MobileScreenMinsIconRD.svg";
import MobileScreenKmsIconRD  from "../../assets/images/superAdminIcons/RideDetails/MobileScreenKmsIconRD.svg";

import NegativeIconRD from "../../assets/images/superAdminIcons/RideDetails/NegativeIconRD.svg";
import ZeroIconRD     from "../../assets/images/superAdminIcons/RideDetails/ZeroIconRD.svg";
import Slot1IconRD    from "../../assets/images/superAdminIcons/RideDetails/Slot1IconRD.svg";
import Slot2IconRD    from "../../assets/images/superAdminIcons/RideDetails/Slot2IconRD.svg";
import Slot3IconRD    from "../../assets/images/superAdminIcons/RideDetails/Slot3IconRD.svg";
import Slot4IconRD    from "../../assets/images/superAdminIcons/RideDetails/Slot4IconRD.svg";

import Slot5IconRD    from "../../assets/images/superAdminIcons/RideDetails/Slot5IconRD.svg";
import Slot6IconRD    from "../../assets/images/superAdminIcons/RideDetails/Slot6IconRD.svg";
import Slot7IconRD    from "../../assets/images/superAdminIcons/RideDetails/Slot7IconRD.svg";
import Slot8IconRD    from "../../assets/images/superAdminIcons/RideDetails/Slot8IconRD.svg";
import Slot9IconRD    from "../../assets/images/superAdminIcons/RideDetails/Slot9IconRD.svg";
import Slot10IconRD   from "../../assets/images/superAdminIcons/RideDetails/Slot10IconRD.svg";

import DrivingStateIconRD from "../../assets/images/superAdminIcons/RideDetails/DrivingStateIconRD.svg";
import DrivingStyleIconRD from "../../assets/images/superAdminIcons/RideDetails/DrivingStyleIconRD.svg";
import RiskStyleIconRD    from "../../assets/images/superAdminIcons/RideDetails/RiskStyleIconRD.svg";
import BatteryLevelIconRD from "../../assets/images/superAdminIcons/RideDetails/BatteryLevelIconRD.svg";
import TrafficScoreIconRD from "../../assets/images/superAdminIcons/RideDetails/TrafficScoreIconRD.svg";
import TransportIconRD    from "../../assets/images/superAdminIcons/RideDetails/TransportIconRD.svg";

import AlertIconRD      from "../../assets/images/superAdminIcons/RideDetails/AlertIconRD.svg";
import AlertIdIconRD    from "../../assets/images/superAdminIcons/RideDetails/AlertIdIconRD.svg";
import AlertValueIconRD from "../../assets/images/superAdminIcons/RideDetails/AlertValueIconRD.svg";
import AlertTimeIconRD  from "../../assets/images/superAdminIcons/RideDetails/AlertTimeIconRD.svg";
import AlertKmsIconRD   from "../../assets/images/superAdminIcons/RideDetails/AlertKmsIconRD.svg";
import AlertNameIconRD  from "../../assets/images/superAdminIcons/RideDetails/AlertNameIconRD.svg";

import AccelerationXIconRD    from "../../assets/images/superAdminIcons/RideDetails/AccelerationXIconRD.svg";
import AccelerationYIconRD    from "../../assets/images/superAdminIcons/RideDetails/AccelerationYIconRD.svg";
import AccelerationZIconRD    from "../../assets/images/superAdminIcons/RideDetails/AccelerationZIconRD.svg";
import MagnetoMeterXIconRD    from "../../assets/images/superAdminIcons/RideDetails/MagnetoMeterXIconRD.svg";
import MagnetoMeterYIconRD    from "../../assets/images/superAdminIcons/RideDetails/MagnetoMeterYIconRD.svg";
import MagnetoMeterZIconRD    from "../../assets/images/superAdminIcons/RideDetails/MagnetoMeterZIconRD.svg";

import GyroscopeXIconRD     from "../../assets/images/superAdminIcons/RideDetails/GyroscopeXIconRD.svg";
import GyroscopeYIconRD     from "../../assets/images/superAdminIcons/RideDetails/GyroscopeYIconRD.svg";
import GyroscopeZIconRD     from "../../assets/images/superAdminIcons/RideDetails/GyroscopeZIconRD.svg";
import SenRotateIconRD      from "../../assets/images/superAdminIcons/RideDetails/SenRotateIconRD.svg";
import GPSTimeRD            from "../../assets/images/superAdminIcons/RideDetails/GPSTimeRD.svg";
import eHorizonLengthIconRD from "../../assets/images/superAdminIcons/RideDetails/eHorizonLengthIconRD.svg";

import wifiUsageIconRD     from "../../assets/images/superAdminIcons/RideDetails/wifiUsageIconRD.svg";
import MobileUsageIconRD   from "../../assets/images/superAdminIcons/RideDetails/MobileUsageIconRD.svg";
import EngineStateIconRD     from "../../assets/images/superAdminIcons/RideDetails/EngineStateIconRD.svg";
import GpsCountsIconRD      from "../../assets/images/superAdminIcons/RideDetails/GpsCountsIconRD.svg";






import SuperAdminDashboardCtrlr from "../../controller/SuperAdminCtrlr";
import noDeviceIcon from "../../assets/images/common/noDeviceIcon.svg";
import Converter from "../../components/Common/EncodeDecode";
import UtilDateTime from "../../util/UtilDateTime";

import RideNumberIcon from "../../assets/images/superAdminIcons/RideList/rideNumber.svg";
import DistanceIcon from "../../assets/images/superAdminIcons/RideList/distanceIcon.svg";
import DurationIcon from "../../assets/images/superAdminIcons/RideList/durationIcon.svg";
import DayIcon from "../../assets/images/superAdminIcons/RideList/dayIcon.svg";
import NightIcon from "../../assets/images/superAdminIcons/RideList/nightIcon.svg";
import CategoryIcon from "../../assets/images/superAdminIcons/RideList/categoryIcon.svg";
import ActivityIcon from "../../assets/images/superAdminIcons/RideList/activityIcon.svg";
import DeviceIcon from "../../assets/images/superAdminIcons/RideList/deviceIcon.svg";
import StatusIcon from "../../assets/images/superAdminIcons/RideList/statusIcon.svg";
import EventIcon from "../../assets/images/superAdminIcons/RideList/eventIcon.svg";
import InsertModeIcon from "../../assets/images/superAdminIcons/RideList/insertModeIcon.svg";
import TimeZoneIcon from "../../assets/images/superAdminIcons/RideList/timeZoneIcon.svg";
import BatteryStartIcon from "../../assets/images/superAdminIcons/RideList/batteryStartIcon.svg";
import BattryEndIcon from "../../assets/images/superAdminIcons/RideList/battryEndIcon.svg";
import FileNameIcon from "../../assets/images/superAdminIcons/RideList/fileNameIcon.svg";
import FileStatusIcon from "../../assets/images/superAdminIcons/RideList/fileStatusIcon.svg";
import SummaryStatusIcon from "../../assets/images/superAdminIcons/RideList/summaryStatusIcon.svg";
import SummaryTimeIcon from "../../assets/images/superAdminIcons/RideList/summaryTimeIcon.svg";
import ScoreIcon from "../../assets/images/superAdminIcons/RideList/scoreIcon.svg";
import GradeIcon from "../../assets/images/superAdminIcons/RideList/gradeIcon.svg";
import RiskIcon from "../../assets/images/superAdminIcons/RideList/riskIcon.svg";
import BehaviorIcon from "../../assets/images/superAdminIcons/RideList/behaviorIcon.svg";

import CppIcon from "../../assets/images/superAdminIcons/RideList/cppIcon.svg";
import AnticipationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AnticipationIcon.svg";
import SelfConfidenceIcon from "../../assets/images/superAdminIcons/RideList/selfConfidenceIcon.svg";
import DrivingSkilIcon from "../../assets/images/superAdminIcons/RideList/drivingSkilIcon.svg";
import DrivingStyleIcon from "../../assets/images/superAdminIcons/RideList/drivingStyleIcon.svg";
import DrivingStateIcon from "../../assets/images/superAdminIcons/RideList/drivingStateIcon.svg";
import MobileUsageIcon from "../../assets/images/adminDashboard/myRidesPageIcons/MobileUsageIcon.svg";
import OverspeedIcon from "../../assets/images/superAdminIcons/RideList/overspeedIcon.svg";
import AccBrakCorIcon from "../../assets/images/superAdminIcons/RideList/accBrakCorIcon.svg";
import TimingStartIcon from "../../assets/images/superAdminIcons/RideList/timingStartIcon.svg"; 
import TimingEndIcon from "../../assets/images/superAdminIcons/RideList/timingEndIcon.svg";

import CreatedIcon from "../../assets/images/superAdminIcons/RideList/createdIcon.svg";
import InsertedIcon from "../../assets/images/superAdminIcons/RideList/insertedIcon.svg";
import ModifiedIcon from "../../assets/images/superAdminIcons/RideList/modifiedIcon.svg";
import StartLatIcon from "../../assets/images/superAdminIcons/RideList/startLatIcon.svg";
import StartZipcodeIcon from "../../assets/images/superAdminIcons/RideList/startZipcodeIcon.svg";
import StartLocationIcon from "../../assets/images/superAdminIcons/RideList/startLocationIcon.svg";
import StartAddressIcon from "../../assets/images/superAdminIcons/RideList/startAddressIcon.svg";
import EndLatIcon from "../../assets/images/superAdminIcons/RideList/endLatIcon.svg";
import EndZipCodeIcon from "../../assets/images/superAdminIcons/RideList/endZipCodeIcon.svg";
import EndLocationIcon from "../../assets/images/superAdminIcons/RideList/endLocationIcon.svg";

import EndAddressIcon from "../../assets/images/superAdminIcons/RideList/endAddressIcon.svg";
import DataCountStartIcon from "../../assets/images/superAdminIcons/RideList/dataCountStartIcon.svg";
import DataCountEndIcon from "../../assets/images/superAdminIcons/RideList/dataCountEndIcon.svg";
import DataCountErrorIcon from "../../assets/images/superAdminIcons/RideList/dataCountErrorIcon.svg";
import AlertIcon from "../../assets/images/superAdminIcons/RideList/alertIcon.svg";
import StressStrainIcon from "../../assets/images/superAdminIcons/RideList/stressStrainIcon.svg";
import ManualIcon from "../../assets/images/superAdminIcons/RideList/manualIcon.svg";


export default function AppRideList() {
  const [showLoader, setShowLoader] = useState(false);
  const [summaryDataList, setSummaryDataList] = useState([]);
  const [rideData, setRideData] = useState([]);
  const [totalPageCount , setTotalPageCount] = useState(0);
  const [loginType, setLoginType] = useState("");
  const [dataLength, setDataLength] = useState(0);
  const [dataLengthRideData, setDataLengthRideData] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [apiDataUser, setApiDataUser] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [exceptionFilter, setExceptionFilter] = useState("");
  const [exceptionFilter2, setExceptionFilter2] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectUsername, setSelectUsername] = useState("")
  const [deviceFilter, setDeviceFilter] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [nextShow, setNextShow] = useState(false);
  const [prevShow, setPrevShow] = useState(false);

  const [locationParams, setLocationParams] = useState({      
      userId:"",
      rideId:""      
    }); 

  const [filters, setFilters] = useState({
    durationFilter: "ALL",
    startDateTime: "",
    endDateTime: "",
  });

  const [subScriptionDataList, setSubcriptionDataList] = useState({
    subscriptionName: "",
    totalCount: 100,
    preLoginCrash: 20,
    preLoginOthers: 20,
    postLoginCrash: 20,
    postLoginOthers: 20,
    postLoginPermission: 10,
    postLoginPeriodical: 10,
    startDate: "",
    endDate: "",
    billing: "1 Year",
    orderCount: 0,
  });

  const [searchText, setSearchText] = useState("");
  const inputRef = useRef();
  const [selectedValue, setSelectedValue] = useState("ALL_LIST");
  const Location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(Location.search);
    
    const UID = queryParams.get("userId"); 
    const RID = queryParams.get("rideId");     

    const decodedUID = Converter.decrypt(decodeURIComponent(UID));
    const decodedRID = Converter.decrypt(decodeURIComponent(RID));
    // console.log(decodedUID,"queryParams")
    
    setLocationParams((prev) => {
      return {
        ...prev,      
        userId:decodedUID,
        rideId: decodedRID,        
      };
    });
    // console.log(locationParams,"locationParams.userId")
  }, [Location]);

  const pageSize = 10;

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const handleSelect = (userIdTemp, firstName) => {
    setSelectedOption(userIdTemp);
    setSelectUsername(firstName);
    // Do something with the selected option
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIndexStart(0);
      setPageNumber((current) => current - current + 1);
      event.preventDefault();
      inputRef.current.click();
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getRideDataSA(0)
  };

  const handleUserClick = (type) => {
    const selectedMapping = {
      ALL:"ALL_LIST",
      ACTIVE: "ACTIVE_LIST",      
      DELETED: "DELETED_LIST",
    };
    setSelectedValue(selectedMapping[type]);    
  };

 
  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  function capitalizeLetter  (category){
    if(category){
    return category
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
    }else{
      return "-"
    }
  } 
  
  const getRideListSA = async () => {
    setShowLoader(true);
    var result = await SuperAdminDashboardCtrlr.getV1SuperAdminRideListSingle(
      locationParams.userId,
      locationParams.rideId
    );
    
    try {

      var resultJSON = JSON.parse(result);
      // console.log(resultJSON,"resultJSON")
      var status = resultJSON.status;

      if (status == "SUCCESS") {

        setShowLoader(false)

        var rideListData = resultJSON.data;

        setDataLength(rideListData.length);

        // console.log(rideListData,"resultJSONDataRide")

        var updatedRideData = [];

        rideListData.forEach((rideData) => {

          var userDetail          = ValueFormat.parseAsObject(rideData.userDetail);
          var rideDetail          = ValueFormat.parseAsObject(rideData.rideDetail);
          var rideOtherDetail     = ValueFormat.parseAsObject(rideData.rideOtherDetail);
          var scoreDetail         = ValueFormat.parseAsObject(rideData.scoreDetail);
          var riskDataDetail      = ValueFormat.parseAsObject(rideData.riskDataDetail);
          var behaviourDataDetail = ValueFormat.parseAsObject(rideData.behaviourDataDetail);
          var timingDetail        = ValueFormat.parseAsObject(rideData.timingDetail);
          var locationDetail      = ValueFormat.parseAsObject(rideData.locationDetail);
          var deviceField         = ValueFormat.parseAsObject(locationDetail.deviceField);
          var lastDeviceField     = ValueFormat.parseAsObject(locationDetail.lastDeviceField);
          var dataCountDetail     = ValueFormat.parseAsObject(rideData.dataCountDetail);
          

          // setTotalPageCount(Math.ceil(totalCount / pageSize));
          // setNextShow(totalCount - indexStartOfSet > pageSize);

          // User Details
          
                    var firstName           = ValueFormat.parseAsString(userDetail.firstName);
                    var username            = ValueFormat.parseAsString(userDetail.username);
                    var deviceType          = ValueFormat.parseAsString(userDetail.deviceType);
                    var vehicleType         = ValueFormat.parseAsString(userDetail.vehicleType);
                    var usernameType        = ValueFormat.parseAsString(userDetail.usernameType);
                    var createdAtToTimeZone = ValueFormat.parseAsString(userDetail.createdAtToTimeZone);
                    var active              = ValueFormat.parseAsString(userDetail.active);
                    var status              = ValueFormat.parseAsString(userDetail.status);
          
                    var overallRideCount    = ValueFormat.parseAsInt(userDetail.overallRideCount);
                    
                    // Ride Details
          
                    var rideName         = ValueFormat.parseAsString(rideData.name) || "-";
                    var distanceInKm     = ValueFormat.parseAsString(rideDetail.distanceInKm) || "-";
                    var durationInMin    = ValueFormat.parseAsString(rideDetail.durationInMin) || "-";
                    var dayPercentage    = ValueFormat.parseAsString(rideDetail.dayPercentage) || "-";
                    var nightPercentage  = ValueFormat.parseAsString(rideDetail.nightPercentage) || "-";
          
                    // Attributes
          
                    var category                          = ValueFormat.parseAsString(rideOtherDetail.category) || "-";
                    var deviceMode                        = ValueFormat.parseAsString(rideOtherDetail.deviceMode) || "-";
                    var rideOtherDetailStatus             = ValueFormat.parseAsString(rideOtherDetail.status) || "-";
                    var insertMode                        = ValueFormat.parseAsString(rideOtherDetail.insertMode) || "-";
                    var timeZoneCode                      = ValueFormat.parseAsString(rideOtherDetail.timeZoneCode) || "-";
                    var batteryLevelStart                 = ValueFormat.parseAsString(rideOtherDetail.batteryLevelStart) || "-";
                    var batteryLevelEnd                   = ValueFormat.parseAsString(rideOtherDetail.batteryLevelEnd) || "-";
                    var deviceRawFileName                 = ValueFormat.parseAsString(rideOtherDetail.deviceRawFileName) || "-";
                    var deviceRawFileStatus               = ValueFormat.parseAsString(rideOtherDetail.deviceRawFileStatus) || "-";
                    var deviceSummaryStatus               = ValueFormat.parseAsString(rideOtherDetail.deviceSummaryStatus) || "-";
                    var subType                           = ValueFormat.parseAsString(rideOtherDetail.subType) || "-";
                    var attrDeviceType                    = ValueFormat.parseAsString(rideOtherDetail.deviceType) || "-";
                    var deviceSummaryModifiedAtToTimeZone = ValueFormat.parseAsString(rideOtherDetail.deviceSummaryModifiedAtToTimeZone) || "-";
          
                    // Score Details
          
                    var calDrivingScore       = ValueFormat.parseAsString(scoreDetail.calDrivingScore) || "-";
                    var calRiskScore          = ValueFormat.parseAsString(scoreDetail.calRiskScore) || "-";
                    var calBehaviourScore     = ValueFormat.parseAsString(scoreDetail.calBehaviourScore) || "-";
                    var crashProbabilityScore = ValueFormat.parseAsString(scoreDetail.crashProbabilityScore) || "-";
                    var calDrivingCategory    = ValueFormat.parseAsString(scoreDetail.calDrivingCategory) || "-";
                    
                    // Risk Data
          
                    var anticipationScore    = ValueFormat.parseAsString(riskDataDetail.anticipationScore) || "-";
                    var selfConfidenceScore  = ValueFormat.parseAsString(riskDataDetail.selfConfidenceScore) || "-";
                    var drivingSkillScore    = ValueFormat.parseAsString(riskDataDetail.drivingSkillScore) || "-";
          
                    // Behaviour Data
          
                    var calDrivingStyleScore    = ValueFormat.parseAsString(behaviourDataDetail.calDrivingStyleScore) || "-";
                    var calDrivingStateScore    = ValueFormat.parseAsString(behaviourDataDetail.calDrivingStateScore) || "-";
                    var calOverspeedScore       = ValueFormat.parseAsString(behaviourDataDetail.calOverspeedScore) || "-";
                    var calAccBrakCorScore      = ValueFormat.parseAsString(behaviourDataDetail.calAccBrakCorScore) || "-";
                    var calMobileCallScore      = ValueFormat.parseAsString(behaviourDataDetail.calMobileCallScore) || "-";
                    var calMobileScreenScore    = ValueFormat.parseAsString(behaviourDataDetail.calMobileScreenScore) || "-";
          
                    // Timings
          
                    var startDateTimeToTimeZone    = ValueFormat.parseAsString(timingDetail.startDateTimeToTimeZone) || "-";
                    var endDateTimeToTimeZone      = ValueFormat.parseAsString(timingDetail.endDateTimeToTimeZone) || "-";
                    var timingsCreatedAtToTimeZone = ValueFormat.parseAsString(timingDetail.createdAtToTimeZone) || "-";
                    var insertedAtToTimeZone       = ValueFormat.parseAsString(timingDetail.insertedAtToTimeZone) || "-";
                    var modifiedAtToTimeZone       = ValueFormat.parseAsString(timingDetail.modifiedAtToTimeZone) || "-";
          
                    // Start Location
          
                    var steartZipCode       = ValueFormat.parseAsString(deviceField.zipCode) || "-";
                    var steartState         = ValueFormat.parseAsString(deviceField.state) || "-";
                    var steartCity          = ValueFormat.parseAsString(deviceField.city) || "-";
                    var steartLocationName  = ValueFormat.parseAsString(locationDetail.locationName) || "-";
                    var startLatitude       = ValueFormat.parseAsString(locationDetail.latitude) || "-";
                    var startLongitude      = ValueFormat.parseAsString(locationDetail.longitude) || "-";
          
                    // End Location
          
                    var endZipCode       = ValueFormat.parseAsString(lastDeviceField.zipCode) || "-";
                    var endState         = ValueFormat.parseAsString(lastDeviceField.state) || "-";
                    var endCity          = ValueFormat.parseAsString(lastDeviceField.city) || "-";
                    var endLocationName  = ValueFormat.parseAsString(locationDetail.lastLocationName) || "-";
                    var lastLatitude       = ValueFormat.parseAsString(locationDetail.lastLatitude) || "-";
                    var lastLongitude      = ValueFormat.parseAsString(locationDetail.lastLongitude) || "-";
          
                    // Data Count
          
                    var startDataCount        = ValueFormat.parseAsString(dataCountDetail.startDataCount) || "-";
                    var endDataCount          = ValueFormat.parseAsString(dataCountDetail.endDataCount) || "-";
                    var distanceDataCount     = ValueFormat.parseAsString(dataCountDetail.distanceDataCount) || "-";
                    var errorDataCount        = ValueFormat.parseAsString(dataCountDetail.errorDataCount) || "-";
                    var alertDataCount        = ValueFormat.parseAsString(dataCountDetail.alertDataCount) || "-";
                    var stressStrainDataCount = ValueFormat.parseAsString(dataCountDetail.stressStrainDataCount) || "-";
                    var manualDataCount       = ValueFormat.parseAsString(dataCountDetail.manualDataCount) || "-";

         

          let statusList = "";

          if (status == "INITIATED" || status == "REGISTERED") {
            statusList = "Unregistered";
          } else if (status == "ACTIVE" && overallRideCount <= 0) {
            statusList = "Inactive";
          } else if (status == "ACTIVE" && overallRideCount > 0) {
            statusList = "Active";
          } else if (status == "DEACTIVATED") {
            statusList = "Deactivated";
          } else {
            statusList = "-";
          }


          var rideGetDataList = {

            firstName:firstName,
            username:username,
            deviceType:deviceType,
            vehicleType:vehicleType,
            usernameType:usernameType,
            createdAtToTimeZone:createdAtToTimeZone,
            active:active,
            status:statusList,

            rideName:rideName,
            distanceInKm:distanceInKm,
            durationInMin:durationInMin,
            dayPercentage:dayPercentage,
            nightPercentage:nightPercentage,

            category:category,
            deviceMode:deviceMode,
            rideOtherDetailStatus:rideOtherDetailStatus,
            insertMode:insertMode,
            timeZoneCode:timeZoneCode,
            batteryLevelStart:batteryLevelStart,
            batteryLevelEnd:batteryLevelEnd,
            deviceRawFileName:deviceRawFileName,
            deviceRawFileStatus:deviceRawFileStatus,
            deviceSummaryStatus:deviceSummaryStatus,
            deviceSummaryModifiedAtToTimeZone:deviceSummaryModifiedAtToTimeZone,
            subType:subType,
            attrDeviceType:attrDeviceType,

            calDrivingScore:calDrivingScore,
            calRiskScore:calRiskScore,
            calBehaviourScore:calBehaviourScore,
            crashProbabilityScore:crashProbabilityScore,
            calDrivingCategory:calDrivingCategory,

            anticipationScore:anticipationScore,
            selfConfidenceScore:selfConfidenceScore,
            drivingSkillScore:drivingSkillScore,

            calDrivingStyleScore:calDrivingStyleScore,
            calDrivingStateScore:calDrivingStateScore,
            calOverspeedScore:calOverspeedScore,
            calAccBrakCorScore:calAccBrakCorScore,
            calMobileCallScore:calMobileCallScore,
            calMobileScreenScore:calMobileScreenScore,

            startDateTimeToTimeZone:startDateTimeToTimeZone,
            endDateTimeToTimeZone:endDateTimeToTimeZone,
            timingsCreatedAtToTimeZone:timingsCreatedAtToTimeZone,
            insertedAtToTimeZone:insertedAtToTimeZone,
            modifiedAtToTimeZone:modifiedAtToTimeZone,

            steartZipCode:steartZipCode,
            steartState:steartState,
            steartCity:steartCity,
            steartLocationName:steartLocationName,
            startLatitude:startLatitude,
            startLongitude:startLongitude,

            endZipCode:endZipCode,
            endState:endState,
            endCity:endCity,
            endLocationName:endLocationName,
            lastLatitude:lastLatitude,
            lastLongitude:lastLongitude,

            startDataCount:startDataCount,
            endDataCount:endDataCount,
            distanceDataCount:distanceDataCount,
            errorDataCount:errorDataCount,
            alertDataCount:alertDataCount,
            stressStrainDataCount:stressStrainDataCount,
            manualDataCount:manualDataCount,

            
          }
          
          updatedRideData.push(rideGetDataList)
         
        })
        
        setSummaryDataList(updatedRideData);
        

        

        
       
        
        
      } else {   
        setDataLength(0);
        setShowLoader(false)
        // console.log("Status failed");
        var code = resultJSON.error.code;

        if (code == "E1206") {
          setShowLoader(false);
          // setDataDisplay("failed");
        }
      }
      setShowLoader(false)
      // if (indexStartOfSet == 0) {
      //   setPrevShow(false);
      // } else if (indexStartOfSet > 0) {
      //   setPrevShow(true);
      // }
    } catch (errMes) {}
  };

  const getRideDataSA = async (indexStartOfSet) => {
    setShowLoader(true);
    var result = await SuperAdminDashboardCtrlr.getV1SuperAdminRideData(
      indexStartOfSet,
      locationParams.userId,
      locationParams.rideId,
      deviceFilter,
      selectedValue,
      searchText
    );
    
    try {

      var resultJSON = JSON.parse(result);
      // console.log(resultJSON,"resultJSONRideData")
      var status = resultJSON.status;

      if (status == "SUCCESS") {

        setShowLoader(false)

        var rideListData = resultJSON.data;

        setDataLengthRideData(rideListData.length);

        // console.log(rideListData,"resultJSONDataRide")

        var newRideData = [];

        rideListData.forEach((rideData) => {

         var basicDetail            = ValueFormat.parseAsObject(rideData.basicDetail);
         var scoreDetail            = ValueFormat.parseAsObject(rideData.scoreDetail);
         var timingDetail           = ValueFormat.parseAsObject(rideData.timingDetail);
         var attributeDetail        = ValueFormat.parseAsObject(rideData.attributeDetail);
         var locationDetail         = ValueFormat.parseAsObject(rideData.locationDetail);
         var deviceDataAddressField = ValueFormat.parseAsObject(locationDetail.deviceDataAddressField);
         var routeDetail            = ValueFormat.parseAsObject(rideData.routeDetail);
         var permissionDetail       = ValueFormat.parseAsObject(rideData.permissionDetail);
         var manualDataDetail       = ValueFormat.parseAsObject(rideData.manualDataDetail);
         var riskSlotDetail         = ValueFormat.parseAsObject(rideData.riskSlotDetail);
         var otherAttributeDetail   = ValueFormat.parseAsObject(rideData.otherAttributeDetail);
         
         var totalCount       = ValueFormat.parseAsObject(rideData.totalCount);

         setTotalPageCount(Math.ceil(totalCount / pageSize));
         setNextShow(totalCount - indexStartOfSet > pageSize);
         // Basic Detail

         var category          = ValueFormat.parseAsString(basicDetail.category) || "-";
         var subCategory       = ValueFormat.parseAsString(basicDetail.subCategory) || "-";
         var subCategoryLevel  = ValueFormat.parseAsString(basicDetail.subCategoryLevel) || "-";
         var deviceModeObj     = ValueFormat.parseAsString(basicDetail.deviceModeObj) || "-";
         var subType           = ValueFormat.parseAsString(basicDetail.subType) || "-";
         var insertMode        = ValueFormat.parseAsString(basicDetail.insertMode) || "-";
         var basicDetailActive = ValueFormat.parseAsString(basicDetail.active) || "-";

         // Score Details

         var drivingScoreObj         = ValueFormat.parseAsString(scoreDetail.drivingScoreObj) || "-";
         var anticipationObj         = ValueFormat.parseAsString(scoreDetail.anticipationObj) || "-";
         var selfConfidenceObj       = ValueFormat.parseAsString(scoreDetail.selfConfidenceObj) || "-";
         var drivingSkillObj         = ValueFormat.parseAsString(scoreDetail.drivingSkillObj) || "-";
         var totalKiloMeterObj       = ValueFormat.parseAsString(scoreDetail.totalKiloMeterObj) || "-";
         var totalDurationObj        = ValueFormat.parseAsString(scoreDetail.totalDurationObj) || "-";
         
         // Timings

         var travelTimeObj           = ValueFormat.parseAsString(timingDetail.travelTimeObj) || "-";
         var previousTravelTimeObj   = ValueFormat.parseAsString(timingDetail.previousTravelTimeObj) || "-";
         var rideTimeObj             = ValueFormat.parseAsString(timingDetail.rideTimeObj) || "-";
         var createdAtToTimeZone     = ValueFormat.parseAsString(timingDetail.createdAtToTimeZone) || "-";
         var insertedAtToTimeZone    = ValueFormat.parseAsString(timingDetail.insertedAtToTimeZone) || "-";
         var modifiedAtToTimeZone    = ValueFormat.parseAsString(timingDetail.modifiedAtToTimeZone) || "-";

         // Speed

         var speedObj             = ValueFormat.parseAsString(attributeDetail.speedObj) || "-";
         var riskObj              = ValueFormat.parseAsString(attributeDetail.riskObj) || "-";
         var previousSpeedObj     = ValueFormat.parseAsString(attributeDetail.previousSpeedObj) || "-";
         var kiloMeterObj         = ValueFormat.parseAsString(attributeDetail.kiloMeterObj) || "-";
         var speedLimitObj        = ValueFormat.parseAsString(attributeDetail.speedLimitObj) || "-";
        //  var poi                  = attributeDetail.pointOfInterestInMtr ?? "";
         var pointOfInterestInMtr = attributeDetail.pointOfInterestInMtr ?? "-";
         
         // Location

         var accidentType           = ValueFormat.parseAsString(locationDetail.accidentType) || "-";
         var accidentSeverityValue  = ValueFormat.parseAsString(locationDetail.accidentSeverityValue) || "-";
         var latitudeObj            = ValueFormat.parseAsString(locationDetail.latitudeObj) || "-";
         var longitudeObj           = ValueFormat.parseAsString(locationDetail.longitudeObj) || "-";
         var zipCodeObj             = ValueFormat.parseAsString(locationDetail.zipCodeObj) || "-";
         var location               = ValueFormat.parseAsString(deviceDataAddressField?.location) || "-";
        
         var city                   = ValueFormat.parseAsString(deviceDataAddressField.city) || "-";
         var state                  = ValueFormat.parseAsString(deviceDataAddressField.state) || "-";

         // Path Details	

         var urbanPercentObj      = ValueFormat.parseAsString(routeDetail.urbanPercentObj) || "-";
         var urbanKilometerObj    = ValueFormat.parseAsString(routeDetail.urbanKilometerObj) || "-";
         var ruralPercentObj      = ValueFormat.parseAsString(routeDetail.ruralPercentObj) || "-";
         var ruralKilometerObj    = ValueFormat.parseAsString(routeDetail.ruralKilometerObj) || "-";
         var highwayPercentObj    = ValueFormat.parseAsString(routeDetail.highwayPercentObj) || "-";
         var highwayKilometerObj  = ValueFormat.parseAsString(routeDetail.highwayKilometerObj) || "-";

         // Permissions

         var networkStatusObj    = ValueFormat.parseAsString(permissionDetail.networkStatusObj) || "-";
         var bgLocationStatusObj = ValueFormat.parseAsString(permissionDetail.bgLocationStatusObj) || "-";
         var doaStatusObj        = ValueFormat.parseAsString(permissionDetail.doaStatusObj) || "-";
         var deviceStartModeObj  = ValueFormat.parseAsString(permissionDetail.deviceStartModeObj) || "-";
         var gpsStatusObj        = ValueFormat.parseAsString(permissionDetail.gpsStatusObj) || "-";

         // Manual Data

         var overSpeedDurationObj       = ValueFormat.parseAsString(manualDataDetail.overSpeedDurationObj) || "-";
         var overSpeedKiloMeterObj      = ValueFormat.parseAsString(manualDataDetail.overSpeedKiloMeterObj) || "-";
         var mobileUseCallDurationObj   = ValueFormat.parseAsString(manualDataDetail.mobileUseCallDurationObj) || "-";
         var mobileUseCallKiloMeterObj  = ValueFormat.parseAsString(manualDataDetail.mobileUseCallKiloMeterObj) || "-";
         var mobileScreenOnDurationObj  = ValueFormat.parseAsString(manualDataDetail.mobileScreenOnDurationObj) || "-";
         var mobileScreenOnKiloMeterObj = ValueFormat.parseAsString(manualDataDetail.mobileScreenOnKiloMeterObj) || "-";

         // Risk Slot Details

         var slotNegativeCountObj  = ValueFormat.parseAsString(riskSlotDetail.slotNegativeCountObj) || "-";
         var slotZeroCountObj      = ValueFormat.parseAsString(riskSlotDetail.slotZeroCountObj) || "-";
         var slot1CountObj         = ValueFormat.parseAsString(riskSlotDetail.slot1CountObj) || "-";
         var slot2CountObj         = ValueFormat.parseAsString(riskSlotDetail.slot2CountObj) || "-";
         var slot3CountObj         = ValueFormat.parseAsString(riskSlotDetail.slot3CountObj) || "-";
         var slot4CountObj         = ValueFormat.parseAsString(riskSlotDetail.slot4CountObj) || "-";
         var slot5CountObj         = ValueFormat.parseAsString(riskSlotDetail.slot5CountObj) || "-";
         var slot6CountObj         = ValueFormat.parseAsString(riskSlotDetail.slot6CountObj) || "-";
         var slot7CountObj         = ValueFormat.parseAsString(riskSlotDetail.slot7CountObj) || "-";
         var slot7DistanceObj      = ValueFormat.parseAsString(riskSlotDetail.slot7DistanceObj) || "-";
         var slot7DurationObj      = ValueFormat.parseAsString(riskSlotDetail.slot7DurationObj) || "-";
         var slot8CountObj         = ValueFormat.parseAsString(riskSlotDetail.slot8CountObj) || "-";
         var slot8DistanceObj      = ValueFormat.parseAsString(riskSlotDetail.slot8DistanceObj) || "-";
         var slot8DurationObj      = ValueFormat.parseAsString(riskSlotDetail.slot8DurationObj) || "-";
         var slot9CountObj         = ValueFormat.parseAsString(riskSlotDetail.slot9CountObj) || "-";
         var slot9DistanceObj      = ValueFormat.parseAsString(riskSlotDetail.slot9DistanceObj) || "-";
         var slot9DurationObj      = ValueFormat.parseAsString(riskSlotDetail.slot9DurationObj) || "-";
         var slot10CountObj        = ValueFormat.parseAsString(riskSlotDetail.slot10CountObj) || "-";
         var slot10DistanceObj     = ValueFormat.parseAsString(riskSlotDetail.slot10DistanceObj) || "-";
         var slot10DurationObj     = ValueFormat.parseAsString(riskSlotDetail.slot10DurationObj) || "-";

         // Other Attributes

         var driverStateObj        = ValueFormat.parseAsString(otherAttributeDetail.driverStateObj) || "-";
         var drivingStyleObj       = ValueFormat.parseAsString(otherAttributeDetail.drivingStyleObj) || "-";
         var riskStyleObj          = ValueFormat.parseAsString(otherAttributeDetail.riskStyleObj) || "-";
         var batteryLevelObj       = ValueFormat.parseAsString(otherAttributeDetail.batteryLevelObj) || "-";
         var trafficScoreObj       = ValueFormat.parseAsString(otherAttributeDetail.trafficScoreObj) || "-";
         var transportModeObj      = ValueFormat.parseAsString(otherAttributeDetail.transportModeObj) || "-";

         var alertObj              = ValueFormat.parseAsString(otherAttributeDetail.alertObj) || "-";
         var alertIdObj            = ValueFormat.parseAsString(otherAttributeDetail.alertIdObj) || "-";
         var alertValueObj         = ValueFormat.parseAsString(otherAttributeDetail.alertValueObj) || "-";
         var alertTimeObj          = ValueFormat.parseAsString(otherAttributeDetail.alertTimeObj) || "-";
         var alertKiloMeterObj     = ValueFormat.parseAsString(otherAttributeDetail.alertKiloMeterObj) || "-";
         var alertNameObj          = ValueFormat.parseAsString(otherAttributeDetail.alertNameObj) || "-";

         var accelerXObj           = ValueFormat.parseAsString(otherAttributeDetail.accelerXObj) || "-";
         var accelerYObj           = ValueFormat.parseAsString(otherAttributeDetail.accelerYObj) || "-";
         var accelerZObj           = ValueFormat.parseAsString(otherAttributeDetail.accelerZObj) || "-";
         var magnetometerXObj      = ValueFormat.parseAsString(otherAttributeDetail.magnetometerXObj) || "-";
         var magnetometerYObj      = ValueFormat.parseAsString(otherAttributeDetail.magnetometerYObj) || "-";
         var magnetometerZObj      = ValueFormat.parseAsString(otherAttributeDetail.magnetometerZObj) || "-";

         var gyroscopeXObj         = ValueFormat.parseAsString(otherAttributeDetail.gyroscopeXObj) || "-";
         var gyroscopeYObj         = ValueFormat.parseAsString(otherAttributeDetail.gyroscopeYObj) || "-";
         var gyroscopeZObj         = ValueFormat.parseAsString(otherAttributeDetail.gyroscopeZObj) || "-";
         var senRotateObj          = ValueFormat.parseAsString(otherAttributeDetail.senRotateObj) || "-";
         var gpsTimeDiffObj        = ValueFormat.parseAsString(otherAttributeDetail.gpsTimeDiffObj) || "-";
         var ehorizonLengthObj     = ValueFormat.parseAsString(otherAttributeDetail.ehorizonLengthObj) || "-";

         var wifiDataUsage         = ValueFormat.parseAsString(otherAttributeDetail.wifiDataUsage) || "-";
         var mobileDataUsage       = ValueFormat.parseAsString(otherAttributeDetail.mobileDataUsage) || "-";
         var engineStateObj        = ValueFormat.parseAsString(otherAttributeDetail.engineStateObj) || "-";
         var gpsCountObj           = ValueFormat.parseAsString(otherAttributeDetail.gpsCountObj) || "-";
         
        
          var rideDataLatest = {

            category:category,
            subCategory:subCategory,
            subCategoryLevel:subCategoryLevel,
            deviceModeObj:deviceModeObj,
            subType:subType,
            insertMode:insertMode,
            basicDetailActive:basicDetailActive,
            
            drivingScoreObj:drivingScoreObj,
            anticipationObj:anticipationObj,
            selfConfidenceObj:selfConfidenceObj,
            drivingSkillObj:drivingSkillObj,
            totalKiloMeterObj:totalKiloMeterObj,
            totalDurationObj:totalDurationObj,

            travelTimeObj:travelTimeObj,
            previousTravelTimeObj:previousTravelTimeObj,
            rideTimeObj:rideTimeObj,
            createdAtToTimeZone:createdAtToTimeZone,
            insertedAtToTimeZone:insertedAtToTimeZone,
            modifiedAtToTimeZone:modifiedAtToTimeZone,

            speedObj:speedObj,
            riskObj:riskObj,
            speedLimitObj:speedLimitObj,
            previousSpeedObj:previousSpeedObj,
            kiloMeterObj:kiloMeterObj,
            pointOfInterestInMtr:pointOfInterestInMtr,

            accidentType:accidentType,
            accidentSeverityValue:accidentSeverityValue,
            latitudeObj:latitudeObj,
            longitudeObj:longitudeObj,
            zipCodeObj:zipCodeObj,
            location:location,
            city:city,
            state:state,

            urbanPercentObj:urbanPercentObj,
            urbanKilometerObj:urbanKilometerObj,
            ruralPercentObj:ruralPercentObj,
            ruralKilometerObj:ruralKilometerObj,
            highwayPercentObj:highwayPercentObj,
            highwayKilometerObj:highwayKilometerObj,

            networkStatusObj:networkStatusObj,
            bgLocationStatusObj:bgLocationStatusObj,
            doaStatusObj:doaStatusObj,
            deviceStartModeObj:deviceStartModeObj,
            gpsStatusObj:gpsStatusObj,

            overSpeedDurationObj:overSpeedDurationObj,
            overSpeedKiloMeterObj:overSpeedKiloMeterObj,
            mobileUseCallDurationObj:mobileUseCallDurationObj,
            mobileUseCallKiloMeterObj:mobileUseCallKiloMeterObj,
            mobileScreenOnDurationObj:mobileScreenOnDurationObj,
            mobileScreenOnKiloMeterObj:mobileScreenOnKiloMeterObj,

            slotNegativeCountObj:slotNegativeCountObj,
            slotZeroCountObj:slotZeroCountObj,
            slot1CountObj:slot1CountObj,
            slot2CountObj:slot2CountObj,
            slot3CountObj:slot3CountObj,
            slot4CountObj:slot4CountObj,
            slot5CountObj:slot5CountObj,
            slot6CountObj:slot6CountObj,
            slot7CountObj:slot7CountObj,
            slot7DistanceObj:slot7DistanceObj,
            slot7DurationObj:slot7DurationObj,
            slot8CountObj:slot8CountObj,
            slot8DistanceObj:slot8DistanceObj,
            slot8DurationObj:slot8DurationObj,
            slot9CountObj:slot9CountObj,
            slot9DistanceObj:slot9DistanceObj,
            slot9DurationObj:slot9DurationObj,
            slot10CountObj:slot10CountObj,
            slot10DistanceObj:slot10DistanceObj,
            slot10DurationObj:slot10DurationObj,
            
            driverStateObj:driverStateObj,
            drivingStyleObj:drivingStyleObj,
            riskStyleObj:riskStyleObj,
            batteryLevelObj:batteryLevelObj,
            trafficScoreObj:trafficScoreObj,
            transportModeObj:transportModeObj,
            alertObj:alertObj,
            alertIdObj:alertIdObj,
            alertValueObj:alertValueObj,
            alertTimeObj:alertTimeObj,
            alertKiloMeterObj:alertKiloMeterObj,
            alertNameObj:alertNameObj,
            accelerXObj:accelerXObj,
            accelerYObj:accelerYObj,
            accelerZObj:accelerZObj,
            magnetometerXObj:magnetometerXObj,
            magnetometerYObj:magnetometerYObj,
            magnetometerZObj:magnetometerZObj,
            gyroscopeXObj:gyroscopeXObj,
            gyroscopeYObj:gyroscopeYObj,
            gyroscopeZObj:gyroscopeZObj,
            senRotateObj:senRotateObj,
            gpsTimeDiffObj:gpsTimeDiffObj,
            ehorizonLengthObj:ehorizonLengthObj,
            wifiDataUsage:wifiDataUsage,
            mobileDataUsage:mobileDataUsage,
            engineStateObj:engineStateObj,
            gpsCountObj:gpsCountObj,

            
          }
          
          newRideData.push(rideDataLatest)
         
        })
        
        setRideData(newRideData);
        

        

        
       
        
        
      } else {   
        setDataLengthRideData(0);
        setShowLoader(false)
        // console.log("Status failed");
        var code = resultJSON.error.code;

        if (code == "E1206") {
          setShowLoader(false);
          // setDataDisplay("failed");
        }
      }
      setShowLoader(false)
      if (indexStartOfSet == 0) {
        setPrevShow(false);
      } else if (indexStartOfSet > 0) {
        setPrevShow(true);
      }
    } catch (errMes) {}
  };

 
    useEffect(() => {
      if(locationParams.userId && locationParams.rideId){
      getRideDataSA(indexStart);
      }
  }, [indexStart,deviceFilter,locationParams,selectedValue]);
  



   useEffect(() => {
    if(locationParams.userId && locationParams.rideId){
      getRideListSA();
     
    }
    }, [locationParams]);


  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Battery Level" breadcrumbItem="Battery Level" />
        {/* {loginType == "SUPER_ADMIN" ? (
          <span
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Welcome, You are viewing{" "}
            <span
              style={{
                fontSize: "13px",
                fontWeight: 800,
              }}
            >
              {userFirstName}'s
            </span>{" "}
            App Exception
          </span>
        ) : ( */}
            <Row className="my-2 py-2 d-flex justify-content-start align-items-center mb-3 mt-3" >
            <Col xs={3} className="d-flex justify-content-start align-items-center text-black" style={{ height:"0px"}}>
              <h5 className="mb-0" style={{textWrap:"nowrap",}}>User Ride Detail</h5>
            </Col>
          
          </Row>
          {/* )}  */}

          

              <Row style={{marginTop:"-20px", }}>
                <Col xl={12} md={12} sm={12}>
                  <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",}}>
                      <div className="my-3" style={{ display: "flex", justifyContent: "center", flexDirection: "row", height: "40px",marginTop: "-50px", }}>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", width:"250px"}} className="me-1" >
              <input type="search" style={{ border: " 1px solid #EEEBEB", width: "100%", height: "80%", }}
                value={searchText}
                onKeyPress={handleKeyPress}
                onChange={(e) => { setSearchText(e.target.value)}}
                placeholder="Search User Detail"
              />
            </div>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }}  >
                          <button ref={inputRef}
                            style={{ marginLeft: "2px", height: "80%", display: "flex", justifyContent: "center", flexDirection: "column", }}
                            className="btn btnColorStylesNew me-3"
                            type="button"
                            onClick={handleSearch}
                          >
                            <i className="bx bx-search-alt align-middle"></i>
                          </button>
                        </div>
                      </div>
                      <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", gap:10}}>
                      
                        
                        <div className="my-0" style={{ height: "0px", display: "flex", alignItems:"center", marginTop: "0x",  }}>
                          <div className=" mt-0 mb-0 mr-0 ms-auto pe-1 border-end border-secondary" >
                            <div style={{ float: "right" }}>
                            {["ALL","ACTIVE", "DELETED"].map((type) => (
                              <button
                                key={type}
                                type="button"
                                style={{
                                  fontWeight: 700,
                                  // backgroundColor: selectedValue === type ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm", // Highlight selected
                                  // color: selectedValue === type ? "#fff" : "#000",
                                  marginRight:"5px"
                                }}
                                className={`btn btn-sm ${
                                  selectedValue === `${type}_LIST` ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm"
                                }`}
                                id={type}
                                onClick={() => handleUserClick(type)} // Pass type to handler
                              >
                                {type.charAt(0) + type.slice(1).toLowerCase()} {/* Format button label */}
                              </button>
                            ))}
                            </div>
                          </div>
                        </div>

                        <div className="mt-0 mb-0 mr-0 ms-auto pe-2 " style={{ textAlign: "center",  }}>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == ""
                                ? "btn btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("")}
                            
                          >
                            All Data
                            
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "START_DATE"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("START_DATA")}
                           
                          >
                            Start Data
                            
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "END_DATE"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("END_DATA")}
                            
                          >
                            End Data
                           
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "DISTANCE_DATA"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("DISTANCE_DATA")}
                           
                          >
                            Distance Data
                           
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "ERROR_DATA"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("ERROR_DATA")}
                           
                          >
                            Error Data
                           
                          </button>

                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "ALERT_DATA"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("ALERT_DATA")}
                           
                          >
                            Alert Data
                           
                          </button>

                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "MANUAL_DATA"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("MANUAL_DATA")}
                           
                          >
                            Manual Data
                           
                          </button>

                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceFilter == "STRESS_STRAIN_DATA"
                                ? "btn btnColorStylesNew btn-sm me-1"
                                : "btn btn-soft-secondary btn-sm me-1"
                            }
                            onClick={() => setDeviceFilter("STRESS_STRAIN_DATA")}
                           
                          >
                            Stress Strain Data
                            
                          </button>
                        </div>

                      </div>
                 
                  </div>

                  <div className="mt-0" style={{ overflowX: "auto",border:"1px solid #DFDEDE"}} >
                    <Table className="table table-bordered mb-0" style={{ borderCollapse: "collapse" }}>
                      <thead className="cardHeader">
                        <tr>
                          <th className="cardHeader" style={{ fontSize: "13px", fontWeight: 600, padding: "14px", position: "sticky", left: -2, zIndex: 2, background:"white" }} >
                            User Details                            
                          </th>
                          <th className="cardHeader"     style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center",
                              position: "sticky",
                              left: 120,
                              zIndex: 2,
                              background:"white",
                            }} >
                            Ride Details
                          </th>
                          <th colSpan={3} style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }}  >
                            Attributes
                          </th>
                         
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center",whiteSpace:"nowrap" }} >
                            Score Details
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Risk Data
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Behaviour Data
                          </th> 
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Timings
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center",whiteSpace:"nowrap" }} >
                          Start Location
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          End Location
                          </th> 
                          <th colSpan={2} style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Data Count
                          </th>      
                              
                          
                        </tr>
                      </thead>
                      <tbody>
                        {
                        dataLength > 0 ? 
                        (
                          (summaryDataList || []).map((apiData, index) => (
                            <tr 
                            key={index}
                            >
                              <td style={{ fontSize: "13px", verticalAlign: "middle", position:!(dataLength === 0) && !(dataLength === null) && "sticky", left:!(dataLength === 0) && !(dataLength === null) && -2, zIndex:!(dataLength === 0) && !(dataLength === null) && 2, backgroundColor: "white", }} >
                                <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={UserNameIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                  </div>                          
                                  <div className="second-div-flex" >     
                                      <span className="span-tag-name" style={{whiteSpace:"nowrap"}} >
                                        {apiData.firstName} 
                                        </span>
                                  </div>
                                  </div>
                                  
                                  <div className="div-flex" style={{marginTop:"10px"}} >
                                  <div>
                                    <p className="p-tag-username">
                                      {apiData.username} 
                                      </p>
                                  </div>
                                  <div style={{marginLeft:"4px"}}>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    >
                                    {apiData.status}
                                    </button>
                                  </div>
                                  </div>
                                

                                <div  className="div-flex-margin" >
                                    <div style={{ display: "flex", marginLeft: "3px", }}>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                        {apiData.deviceType == "ANDROID" ? (
                                          <img src={andIconNew} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} /> 
                                        ) : apiData.deviceType == "IOS" ? (
                                          <img src={iosIconNew} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                        ) : (
                                          <img src={noDeviceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                        )}
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                        {(apiData.deviceType == "ANDROID") || (apiData.deviceType == "IOS" ) ? "Mobile" : (apiData.deviceType == "ANDROID_SDK") || (apiData.deviceType == "IOS_SDK" )  ?  "SDK" : "Other"}
                                      </div>
                                    </div>     
                                    <div className="div-spacing" > | </div>
                                    <div style={{ display: "flex", marginLeft: "3px", }}>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                        <img src={FTwheelerIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                        {apiData.vehicleType === "FOUR_WHEELER" 
                                          ? "4W" 
                                          : apiData.vehicleType === "TWO_WHEELER" 
                                          ? "2W" 
                                          : "-"}
                                      </div>
                                    </div>
                                </div>

                                <div>
                                  <div className="div-flex-reg-act">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ActType} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Act Type : </p>
                                    <p className="p-tag-text-left" >{capitalizeLetter(apiData.active)}</p>
                                  </div>

                                  <div className="div-flex-reg-act">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ActiveDate} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Act Date : </p>
                                    <p className="p-tag-text-left" >
                                      {UtilDateTime.formatDateInt(apiData.createdAtToTimeZone)} | {formatTime(apiData.createdAtToTimeZone)} {apiData.createdAtToTimeZone.split(" ")[2]}
                                      </p>
                                  </div>
                                </div>                    
                              </td>

                              <td  style={{
                    fontSize: "13px",
                    verticalAlign: "middle",
                    textAlign: "center",
                    position:!(dataLength === 0) && !(dataLength === null) && "sticky",
                  left:!(dataLength === 0) && !(dataLength === null) && 120,
                  zIndex:!(dataLength === 0) && !(dataLength === null) && 2,
                  background:"white"
                  }}
                      >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={RideNumberIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right"  > Ride No:  </p>
                                    <p className="p-tag-text-left">
                                    {apiData.rideName}
                                    </p>
                                  </div>
                                  {/* <div className="div-flex-reg-detail">
                                  <div>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    > -
                                    {apiData.userStatus}
                                    </button>
                                  </div>
                                  </div> */}
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DistanceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Distance: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.distanceInKm} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DurationIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Duration: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.durationInMin} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DayIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Day:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.dayPercentage}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={NightIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Night:</p>
                                  <p className="p-tag-text-left" >
                                    {apiData.nightPercentage}
                                  </p>
                                </div>
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={CategoryIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Category:  </p>
                                    <p className="p-tag-text-left" >
                                    {capitalizeLetter(apiData.category)}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={ActivityIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Activity: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiData.deviceMode)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DeviceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Device: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiData.attrDeviceType)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={StatusIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Status:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.rideOtherDetailStatus}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={EventIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Event:  </p>
                                    <p className="p-tag-text-left" >
                                    {capitalizeLetter(apiData.subType)}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={InsertModeIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Insert Mode: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiData.insertMode)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={TimeZoneIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Time Zone: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.timeZoneCode}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={BatteryStartIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Battery Start:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.batteryLevelStart}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={BattryEndIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Battery End:</p>
                                  <p className="p-tag-text-left" >
                                 {apiData.batteryLevelEnd}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={FileNameIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >File Name:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.deviceRawFileName}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={FileStatusIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >File Status: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.deviceRawFileName}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={SummaryStatusIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Summary Status: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiData.deviceSummaryStatus)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={SummaryTimeIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Summary Time:</p>
                                  <p className="p-tag-text-left" >
                                 {UtilDateTime.formatDateInt(apiData.deviceSummaryModifiedAtToTimeZone)} | {formatTime(apiData.deviceSummaryModifiedAtToTimeZone)} {apiData.deviceSummaryModifiedAtToTimeZone.split(" ")[2]}
                                  </p>
                                </div>

                                

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ScoreIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Score:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.calDrivingScore}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={GradeIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Grade: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiData.calDrivingCategory)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={RiskIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Risk: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calRiskScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={BehaviorIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Behavior:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calBehaviourScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={CppIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >CPP:</p>
                                  <p className="p-tag-text-left" >
                                 {apiData.crashProbabilityScore}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={AnticipationIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Anticipation:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.anticipationScore}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={SelfConfidenceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Self Confidence: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.selfConfidenceScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DrivingSkilIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Driving Skill: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.drivingSkillScore}
                                  </p>
                                </div>

                                
                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={DrivingStyleIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Driving Style:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.calDrivingStyleScore}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DrivingStateIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Driving State: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calDrivingStateScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MobileUsageIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Call: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calMobileCallScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Screen: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calMobileScreenScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={OverspeedIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Over Speed:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calOverspeedScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AccBrakCorIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Acc/Brak/Cor:</p>
                                  <p className="p-tag-text-left" >
                                 {apiData.calAccBrakCorScore}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={TimingStartIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Start:  </p>
                                    <p className="p-tag-text-left" >
                                    {UtilDateTime.formatDateInt(apiData.startDateTimeToTimeZone)} | {formatTime(apiData.startDateTimeToTimeZone)} {apiData.startDateTimeToTimeZone.split(" ")[2]}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={TimingEndIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >End: </p>
                                  <p className="p-tag-text-left" >
                                  {UtilDateTime.formatDateInt(apiData.endDateTimeToTimeZone)} | {formatTime(apiData.endDateTimeToTimeZone)} {apiData.endDateTimeToTimeZone.split(" ")[2]}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={CreatedIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Created: </p>
                                  <p className="p-tag-text-left" >
                                  {UtilDateTime.formatDateInt(apiData.timingsCreatedAtToTimeZone)} | {formatTime(apiData.timingsCreatedAtToTimeZone)} {apiData.timingsCreatedAtToTimeZone.split(" ")[2]}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={InsertedIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Inserted:</p>
                                  <p className="p-tag-text-left" >
                                  {UtilDateTime.formatDateInt(apiData.insertedAtToTimeZone)} | {formatTime(apiData.insertedAtToTimeZone)} {apiData.insertedAtToTimeZone.split(" ")[2]}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={ModifiedIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Modified:</p>
                                  <p className="p-tag-text-left" >
                                  {UtilDateTime.formatDateInt(apiData.modifiedAtToTimeZone)} | {formatTime(apiData.modifiedAtToTimeZone)} {apiData.modifiedAtToTimeZone.split(" ")[2]}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                              <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={StartLatIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px", }} />
                                  </div>                          
                                  <div className="second-div-flex" style={{marginLeft:"-2px"}}>     
                                  <p className="p-tag-text-right" >Lat:{" "} 
                                       {apiData.startLatitude}
                                        </p>
                                  </div>
                                  <div className="div-spacing" > | </div>
                                  <div>
                                  <p className="p-tag-text-right" >Long:{" "} 
                                        {apiData.startLongitude}
                                        </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={StartZipcodeIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Zipcode: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.steartZipCode}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={StartLocationIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Location: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.steartLocationName}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={StartAddressIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Address:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.steartLocationName}, {apiData.steartCity}, {apiData.steartState} 
                                  </p>
                                </div>

                               
                               
                              </td>

                              <td className="td-container" >
                              <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={EndLatIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px", }} />
                                  </div>                          
                                  <div className="second-div-flex" style={{marginLeft:"-2px"}}>     
                                  <p className="p-tag-text-right" >Lat:{" "} 
                                        {apiData.lastLatitude}
                                        </p>
                                  </div>
                                  <div className="div-spacing" > | </div>
                                  <div>
                                  <p className="p-tag-text-right" >Long: {" "}
                                        {apiData.lastLongitude}
                                        </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={EndZipCodeIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Zipcode: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.endZipCode}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={EndLocationIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Location: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.endLocationName}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={EndAddressIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Address:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.endLocationName}, {apiData.endCity}, {apiData.endState}
                                  </p>
                                </div>

                               
                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={DataCountStartIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Start:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.startDataCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DataCountEndIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >End: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.endDataCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DistanceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Distance: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.distanceDataCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DataCountErrorIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Error:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.errorDataCount}
                                  </p>
                                </div>

                               

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={AlertIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Alert:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.alertDataCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={StressStrainIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Stress Strain: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.stressStrainDataCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={ManualIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Manual: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.manualDataCount}
                                  </p>
                                </div>                          

                               
                              </td>                          

                            </tr>
                        ))
                        ) 
                        
                        : dataLength === 0 ? ( 
                          <tr>
                            {" "}
                            <td colSpan="31">
                              <div
                                style={{
                                  color: "red",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                  fontSize:"13px"
                                }}
                              >
                                {displayText.DATA_NOT_AVAILABLE}
                              </div>
                            </td>
                          </tr>
                        ) : dataLength === null ? (
                          <tr>
                            {" "}
                            <td colSpan="31">
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.FETCHING_DATA}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                        
                        }


                      </tbody>
                    </Table>          
                  </div>  
                </Col>
              </Row>



       
        

        <Row style={{marginTop:"20px", }}>
                <Col xl={12} md={12} sm={12}>                 

                  <div className="mt-0" style={{ overflowX: "auto",border:"1px solid #DFDEDE"}} >
                    <Table className="table table-bordered mb-0" style={{ borderCollapse: "collapse" }}>
                      <thead className="cardHeader">
                        <tr>
                          <th className="cardHeader" style={{ fontSize: "13px", fontWeight: 600, padding: "14px", position: "sticky", left: -2, zIndex: 2, background:"white" }} >
                            Basic Detail                            
                          </th>
                          <th className="cardHeader"     style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center",
                              position: "sticky",
                              left: 120,
                              zIndex: 2,
                              background:"white",
                            }} >
                            Score Details
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }}  >
                           Timings
                          </th>
                         
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center",whiteSpace:"nowrap" }} >
                            Speed
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Location
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Path Details
                          </th> 
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Permissions
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center",whiteSpace:"nowrap" }} >
                          Manual Data
                          </th>   
                          <th colSpan={2} style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                           Risk Slot Details
                          </th> 
                          <th colSpan={5}  style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Other Attributes
                          </th>      
                                      
                        </tr>
                      </thead>

                      <tbody>
                        {dataLengthRideData > 0 ? (
                          (rideData || []).map((apiRideData, index) => (
                            <tr 
                            key={index}
                            >
                              <td style={{ fontSize: "13px", verticalAlign: "middle", position: "sticky", left: -2, zIndex: 2, backgroundColor: "white", }} >
                              <div style={{display:"flex"}}>

                                  <div className="div-flex-reg-detail">   

                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    >
                                   {capitalizeLetter(apiRideData.category)}
                                    </button>
                                    
                                  </div>
                                 
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={SubCategoryRD} alt="SubCategoryRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Sub Category: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiRideData.subCategory)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={LevelIconRD} alt="LevelIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Level: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiRideData.subCategoryLevel)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DeviceIconRD} alt="DeviceIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Device:</p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiRideData.deviceModeObj)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={subTypeIconRD} alt="subTypeIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Sub Type:</p>
                                  <p className="p-tag-text-left" >
                                    {capitalizeLetter(apiRideData.subType)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={ModeIconRD} alt="ModeIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >{capitalizeLetter(apiRideData.insertMode)}</p>
                                  <p className="p-tag-text-left" >
                                    |
                                  </p>
                                  <p className="p-tag-text-left" >
                                    {capitalizeLetter(apiRideData.basicDetailActive)}
                                    </p>
                                </div>
                              </td>

                              <td style={{
                    fontSize: "13px",
                    verticalAlign: "middle",
                    textAlign: "center",
                    position:"sticky",
                  left:  120,
                  zIndex: 2,
                  background:"white"
                  }}  >

<div style={{display:"flex"}}>
    <div className="div-flex-reg-detail">
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
        <img src={ScoreIconRD} alt="ScoreIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
      </div>
      <p className="p-tag-text-right" >Score:  </p>
      <p className="p-tag-text-left" >
      {apiRideData.drivingScoreObj}
      </p>
    </div>
    
  </div>

  <div className="div-flex-reg-act">
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
      <img src={AnticipationIconRD} alt="AnticipationIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
    </div>
    <p className="p-tag-text-right" >Anticipation: </p>
    <p className="p-tag-text-left" >
    {apiRideData.anticipationObj}
    </p>
  </div>

  <div className="div-flex-reg-act">
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
      <img src={SelfConfidenceRD} alt="SelfConfidenceRD" style={{ height: "25px", marginLeft: "-4px" }} />
    </div>
    <p className="p-tag-text-right" >Self Confidence: </p>
    <p className="p-tag-text-left" >
    {apiRideData.selfConfidenceObj}
    </p>
  </div>

  <div className="div-flex-reg-act">
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
      <img src={DrivingSkillIconRD} alt="DrivingSkillIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
    </div>
    <p className="p-tag-text-right" >Driving Skill:</p>
    <p className="p-tag-text-left" >
    {apiRideData.drivingSkillObj}
    </p>
  </div>

  <div className="div-flex-reg-act">
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
      <img src={TDistanceIconRD} alt="TDistanceIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
    </div>
    <p className="p-tag-text-right" >T.Distance:</p>
    <p className="p-tag-text-left" >
    {apiRideData.totalKiloMeterObj}
    </p>
  </div>


  <div className="div-flex-reg-act">
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
      <img src={TDurationIconRD} alt="TDurationIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
    </div>
    <p className="p-tag-text-right" >T.Duration:</p>
    <p className="p-tag-text-left" >
    {apiRideData.totalDurationObj}
    </p>
  </div>

 
 
</td>


                              <td  className="td-container"
                      >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={TravelTimeIconRD} alt="TravelTimeIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Travel Time:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.travelTimeObj}
                                    </p>
                                  </div>
                                  {/* <div className="div-flex-reg-detail">
                                  <div>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    > -
                                    {apiData.userStatus}
                                    </button>
                                  </div>
                                  </div> */}
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={PreviousTravelTimeRD} alt="PreviousTravelTimeRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Previous Travel Time: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.previousTravelTimeObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={RideTimeIconRD} alt="RideTimeIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Ride Time: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.rideTimeObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={CreatedIconRD} alt="CreatedIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Created:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.createdAtToTimeZone}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={InsertedIconRD} alt="InsertedIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Inserted:</p>
                                  <p className="p-tag-text-left" >
                                    {apiRideData.insertedAtToTimeZone}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={ModifiedIconRD} alt="ModifiedIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Modified:</p>
                                  <p className="p-tag-text-left" >
                                    {apiRideData.modifiedAtToTimeZone}
                                  </p>
                                </div>
                              </td>

                              <td className="td-container" >
                              <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={SpeedRiskIconRD} alt="SpeedRiskIconRD" style={{ height: "25px", marginLeft: "-4px", }} />
                                  </div>                          
                                  <div className="second-div-flex" style={{marginLeft:"-2px",marginTop:"2px"}}>     
                                  <p className="p-tag-text-right" >Speed: 
                                       {apiRideData.speedObj}
                                        </p>
                                  </div>
                                  <div className="div-spacing" style={{marginTop:"2px"}} > | </div>
                                  <div>
                                  <p className="p-tag-text-right" style={{marginTop:"2px"}} >Risk: 
                                        {apiRideData.riskObj}
                                        </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={SpeedLimitIconRD} alt="SpeedLimitIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Speed Limit: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.speedLimitObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={PSpeedIconRD} alt="PSpeedIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >P.Speed:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.previousSpeedObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DistanceIconRD} alt="DistanceIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Distance:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.kiloMeterObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={PoiIconRD} alt="PoiIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >POI:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.pointOfInterestInMtr}
                                  </p>
                                </div>
                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={CrashesIconRD} alt="CrashesIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Crash:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.accidentType} {`[Val: ${apiRideData.accidentSeverityValue}]`}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex" style={{marginTop:"10px"}}>
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={LatLngIconRD} alt="LatLngIconRD" style={{ height: "25px", marginLeft: "-5px", }} />
                                  </div>                          
                                  <div className="second-div-flex" style={{marginLeft:"-2px",marginTop:"2px"}}>     
                                  <p className="p-tag-text-right" >Lat: 
                                       {apiRideData.latitudeObj}
                                        </p>
                                  </div>
                                  <div className="div-spacing" style={{marginTop:"2px"}}> | </div>
                                  <div>
                                  <p className="p-tag-text-right" style={{marginTop:"2px"}} >Long: 
                                        {apiRideData.longitudeObj}
                                        </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={ZipCodeIconRD} alt="ZipCodeIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Zipcode: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.zipCodeObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={LocationIconRD} alt="LocationIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Location:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.location}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AddressIconRD} alt="AddressIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Address:</p>
                                  <p className="p-tag-text-left" >
                                 {apiRideData.location}, {apiRideData.city}, {apiRideData.state}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={UrbanIconRD} alt="UrbanIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Urban:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.urbanPercentObj}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={UrbanKmsIconRD} alt="UrbanKmsIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Urban: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.urbanKilometerObj} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={RuralIconRD} alt="RuralIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Rural: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.ruralPercentObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={RuralKmsIconRD} alt="RuralKmsIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Rural:</p>
                                  <p className="p-tag-text-left" >
                                 {apiRideData.ruralKilometerObj} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={HighWayIconRD} alt="HighWayIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Highway:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.highwayPercentObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={HighWayKmsIconRD} alt="HighWayKmsIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Highway:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.highwayKilometerObj} Kms
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={NetworkIconRD} alt="NetworkIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Network:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.networkStatusObj}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={GpsIconRD} alt="GpsIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >GPS: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.gpsStatusObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={BgLocationRD} alt="BgLocationRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >BG Location: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.bgLocationStatusObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DoaStatusIconRD} alt="DoaStatusIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >DOA Status:</p>
                                  <p className="p-tag-text-left" >
                                 {apiRideData.doaStatusObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={StartModeIconRD} alt="StartModeIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Start Mode:</p>
                                  <p className="p-tag-text-left" >
                                 {capitalizeLetter(apiRideData.deviceStartModeObj)}
                                  </p>
                                </div>

                                

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={OverSpeedMinsIconRD} alt="OverSpeedMinsIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Over Speed:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.overSpeedDurationObj} Mins
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={OverSpeedKmsIconRD} alt="OverSpeedKmsIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Over Speed:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.overSpeedKiloMeterObj} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MobileCallMinsIconRD1} alt="MobileCallMinsIconRD1" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Call: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.mobileUseCallDurationObj} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MobileCallKmsIconRD} alt="MobileCallKmsIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Call: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.mobileUseCallKiloMeterObj} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MobileScreenMinsIconRD} alt="MobileScreenMinsIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Screen: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.mobileScreenOnDurationObj} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MobileScreenKmsIconRD} alt="MobileScreenKmsIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Screen:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.mobileScreenOnKiloMeterObj} Kms
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={NegativeIconRD} alt="NegativeIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Negative:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.slotNegativeCountObj}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={ZeroIconRD} alt="ZeroIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Zero: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.slotZeroCountObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Slot1IconRD} alt="Slot1IconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 1: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.slot1CountObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Slot2IconRD} alt="Slot2IconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 2: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.slot2CountObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Slot3IconRD} alt="Slot3IconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 3: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.slot3CountObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Slot4IconRD} alt="Slot4IconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 4: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.slot4CountObj}
                                  </p>
                                </div>

                                
                               
                              </td>

                              <td className="td-container" >
                                
                              <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={Slot5IconRD} alt="Slot5IconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Slot 5:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.slot5CountObj}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={Slot6IconRD} alt="Slot6IconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 6: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.slot6CountObj} 
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Slot7IconRD} alt="Slot7IconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 7: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.slot7CountObj} | {apiRideData.slot7DistanceObj} Kms | {apiRideData.slot7DurationObj} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={Slot8IconRD} alt="Slot8IconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 8:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.slot8CountObj} | {apiRideData.slot8DistanceObj} Kms | {apiRideData.slot8DurationObj} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={Slot9IconRD} alt="Slot9IconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 9:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.slot9CountObj} | {apiRideData.slot9DistanceObj} Kms | {apiRideData.slot9DurationObj} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={Slot10IconRD} alt="Slot10IconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 10:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.slot10CountObj} | {apiRideData.slot10DistanceObj} Kms | {apiRideData.slot10DurationObj} Mins
                                  </p>
                                </div>

                               
                              </td>

                              

                             
                              <td className="td-container" >
                              
                              <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={DrivingStateIconRD} alt="DrivingStateIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Driving State:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.driverStateObj}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DrivingStyleIconRD} alt="DrivingStyleIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Driving Style: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.drivingStyleObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={RiskStyleIconRD} alt="RiskStyleIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Risk Style: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.riskStyleObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={BatteryLevelIconRD} alt="BatteryLevelIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Battery Level:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.batteryLevelObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={TrafficScoreIconRD} alt="TrafficScoreIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Traffic Score:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.trafficScoreObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={TransportIconRD} alt="TransportIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Transport Mode:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.transportModeObj}
                                  </p>
                                </div>

                               
                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={AlertIconRD} alt="AlertIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Alert:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.alertObj}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AlertIdIconRD} alt="AlertIdIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Alert Id: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.alertIdObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={AlertValueIconRD} alt="AlertValueIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Alert Value: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.alertValueObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AlertTimeIconRD} alt="AlertTimeIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Alert Time:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.alertTimeObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AlertKmsIconRD} alt="AlertKmsIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Alert Kms:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.alertKiloMeterObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AlertNameIconRD} alt="AlertNameIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Alert Name:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.alertNameObj}
                                  </p>
                                </div>

                               

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={AccelerationXIconRD} alt="AccelerationXIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Acceleration X:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.accelerXObj}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AccelerationYIconRD} alt="AccelerationYIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Acceleration Y: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.accelerYObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={AccelerationZIconRD} alt="AccelerationZIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Acceleration Z: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.accelerZObj}
                                  </p>
                                </div>     

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MagnetoMeterXIconRD} alt="MagnetoMeterXIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Magneto Meter X: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.magnetometerXObj}
                                  </p>
                                </div>      

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MagnetoMeterYIconRD} alt="MagnetoMeterYIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Magneto Meter Y: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.magnetometerYObj}
                                  </p>
                                </div>      

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MagnetoMeterZIconRD} alt="MagnetoMeterZIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Magneto Meter Z: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.magnetometerZObj}
                                  </p>
                                </div>                          

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={GyroscopeXIconRD} alt="GyroscopeXIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Gyroscope X:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.gyroscopeXObj}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={GyroscopeYIconRD} alt="GyroscopeYIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Gyroscope Y: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.gyroscopeYObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={GyroscopeZIconRD} alt="GyroscopeZIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Gyroscope Z: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.gyroscopeZObj}
                                  </p>
                                </div>      

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={SenRotateIconRD} alt="SenRotateIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Sen Rotate: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.senRotateObj}
                                  </p>
                                </div>    

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={GPSTimeRD} alt="GPSTimeRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >GPS Time: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.gpsTimeDiffObj}
                                  </p>
                                </div> 

                                 <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={eHorizonLengthIconRD} alt="eHorizonLengthIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >eHorizon length: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.ehorizonLengthObj}
                                  </p>
                                </div>                          

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={wifiUsageIconRD} alt="wifiUsageIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Wifi Usage:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiRideData.wifiDataUsage}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MobileUsageIconRD} alt="MobileUsageIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Usage: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.mobileDataUsage}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={EngineStateIconRD} alt="EngineStateIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Engine State: </p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.engineStateObj}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={GpsCountsIconRD} alt="GpsCountsIconRD" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >GPS Counts:</p>
                                  <p className="p-tag-text-left" >
                                  {apiRideData.gpsCountObj}
                                  </p>
                                </div>

                               

                               
                              </td>


                            </tr>
                           ))
                        ) : dataLengthRideData === 0 ? ( 
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "red",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                  fontSize:"13px"
                                }}
                              >
                                {displayText.DATA_NOT_AVAILABLE}
                              </div>
                            </td>
                          </tr>
                        ) : dataLengthRideData === null ? (
                          <tr>
                            {" "}
                            <td colSpan="11">
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.FETCHING_DATA}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )} 
                        
                        
                      </tbody>
                    </Table>          
                  </div>  
                </Col>
              </Row>

              <Row>
          <Col>
            <div className="d-flex justify-content-between mb-3 mt-3">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLengthRideData > 0 ? (
                <div style={{display:"flex",justifyContent:"space-between"}}>
                <div className="pagination">
                  <a>{pageNumber}</a>
                </div>
                <p style={{marginTop:"6px",marginLeft:"5px",marginRight:"5px"}} >of</p>             
              <div className="pagination ">                  
             <a>{totalPageCount}</a>             
           </div> 
           </div>
              ) : null}
              {dataLengthRideData > 0 ? (
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility: nextShow ? "visible" : "hidden",
                  float: "hidden",
                }}
              >
                Next
              </button>
                ) : null}
            </div>
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}

import { Image, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SC_COMPANY_NAME, SC_LOGIN_USER_FIRST_NAME, SC_USER_FIRST_NAME, SC_USER_LOGIN_TYPE, SC_USERNAME } from "../../../constant/StorageConstant";
import ValueFormat from "../../../util/ValueFormat";

export default function PdfFooterV1({pageNumber, totalPages, pathname , reversedDateFormat}) {
  // const footer = [
  //   { f: "https://motivai.tech" },
  //   { f: "https://app.motivai.tech" },
  //   { f: '"Drive Safe, Arrive Safe"' },
  //   { f: "A MotivAI Product" },
  // ];

  const footer = [
      { f: `Generated at: ${reversedDateFormat}` },
      { f: "https://app.motivai.tech" },
      { f: '"Drive Safe, Arrive Safe"' },
      { f: "A MotivAI Product" },
    ];

  const fontBold = {
    fontFamily: "Helvetica-Bold",
  };

  const pageTotal = totalPages;

   const currentPath = window.location.pathname;
    const [userFirstName, setUserFirstName] = useState("");
    const [mobileType, setMobileType] = useState("");
    const [userName, setUserName] = useState("");
    const companyName = localStorage.getItem(SC_COMPANY_NAME);

    const loginType = localStorage.getItem(SC_USER_LOGIN_TYPE);
  
    useEffect(() => {
      if (currentPath.includes("/user-dashboard")) {
        setUserFirstName(localStorage.getItem(SC_USER_FIRST_NAME));
        setUserName(localStorage.getItem(SC_USERNAME));
       
      } else {
        setUserFirstName(localStorage.getItem(SC_USER_FIRST_NAME));
        setUserName(localStorage.getItem(SC_USERNAME))
      }
    }, [currentPath]);

    const maskNumber = (number) => {
      // console.log(number,"number")
      if (number.length <= 5) {
        return `**${number}`;
      }
      const lastFive = number.slice(-5);
      // console.log(lastFive,"number")
      return `**${lastFive}`;
    };
  


  return (
    <View
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        // borderBottom: "5px solid #615AE4",
        // backgroundColor: "#f4f4f4",
        borderTop: '1px solid lightgrey',
        display: "flex",
        flexDirection:"row",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 10,
        paddingBottom: 10,
        alignItems: "center",
      }}
    >
      <View style={{width:"50%",}}>
      <View style={{display:"flex",flexDirection:"row"}}>
      <Text
          style={{
            fontSize: 10,
            fontWeight: 600,
            textAlign: "left",           
            padding: 2,
            
          }}
        >
          Generated at:
        </Text>
        <Text
          style={{
            fontSize: 10,
            fontWeight: 600,
            textAlign: "left",           
            padding: 2,
            fontFamily: "Helvetica-Bold"
          }}
        >
          {reversedDateFormat}
        </Text>
        </View>
      </View>
      <View style={{width:"40%"}}>
        <View style={{display:"flex",flexDirection:"row"}}>
      <Text
          style={{
            fontSize: 10,
            fontWeight: 600,
            textAlign: "center",            
            padding: 2,
            
          }}
        >
          Logged In as:
        </Text>
      <Text
          style={{
            fontSize: 10,
            fontWeight: 600,
            textAlign: "center",            
            padding: 2,
            fontFamily: "Helvetica-Bold"
          }}
        >
          {ValueFormat.capitalizeFirstLetter(loginType)}
        </Text>
        </View>
      </View>
      <View style={{width:"30%"}}>
      <View style={{display:"flex",flexDirection:"row",justifyContent:"flex-start"}}>
      <Text
          style={{
            fontSize: 10,
            fontWeight: 600,
            textAlign: "left",            
            padding: 2,
            
            
          }}
        >
         User ID:
        </Text>
        <Text
          style={{
            fontSize: 10,
            fontWeight: 600,
            textAlign:"left",            
            padding: 2,
            fontFamily: "Helvetica-Bold"
            
          }}
        >
         {maskNumber(userName)}
        </Text>
        </View>
      </View>
      <View style={{width:"140%"}}>
      <View style={{display:"flex",flexDirection:"row"}}>
      <Text
          style={{
            fontSize: 10,
            fontWeight: 600,
            textAlign: "center",            
            padding: 2,
           
            
          }}
        >
         Generated by:
        </Text>
        <Text
          style={{
            fontSize: 10,
            fontWeight: 600,
            textAlign: "center",            
            padding: 2,
            fontFamily: "Helvetica-Bold"
            
          }}
        >
         {userFirstName}
        </Text>
        </View>
      </View>
      
      <View style={{width:"30%"}}>
      <Text
          style={{
            fontSize: 10,
            fontWeight: 400,
            textAlign:"right",
            color: "#000",
            padding: 2,
            fontFamily: "Helvetica"
          }}
        >
        Page <Text
          style={{
            fontSize: 10,
            fontWeight: 400,
            textAlign:"right",
            color: "#000",
            padding: 2,
            fontFamily: "Helvetica-Bold"
          }}
        >{pageNumber}</Text> of {(pathname?.pathname !== "/drivingEfficeiency") ? <Text
          style={{
            fontSize: 10,
            fontWeight: 400,
            textAlign:"right",
            color: "#000",
            padding: 2,
            fontFamily: "Helvetica-Bold"
          }}
        >{totalPages}</Text>: ''}        </Text>
      </View>
      {/* <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          paddingLeft: 30,
          paddingRight: 30,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: 600,
            textAlign: "left",
            color: "#615AE4",
            padding: 2,
            fontFamily: "Helvetica"
          }}
        >
          {footer[0].f}
        </Text>
        <Text
          style={{
            fontSize: 12,
            fontWeight: 600,
            textAlign: "right",
            color: "#615AE4",
            padding: 2,
            fontFamily: "Helvetica"
          }}
        >
          {footer[1].f}
        </Text>
      </View> */}
      {/* <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          paddingRight: 30,
          paddingLeft: 30,
        }}
      >
        <View style={{ display: "flex", justifyContent: "flex-start", width:"30%" }}>
          <Text
            style={{
                fontSize: 10,
                fontWeight: 600,
                textAlign: "left",
                color: "#000",
                padding: 2,
                fontFamily: "Helvetica-Oblique"
              }}
          >
            {footer[2].f}
          </Text>
        </View>
        <View style={{ display: "flex", justifyContent: "center", width:"40%" }}>
        <Text
          style={{
            fontSize: 12,
            fontWeight: 400,
            textAlign: "center",
            color: "#000",
            padding: 2,
            fontFamily: "Helvetica"
          }}
        >
        {`Page ${pageNumber}${(pathname?.pathname !== "/drivingEfficeiency") ? ` of ${totalPages}` : ''}`}        </Text>
        </View>
        <View style={{ display: "flex", justifyContent: "flex-end", width:"30%" }}>
        <Text
          style={{
            fontSize: 10,
            fontWeight: 600,
            textAlign: "right",
            color: "#000",
            padding: 2,
            fontFamily: "Helvetica"
          }}
        >
          {footer[3].f}
        </Text>
        </View>
      </View> */}
    </View>
  );
}

import React, { useEffect } from "react";
import ReactPDF, {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Rect,
  Svg,
  Line,
} from "@react-pdf/renderer";
import RuduLogo from "../../../assets/images/logo/motivAiLogoPng.png";
import MotivaiLogo from "../../../assets/images/logo.motivAI.png";
import { useState } from "react";
import LoaderPdf from "../../Common/loaderPdf";
import {
  SC_USERNAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_COMPANY_NAME,
  SC_PROFILE_OPEN,
  SC_USER_LOGIN_TYPE,
  SC_DIVISION_NAME,
  SC_REGISTRATION_DATE,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import { number } from "echarts";
import ValueFormat from "../../../util/ValueFormat";
import PdfHeaderV1 from "../Components/pdfHeaderV1";
import PdfFooterV1 from "../Components/pdfFooterV1";
import { useLocation } from "react-router-dom";
import PdfHeading from "../Components/pdfHeading";
import { globalPDF } from "../../../assets/css/commonCssTextForPdf";
import UtilDateTime from "../../../util/UtilDateTime";

import LocationIcon from "../../../assets/images/adminDashboard/pdf/locationIconPdf.png";
import MinsIcon from "../../../assets/images/adminDashboard/pdf/minsIconPdf.png";
import AnticipationIcon from "../../../assets/images/adminDashboard/pdf/AnticipationIconPdf.png";
import SelfConfidenceIcon from "../../../assets/images/adminDashboard/pdf/SelfconfidenceIconPdf.png";
import DrivingSkillcon from "../../../assets/images/adminDashboard/pdf/DrivingskillIconPdf.png";
import DrivingStylelcon from "../../../assets/images/adminDashboard/pdf/DrivingstyleIcon.png";
import DrivingStatelcon from "../../../assets/images/adminDashboard/pdf/DrivingStateIcon.png";
import MobileUsagelcon from "../../../assets/images/adminDashboard/pdf/MobileUsageIcon.png";
import OverSpeedlcon from "../../../assets/images/adminDashboard/pdf/OverSpeedIcon.png";
import Abclcon from "../../../assets/images/adminDashboard/pdf/AbcIcon.png";
import Countlcon from "../../../assets/images/adminDashboard/pdf/countIcon.png";
import CallCountlcon from "../../../assets/images/adminDashboard/pdf/CallcountIcon.png";
import ScreenCountlcon from "../../../assets/images/adminDashboard/pdf/ScreencountIcon.png";
import steeringWheel from "../../../assets/images/adminDashboard/pdf/steeringWheelIcon.png";
import AddressIcon from "../../../assets/images/pdf/RideList/addressIcon.png";
import LocationIconNew from "../../../assets/images/pdf/RideList/locationIcon.png";
import AccIcon from "../../../assets/images/pdf/RideList/accIcon.png";
import BrakIcon from "../../../assets/images/pdf/RideList/brakIcon.png";
import CorIcon from "../../../assets/images/pdf/RideList/corIcon.png";
import RdIcon from "../../../assets/images/pdf/RideList/rdIcon.png";
import BattryIcon from "../../../assets/images/pdf/RideList/battryIcon.png";
import JvIcon from "../../../assets/images/pdf/RideList/jvIcon.png";

// Create Document Component
export default function RideDetailPdfNew({
  rideDetailsSummary,
  rideNameData
}) {
 
  const fontBold = { fontFamily: "Helvetica-Bold" };

  const pathname = useLocation();
  const [showLoader, setShowLoader] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const companyName = localStorage.getItem(SC_COMPANY_NAME);
  const firstName = ValueFormat.capitalizeFirstLetter(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const userStatus = localStorage.getItem(SC_PROFILE_OPEN);
  const userName = localStorage.getItem(SC_USERNAME);
  const loginType = localStorage.getItem(SC_USER_LOGIN_TYPE);
  const unitName = localStorage.getItem(SC_DIVISION_NAME);
  const createdDate = localStorage.getItem(SC_REGISTRATION_DATE);

  const heading = "Ride Detail Dashboard";
  var currentDate = new Date();

  const originalData = rideDetailsSummary || [
    {
      rideName: "-",
      status: "-",
      drivingCategory: "-",
      drivingBehaviourScore: "-",
      calculatedDrivingScore: "-",
      calculatedDrivingScoreValue: "-",
      drivingScore: "-",
      rideId: "-",
      userId: "-",
      divisionId: "-",
      ABCPoint: "-",
      overallAnticipation: "-",
      overallSelfConfidence: "-",
      overallDrivingSkill: "-",
      totalKmSPoint: "-",
      DSP: "-",
      DSTP: "-",
      totalAccelerationCount: "-",
      totalBrakingCount: "-",
      totalCorneringCount: "-",
      totalOverSpeedDuration: "-",
      totalMobileUseInAcceptedCount: "-",
      totalMobileUseInAcceptedDuration: "-",
      totalMobileScreenScreenOnCount: "-",
      totalMobileScreenScreenOnDuration: "-",
      totalOverSpeedCount: "-",
      tKiloMeter: "-",
      tTravelTime: "-",
      rideCategory: "-",
      startTime: "-",
      startTimeValue: "-",
      startDateFormate: "-",
      endTime: "-",
      endDateFormate: "-",
      batterylevelStart: "-",
      batterylevelEnd: "-",
      batterylevelTotalValue: "-",
      event: "-",
      longEvent: "-",
      cppLevel: "-",
      cppLevelColor: "-",
      mobileUsage: "-",
      day: "-",
      night: "-",
      urbanKilometers: "-",
      ruralKilometers: "-",
      highwayKilometers: "-",
      riskSlot10count: "-",
      riskSlot10Distance: "-",
      riskSlot10Duration: "-",
      deviceMode: "-",
      startLocationName: "-",
      startDeviceCity: "-",
      startDeviceState: "-",
      lastLocationName: "-",
      lastDeviceCity: "-",
      lastDeviceState: "-",
    },
  ];

  const deviceNameHandle = (deviceName) => {
    if (deviceName == "PERSONAL_AUTO") {
      return "Personal";
    } else if (deviceName == "WORK_AUTO") {
      return "Work";
    } else {
      return "-";
    }
  };

  const formatTimeNew = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  return (
    <React.Fragment>
      {/* {showLoader > 0 ? <LoaderPdf /> : ""} */}

      <PDFViewer
        style={{
          height: "96%",
          width: "100%",
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        <Document style={{ marginTop: "15px", marginBottom: "5px" }}>
          <Page
            size={"A2"}
            // pageNumber={pageNumber}
          >
            <PdfHeaderV1 />
            <View style={{ padding: 10, paddingTop: 10 }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  Ride Details Report
                </Text>
              </View>

              <View
                style={{
                  marginBottom: 0,
                  paddingTop: 5,
                }}
              >
                {/* Ride Details Summary Start */}

                <Text
                  style={{ fontSize: "12px", marginBottom: 5, fontWeight: 600 }}
                >
                  {rideNameData}
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: globalPDF.GREY_COLOR,
                    height: "65px",
                    marginTop: 10,
                  }}
                >
                  <View
                    style={{
                      width: "260px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        User Details
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "80px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Driving Score
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "60px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Driving
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Risk
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "140px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Driving Risk
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Data
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "60px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Driving
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Behaviour
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "140px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Driving Behaviour
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Data
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Crash
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Probability
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Acc/Brak/Cor
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Counts
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "90px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Over Speed
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Data
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "90px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Mobile
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Usage
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        High Risk
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Data
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Others
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Context
                      </Text>
                    </View>
                  </View>
                </View>

                {originalData.map((apiData, ind) => (
                  <View key={ind}>
                    <View
                      style={{
                        flexDirection: "row",
                        height: "150px",
                        marginBottom: "0px",
                        border: "1px solid lightgrey",
                      }}
                    >
                      <View
                        style={{
                          width: "260px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Text
                            style={{
                              fontSize: 10,
                              marginLeft: "5px",
                              fontWeight: 700,
                              color: "#5156BE",
                              fontFamily: "Helvetica-Bold",
                              marginTop: "2px",
                            }}
                          >
                            {apiData.rideName}
                          </Text>
                          <Text
                            style={{
                              fontSize: 10,
                              marginLeft: "5px",
                              fontWeight: 700,
                              marginTop: "2px",
                            }}
                          >
                            |
                          </Text>

                          <View
                            style={{
                              backgroundColor: "#D7EC23",
                              marginLeft: "5px",
                              padding: 2,
                            }}
                          >
                            <Text style={{ fontSize: 10 }}>
                              {deviceNameHandle(apiData.deviceMode)}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            {UtilDateTime.formatDateInt(apiData.startTimeValue)}{" "}
                            | {formatTimeNew(apiData.startTime)}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <Image
                            src={LocationIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "5px",
                              marginTop: "3px",
                            }}
                          >
                            {apiData.tKiloMeter + " Kms"} |
                            {parseFloat(apiData.tTravelTime) < 60
                              ? ` ${parseFloat(apiData.tTravelTime).toFixed(
                                  2
                                )} Mins`
                              : ` ${(
                                  parseFloat(apiData.tTravelTime) / 60
                                ).toFixed(2)} Hours`}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <Image
                            src={AddressIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "5px",
                              marginTop: "3px",
                            }}
                          >
                            {apiData.startLocationName}{" "}
                            {apiData.startDeviceCity} {apiData.startDeviceState}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <Image
                            src={LocationIconNew}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "5px",
                              marginTop: "3px",
                            }}
                          >
                            {apiData.lastLocationName} {apiData.lastDeviceCity}{" "}
                            {apiData.lastDeviceState}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "80px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {apiData.calculatedDrivingScore}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                            justifyContent: "center",
                          }}
                        >
                          <Image
                            src={steeringWheel}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "2px",
                              marginTop: "3px",
                            }}
                          >
                            {apiData.drivingCategory}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "60px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {ValueFormat.formatDecimalIfRounded(
                              apiData.drivingScore
                            )}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "140px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={AnticipationIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Anticipation
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "26px" }}>
                            {" - "}
                            {`${ValueFormat.formatDecimalIfRounded(
                              apiData.overallAnticipation
                            )}`}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={SelfConfidenceIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Self Confidence
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "10px" }}>
                            {" - "}
                            {ValueFormat.formatDecimalIfRounded(
                              apiData.overallSelfConfidence
                            )}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={DrivingSkillcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Driving Skill
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "26px" }}>
                            {" - "}
                            {ValueFormat.formatDecimalIfRounded(
                              apiData.overallDrivingSkill
                            )}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "60px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {apiData.drivingBehaviourScore}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "140px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={DrivingStylelcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Driving Style
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "22px" }}>
                            {" - "}
                            {`${ValueFormat.formatDecimalIfRounded(
                              apiData.DSTP
                            )}`}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={DrivingStatelcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Driving State
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "21px" }}>
                            {" - "}
                            {ValueFormat.formatDecimalIfRounded(apiData.DSP)}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={MobileUsagelcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Mobile Usage
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "19px" }}>
                            {" - "}
                            {apiData.mobileUsage}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={OverSpeedlcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Over Speed
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "26px" }}>
                            {" - "}
                            {ValueFormat.formatDecimalIfRounded(
                              apiData.totalKmSPoint
                            )}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={Abclcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Acc/Brak/Cor
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "26px" }}>
                            {" - "}
                            {ValueFormat.formatDecimalIfRounded(
                              apiData.ABCPoint
                            )}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "100px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {apiData.cppLevel}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: "8px",
                          }}
                        >
                          <Text style={{ fontSize: "10px" }}>L0 is Safe</Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: "8px",
                          }}
                        >
                          <Text style={{ fontSize: "10px" }}>L6 is Unsafe</Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "100px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "25%" }}>
                            <Image
                              src={AccIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              Acc
                            </Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "10px",
                                marginTop: "2px",
                              }}
                            >
                              {" - "}
                              {apiData.totalAccelerationCount}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "25%" }}>
                            <Image
                              src={BrakIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              Brak
                            </Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "10px",
                                marginTop: "2px",
                              }}
                            >
                              {" - "}
                              {apiData.totalBrakingCount}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "25%" }}>
                            <Image
                              src={CorIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              Cor
                            </Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "10px",
                                marginTop: "2px",
                              }}
                            >
                              {" - "}
                              {apiData.totalCorneringCount}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "90px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <View style={{ width: "25px" }}>
                            <Image
                              src={Countlcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginTop: "3px",
                                width: "48px",
                              }}
                            >
                              {apiData.totalOverSpeedCount} {"Count"}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "8px",
                          }}
                        >
                          <View style={{ width: "10px" }}>
                            <Image
                              src={MinsIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "15px",
                                marginTop: "2px",
                              }}
                            >
                              {parseFloat(apiData.totalOverSpeedDuration) < 60
                                ? `${parseFloat(
                                    apiData.totalOverSpeedDuration
                                  ).toFixed(2)} Mins`
                                : `${(
                                    parseFloat(apiData.totalOverSpeedDuration) /
                                    60
                                  ).toFixed(2)} Hours`}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "90px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "20px" }}>
                            <Image
                              src={ScreenCountlcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View style={{ width: "48px" }}>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              {apiData.totalMobileScreenScreenOnCount} {"Count"}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "8px",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "20px" }}>
                            <Image
                              src={MinsIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View style={{ width: "48px" }}>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              {parseFloat(
                                apiData.totalMobileScreenScreenOnDuration
                              ) < 60
                                ? `${parseFloat(
                                    apiData.totalMobileScreenScreenOnDuration
                                  ).toFixed(2)} Mins`
                                : `${(
                                    parseFloat(
                                      apiData.totalMobileScreenScreenOnDuration
                                    ) / 60
                                  ).toFixed(2)} Hours`}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "8x",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "20px" }}>
                            <Image
                              src={CallCountlcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View style={{ width: "48px" }}>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              {apiData.totalMobileUseInAcceptedCount} {"Count"}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "8px",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "20px" }}>
                            <Image
                              src={MinsIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View style={{ width: "48px" }}>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              {parseFloat(
                                apiData.totalMobileUseInAcceptedDuration
                              ) < 60
                                ? `${parseFloat(
                                    apiData.totalMobileUseInAcceptedDuration
                                  ).toFixed(2)} Mins`
                                : `${(
                                    parseFloat(
                                      apiData.totalMobileUseInAcceptedDuration
                                    ) / 60
                                  ).toFixed(2)} Hours`}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "100px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Image
                            src={Countlcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            {apiData.riskSlot10count} {"Count"}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={LocationIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            {apiData.riskSlot10Distance} {"Kms"}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={MinsIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            {apiData.riskSlot10Duration} {"Mins"}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "100px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Image
                            src={RdIcon}
                            style={{ width: "15px", height: "15px" }}
                          />

                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "2px",
                              }}
                            >
                              Start Time
                            </Text>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "4px",
                              }}
                            >
                              {`${UtilDateTime.formatDateTimeNew(
                                apiData.startTime
                              )}`}
                            </Text>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "4px",
                              }}
                            >
                              {`${apiData.startDateFormate}`}
                            </Text>

                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "5px",
                              }}
                            >
                              End Time
                            </Text>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "4px",
                              }}
                            >
                              {`${UtilDateTime.formatDateTimeNew(
                                apiData.endTime
                              )}`}
                            </Text>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "4px",
                              }}
                            >
                              {`${apiData.endDateFormate}`}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={BattryIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            {apiData.batterylevelTotalValue < 0
                              ? `Battery Consumption | ${Math.abs(
                                  apiData.batterylevelTotalValue
                                )}% `
                              : apiData.batterylevelTotalValue == 0
                              ? "No Battery Consumption"
                              : "Battery Charged"}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={JvIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            {/* {apiData.totalHighRiskCount}{" "}{"Count"} */}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "3px",
                            justifyContent: "center",
                            marginBottom: "3px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "1px",
                            }}
                          >
                            {apiData.event}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "100px",
                          height: "150px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            U{" - "}
                            {apiData.urbanKilometers} {"Kms"}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            R{" - "}
                            {apiData.ruralKilometers} {"Kms"}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            H{" - "}
                            {apiData.highwayKilometers} {"Kms"}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            D{" - "}
                            {apiData.day} {"%"}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            N{" - "}
                            {apiData.night} {"%"}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                ))}

                {/* Ride Details Summary End */}
              </View>
            </View>

            {/* <PdfFooterV1 pageNumber={1} totalPages={2} pathname={pathname} /> */}
          </Page>
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
}

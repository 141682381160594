import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import UserSessionController from "../../../controller/UserSession/UserSessionController.js";
import { GlobalConst } from "../../../constant/GlobalConstant.js";
import { BiSolidUserCircle } from "react-icons/bi";
import FeatherIcon from "feather-icons-react";
import "../TopbarDropdown/ProfileMenu.css";
import RuduLogo from "../../../assets/images/logo/motivAiLogo.svg";

import {
  SC_LOCK_STATUS,
  SC_USER_FIRST_NAME,
  SC_PROFILE_OPEN,
  SC_USER_LOGIN_TYPE,
  SC_DIVISION_ID,
  SC_USER_ID,
  SC_PROFILE_IMAGE,
  SC_PROFILE_IMAGE_USER,
  SC_USERNAME,
} from "../../../constant/StorageConstant";
import Modal from "react-modal";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import ConformationPopup from "../../Common/ConfirmationPopup.js";
import AdminDashboardCtrlr from "../../../controller/AdminDashboardCtrlr.js";
import { Buffer } from 'buffer';

import AdminLogo from "../../../assets/images/LoginProfile/Admin.svg";
import SuperAdminLogo from "../../../assets/images/LoginProfile/Super Admin.svg";
import UserLogo from "../../../assets/images/LoginProfile/User.svg";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");
  const loginType = localStorage.getItem(SC_USER_LOGIN_TYPE) || "";
  const deviceId = localStorage.getItem(SC_DIVISION_ID) || "";
  const userId = localStorage.getItem(SC_USER_ID) || "";
  var profileImage = localStorage.getItem(SC_PROFILE_IMAGE_USER) || "";  
  console.log(profileImage,"profileImage")
  const [user, setUser] = useState("");  
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [getImage, setGetImage] = useState("");
  var userName = localStorage.getItem(SC_USERNAME);

  
  
  const getRideListSA = async () => {

    console.log(loginType,"loginType")
    var typeChange = ((loginType == "ADMIN") || (loginType == "SUPER_ADMIN")) ? "DIVISION_PROFILE_IMAGE" : "USER_PROFILE_IMAGE";
    var result = await AdminDashboardCtrlr.getProfileLogoUser(
      deviceId,
      userId,
      typeChange
    );
    
    try {
      
      if(result.status == "SUCCESS") {
        const blob = new Blob([result.data], { type: 'image/jpeg' });  // Set correct mime type (e.g., 'image/jpeg', 'image/png')
        const url = URL.createObjectURL(blob);
        setGetImage(url);
      }     
     
    }catch (errMes) {
      console.log(errMes.message)
    }
  }


  useEffect(() => {
    
    if((loginType == "USER") && profileImage && (profileImage != "undefined") && (profileImage != "null") && (profileImage != "") && (profileImage != undefined)){
     
      getRideListSA()
    }
      
  },[deviceId,userId])


  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
    openPopup("Logout");
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const history = useNavigate();

// _____________________________

  const [popupData, setPopUpData] = useState({
    Purpose: "",
    message: "",
    DataDetails: "",
  });

  const openPopup = (PopupType, Data) => {
    if (PopupType == "Logout") {
      setPopUpData({
        ...popupData,
        Purpose: "Logout",
        message: "you want to logout ?",
      });
    } else {
      setPopUpData({
        ...popupData,
        Purpose: "Delete",
        message: "This action cannot be undone. The station will be removed from the list.",
        DataDetails: Data ? Data : "",
      });
    }
    // setOpen(true);
  };

  const PopUpClose = () => {
    // setOpen(false);
  };

  // _______________________________________________

  useEffect(() => {
    console.log(loginType,"loginType")
    if (loginType != undefined || loginType != null) {
      let userParse = loginType.split("_").map((val) => val.charAt(0) + val.slice(1).toLowerCase()).join(" ")
      setUser(userParse)
    }
  }, [loginType])

  const logout = async () => {
    var result = await UserSessionController.deleteUserSession();
    
    var resultTemp = JSON.parse(result);
    
    var resultStatus = resultTemp.status;
    if (resultStatus == GlobalConst.SUCCESS) {
      history("/logout");
    }
  };
  const lockUSer = () => {
    localStorage.setItem(SC_LOCK_STATUS, true);
  };

  const profileOpen = () => {
    localStorage.setItem(SC_PROFILE_OPEN, true);
  };
  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       setusername(obj.displayName);
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       setusername(obj.username);
  //     }
  //   }
  // }, [props.success]);

  useEffect(() => {
    var userFirstNameTemp = localStorage.getItem(SC_USER_FIRST_NAME);
    if (userFirstNameTemp != undefined || userFirstNameTemp != null) {
      if (userFirstNameTemp.length > 0) {
        try {
          var userFirstName =
            userFirstNameTemp[0].toLocaleUpperCase() + userFirstNameTemp.slice(1);
          setusername(userFirstName);
        } catch (errMess) {
          // console.log("Error : " + errMess);
        }
      }
    }
  }, []);

  // const openImageView = () => {

  // }

  const [isHovered, setIsHovered] = useState(false); 

  function getProfileImage(loginType, getImage) {
   
   if( (loginType == "ADMIN")){ 

    if(profileImage && (profileImage != "undefined") && (profileImage != "null") && (profileImage != "") && (profileImage != undefined)){
     
      return AdminLogo
    }else{
     
      return AdminLogo
    }

  }else if((loginType == "SUPER_ADMIN")){

    if(profileImage && (profileImage != "undefined") && (profileImage != "null") && (profileImage != "") && (profileImage != undefined)){
     
      return SuperAdminLogo
    }else{
     
      return SuperAdminLogo
    }

  }else if((loginType == "USER")){
     console.log(profileImage,"profileImageUser")
    if(profileImage && (profileImage != "undefined") && (profileImage != "null") && (profileImage != "") && (profileImage != undefined)){
     
      return getImage
      // return UserLogo
      
    }else{
      console.log(profileImage,"profileImageUserDefault")
      return UserLogo
    }

  }
  
}

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
          <div className="btn header-item   " style={{ display: "flex", minWidth: "50px", maxWidth: "350px", alignItems:'center', cursor:"default",borderLeft:"1px solid #C3C3C3" }}>
            <div >
         
              <div
              style={{ display: 'inline-block', position: 'relative' }}
              onMouseEnter={() => setIsHovered(true)} // Set hover state to true when mouse enters
              onMouseLeave={() => setIsHovered(false)} // Set hover state to false when mouse leaves
            >
          
       
             <img
                src={getProfileImage(loginType, getImage)}
                alt="Profile"
                height="40px"
                style={{ cursor: 'pointer',marginRight:"8px"}} // Change cursor to pointer on hover
              />

           
        
              {/* Hover preview (only shown when isHovered is true) */}
              {/* {isHovered && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0', // Position the preview just below the thumbnail
                    left: '50%',
                    transform: 'translateX(-50%)', // Center the preview horizontally
                    zIndex: 10,
                    backgroundColor: '#fff',
                    border: '1px solid #ddd',
                    padding: '3px',
                    borderRadius: '5px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <img
                    src={getImage}
                    alt="Preview"
                    style={{ height:"43px" }} // Show the preview image in larger size
                  />
                </div>
              )} */}
            </div>
              {/* // :
              // <BiSolidUserCircle style={{ width: "50px", height: "50px", color:"#74788d" }} data-tooltip-id={username.length > 25 && "snpTooltipContent"} />
              // } */}
               
                
             
                <ReactTooltip
                  id={"snpTooltipContent"}
                  place="right"
                  content={username}
                  style={{ backgroundColor: "#000", opacity: 1 }}
              />
              {/* <p onClick={openImageView} style={{color:"blue",fontSize:"10px",textDecoration:"underline",marginBottom:"0px"}}>View</p> */}
            </div>
            <DropdownToggle 
          id="page-header-user-dropdown"
          tag="button"
          style={{ border:"none", backgroundColor:"#fff" }}
        >
            <div className="d-none d-lg-inline-block text-nowrap" style={{ fontSize: '14px', fontWeight: 500, color: "#313533", textAlign: "left" }}>
            
            <span style={{fontSize: '12px',fontWeight:600}}>User ID: {userName}</span>
            <br/>
                
                
                
                
                

                
                <span style={{fontSize: '12px',fontWeight:600}}>[Type: {user}]</span>
                <br/>
                <div style={{display:"flex",justifyContent:"flex-end"}}>
                <span style={{ minWidth: "100px", maxWidth: "250px", overflow: "hidden", whiteSpace: "nowrap",fontWeight:800}} >{username}</span>
                <i className="mdi mdi-chevron-down d-none d-lg-inline-block"/>
                </div>
            </div>
            </DropdownToggle>
          </div>
          <div>
          </div>
       
        <DropdownMenu className="dropdown-menu-end" style={{ maxWidth: "300px" }}>
          {/* <DropdownItem tag="a" href="/profile"> */}
          {/* <Link
            to={"/contacts-profile"}
            className="dropdown-item"
            onClick={profileOpen}
          >
            <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
            {props.t("Profile")}
          </Link> */}
          {/* <Link
            // to={"/profile"}
            className="dropdown-item"
          >
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </Link> */}
          <Link to="/page-lock-screen" className="dropdown-item">
            <button
              onClick={lockUSer}
              style={{
                border: "none",
                marginLeft: "-5px",
                paddingTop: "-10px",
              }}
            >
              {" "}
              <i className="bx bx-lock-open font-size-16 align-middle me-1" />
              {props.t("Lock screen")}
            </button>
          </Link>
          {/*           
          {localStorage.getItem(SC_USER_LOGIN_TYPE)=="USER" &&   <Link 
          to="/appBootSettings" 
          className="dropdown-item">
         <button  style={{border:"none" ,marginLeft:"-5px", paddingTop:"-10px", marginRight:"10px"}}>  <FeatherIcon
         style={{marginRight:"5px"}}
                  icon="settings"
                  className="icon-sm"
                />
            {props.t("App Boot Settings")}</button>
          </Link>} */}
          <div className="dropdown-divider" />
          <button 
          // onClick={logout}
          onClick={openConfirmationModal}
           className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </button>
        </DropdownMenu>
      </Dropdown>

      <Modal
                isOpen={confirmationModalOpen}
                // onRequestClose={closeConfirmationModal}
                toggle={closeConfirmationModal}
                contentLabel="Confirmation Modal"
                // className="custom-modall"
                style={{
                  backgroundColor:"green",
                  content: { height: "190px", width: "340px", margin: "auto", borderRadius:"10px", padding:"0px" },
                  overlay: { backgroundColor: "rgba(0,0,0,0.5)" }, // /* Black background with opacity */
                }}>


<ModalHeader style={{ height: "40px", backgroundColor: "#5156BE", width: "100%", display:"felx", justifyContent:"center"  }} >
  <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>Confirm Action </span>
</ModalHeader>
               
                 <ConformationPopup
         Data={popupData}
         formSubmit={logout}
         cancelSubmit={closeConfirmationModal}
        />
              </Modal>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default connect(mapStatetoProps, {})(withTranslation()(ProfileMenu));
